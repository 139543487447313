.main-slider-banner .slide-layer-info {
    position: absolute;
    top: 10%;
    left:50%;
    transform:translate(-50%, 0);
    height: 80%;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 30px;
    background: rgba(63,157,167,.6);
    color: #fff;
    display: flex;
    flex-direction: column;

}
.slide-layer-info{
    &.left{
        left: 20%;
    }
    &.center{
        left: 50%;
    }
    &.right{
        left: 80%;
    }
}

.main-slider-banner .slide-layer-info .layer-title {
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 20px;
}
.main-slider-banner .slide-layer-info .layer-text {
    margin-bottom: 20px;
}
.main-slider-banner .slide-layer-info .link {
    display: inline-block;
    padding: 10px 30px;
    
    background-color: #444;
    color: #fff;
    border: 2px solid #444;
    text-decoration: none;
    margin: 10px 0;
    width: 100%;
    transition: .2s;
}
.main-slider-banner .slide-layer-info .link:hover {
    color: #444;
    background-color: #fff;
}
.main-slider-banner {
    z-index: 9;
}
.main-slider-banner .slick-slide {
    position: relative;
}
.main-slider-banner .slick-dots {
    display: flex !important;
    justify-content: center;
}
.main-slider-banner .slick-dots li {
    background-color: #ccc;
    margin: 0 8px;
    width: 15px;
    height: 15px;
}
.main-slider-banner .slick-dots li.slick-active {
    background-color: orange;
}
.main-slider-banner .slick-dots li button:before {
    display: none;
}
.main-slider-banner .slide-layer-info .link-wrap {
    text-align: center;
    margin-top: auto;
}

@media (max-width: 767px) {
    .main-slider-banner {
        margin-top: 30px;
    }
    .main-slider-banner .slide-layer-info {
        height: calc(100% - 15px);
        max-width: none;
        padding: 15px;
        top: 0;
        left: 0;
        transform: translate(0, 0);
    }
    .main-slider-banner .slide-layer-info .layer-title {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .main-slider-banner .slide-layer-info .layer-text {
        margin-bottom: 10px;
    }
    .touchevents #top .main-slider-banner .slick-slide{
        height: 200px;
    }
    .main-slider-banner .slide-layer-info .link {
        margin-bottom: 0;
        margin-top: 5px;
        padding: 8px 20px;
    }
}