@font-face {   font-display: swap;
  font-family: 'HelveticaNeueCyrMedium';
  src: url("/styles/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Medium.ttf");
  src: local("HelveticaNeueCyr-Medium"), url("/styles/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Medium.eot?#iefix") format("embedded-opentype"), url("/styles/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Medium.woff") format("woff"), url("/styles/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;

}

@font-face {   font-display: swap;
  font-family: 'HelveticaNeueCyrRoman';
  src: url("/styles/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman.ttf");
  src: local("HelveticaNeueCyr-Roman"), url("/styles/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman.eot?#iefix") format("embedded-opentype"), url("/styles/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman.woff") format("woff"), url("/styles/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }
@font-face {   font-display: swap;
  font-family: 'HelveticaNeueCyrLight';
  src: url("/styles/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Light.ttf");
  src: local("HelveticaNeueCyrLight"), url("/styles/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Light.eot?#iefix") format("embedded-opentype"), url("/styles/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Light.woff") format("woff"), url("/styles/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

body {
  color: #444;
  font: normal 1rem/1.5em "HelveticaNeueCyrLight", sans-serif;
  background: #fff; }

.animated.slowe {
  -webkit-animation-duration: 2s;
  animation-duration: 2s; }

input,
textarea,
select,
button {
  outline: none; }
input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none; }

img {
  width: auto;
  max-width: 100%; }

a {
  color: inherit;
  text-decoration: none; }
a:hover {
  text-decoration: none; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

p:last-of-type {
  margin-bottom: 0; }

h2 {
  color: #000;
  font: normal 2.25rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  margin-bottom: 2.5rem; }


section {
  position: relative;
  padding: 3.75rem 0; }
section.overflow-hidden {
  overflow: hidden; }
section.hide {
  display: none; }
section.no-padding {
  padding: 0; }
section.no-padding-bottom {
  padding-bottom: 0; }
section.no-padding-top {
  padding-top: 0; }
section.dark {
  background: #f5f5f5; }
section.with-full-title {
  padding: 11.25rem 0 0; }
section.cabinets-section.with-full-title {
  background-color: #f5f5f5; }

section.with-full-title.first-title.with-promo {
  padding: 14.25rem 0 0; }


section.dark-with-border {
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  background-color: #f5f5f5; }
section.with-border {
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8; }
section .row {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem; }
section .row.no-padding {
  padding: 0; }
section .row.no-padding-bottom {
  padding-bottom: 0; }
section .row.no-padding-top {
  padding-top: 0; }
div .no-padding {
  padding: 0; }

.animate {
  opacity: 0; }

.text-align-right {
  text-align: right; }

.text-align-center {
  text-align: center; }

.full-width-title {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  padding: 4.5rem 0; }

.full-width-title.first {
  padding-top:9rem;
}
.touchevents .header .main-navigation nav .nav {
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
  width: 100%;
  padding: 2.15rem;
  background: rgba(0, 0, 0, 0.7);
  border-top: 0.2rem solid #000;
  animation-duration: 0.1s;
  z-index: 5; }
section.first-title.with-promo .full-width-title {
  padding-top:11.5rem;
}
.full-width-title .title {
  color: #000;
  font: 2.25rem/1em "HelveticaNeueCyrMedium", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  margin-bottom: 0; }

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 0;
  background: #373A3C;
  z-index: 100; }
.header.no-banner {
  background: #000; }
.header .logo {
  border-right: 1px solid #fff;
  text-align: center;}
.header .logo img {
  height: auto;
}
.header .logo a {
  display: block; }
.header .logo a .logo-image {
  display: block;
  width: 5.8125rem;
  height: 2.25rem; }
.header .main-navigation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end; }
.header .main-navigation nav {
  margin-right: 3.25%; }
.header .main-navigation nav .mobile-button {
  display: none;
  padding: 0;
  border: 0;
  background: transparent; }
.header .main-navigation nav .mobile-button .menu-icon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  fill: #fff;
  margin-right: 0.5rem; }
.header .main-navigation nav .mobile-button .mobile-text {
  display: block;
  color: #fff;
  font: normal 0.875rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-transform: uppercase; }
.header .main-navigation nav .nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }
.header .main-navigation nav .nav .menu-item {
  margin-right: 1.875rem; }
.header .main-navigation nav .nav .menu-item:last-child {
  margin-right: 0; }
.header .main-navigation nav .nav .menu-link {
  position: relative;
  display: block;
  color: #fff;
  font: normal 0.875rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.header .main-navigation nav .nav .menu-link:after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 50%;
  width: 0;
  height: 1px;
  background: #fff;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.header .main-navigation nav .nav .menu-link:hover:after {
  left: 0;
  width: 100%;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.header .main-navigation nav .nav .menu-link.order-now-link,
.header .main-navigation  .menu-link.order-now-link.single
{
  color: #fff;
  background: #28a745;
  padding: 0.3em 1em;
  border-radius: 1em;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  animation: pulse 2s infinite;
}
.touchevents .header .main-navigation nav .nav .menu-link.order-now-link {
  display: none;
}
.header .main-navigation  .menu-link.order-now-link.single{
  padding: 0.2em 0.5em;

}
.header .main-navigation  .menu-link.order-now-link.single{
  margin-right: 1rem;
}
.header .main-navigation  .menu-link.order-now-link.single:hover,
.header .main-navigation nav .nav .menu-link.order-now-link:hover
{
  animation:none;
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.3);
}
.header .main-navigation  .menu-link.order-now-link.single:hover:after,
.header .main-navigation nav .nav .menu-link.order-now-link:hover:after
{
  width: 0;
}


@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.header .main-navigation nav .nav .menu-link.order-now-link:after{
  /*content:none;*/
}

.header .main-navigation .header-search {
  position: relative;
  margin-right: 3.25%; }
.header .main-navigation .header-search .search-button.callback-button {
  color:#fff;
}
.header .main-navigation .header-search .search-button {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 1.875rem;
  height: 1.875rem;
  background: transparent;
  padding: 0;
  border: 0;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  z-index: 2; }
.header .main-navigation .header-search .search-button svg {
  display: block;
  fill: #fff;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.header .main-navigation .header-search .search-button svg.search-icon {
  width: 1rem;
  height: 1rem; }
.header .main-navigation .header-search .search-button svg.close-icon {
  width: 0;
  height: 0; }
.header .main-navigation .header-search .search-button.callback-button:hover {
  color:#000;
}
.header .main-navigation .header-search .search-button:hover {
  background: #fff;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.header .main-navigation .header-search .search-button:hover svg {
  fill: #000;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.header .main-navigation .header-search .search-button.active svg.search-icon {
  width: 0;
  height: 0; }
.header .main-navigation .header-search .search-button.active svg.close-icon {
  width: 0.625rem;
  height: 0.625rem; }
.header .main-navigation .header-search .search-form {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 1.875rem;
  background: #000;
  -webkit-box-shadow: 0 0 1px #fff;
  box-shadow: 0 0 1px #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.header .main-navigation .header-search .search-form.show {
  width: 40rem;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.header .main-navigation .header-search .search-form .form-field {
  position: relative;
  display: block;
  width: 100%;
  background: transparent;
  margin: 0;
  z-index: 3; }
.header .main-navigation .header-search .search-form .form-field input {
  display: block;
  color: #fff;
  font: normal 0.85rem/1em "HelveticaNeueCyrLight", sans-serif;
  width: 100%;
  height: 1.875rem;
  padding: 0 1.875rem 0 2.625rem;
  background: transparent;
  border: 0; }
.header .main-navigation .header-search .search-form .form-field input::-webkit-input-placeholder {
  color: #fff; }
.header .main-navigation .header-search .search-form .form-field input::-moz-placeholder {
  color: #fff; }
.header .main-navigation .header-search .search-form .form-field input:-ms-input-placeholder {
  color: #fff; }
.header .main-navigation .header-search .search-form .form-field input::-ms-input-placeholder {
  color: #fff;
  opacity: 1; }
.header .main-navigation .header-search .search-form .form-field input::placeholder {
  color: #fff;
  opacity: 1; }
.header .main-navigation .header-search .search-form .submit {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4; }
.header .main-navigation .header-search .search-form .submit button {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 1.875rem;
  height: 1.875rem;
  background: transparent;
  padding: 0;
  border: 0;
  cursor: pointer; }
.header .main-navigation .header-search .search-form .submit button .search-icon {
  display: block;
  fill: #fff;
  width: 1rem;
  height: 1rem; }
.header .main-navigation .header-search .search-results {
  /*display: none;*/
  position: absolute;
  top: -webkit-calc(100% + 0.9375rem);
  top: calc(100% + 0.9375rem);
  right: 0;
  width: 41.25rem;
  z-index: 50; }
.header .main-navigation .header-search .search-results .result-list {
  background: #fff;
  -webkit-box-shadow: 0 3px 7px #c4c4c4;
  box-shadow: 0 3px 7px #c4c4c4;
  padding: 1.875rem 1.25rem; }
.header .main-navigation .header-search .search-results .result-list .result-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid #d8d8d8; }
.header .main-navigation .header-search .search-results .result-list .result-item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0; }
.header .main-navigation .header-search .search-results .result-list .result-item .product-image {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 3.75rem;
  -ms-flex: 0 0 3.75rem;
  flex: 0 0 3.75rem;
  max-width: 3.75rem;
  margin-right: 1.25rem; }
.header .main-navigation .header-search .search-results .result-list .result-item .product-image a {
  display: block;
  width: 3.75rem;
  height: 3.75rem;
  border: 1px solid #d8d8d8;
  background-color: #fff; }
.header .main-navigation .header-search .search-results .result-list .result-item .product-information {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 15.3125rem;
  -ms-flex: 1 0 15.3125rem;
  flex: 1 0 15.3125rem;
  max-width: 100%;
  margin-right: 1.25rem; }
.header .main-navigation .header-search .search-results .result-list .result-item .product-information .product-name {
  color: #000;
  font: normal 1rem/1.2em "HelveticaNeueCyrMedium", sans-serif;
  margin-bottom: 0.625rem;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.header .main-navigation .header-search .search-results .result-list .result-item .product-information .product-name a {
  color: inherit;
  font: inherit; }
.header .main-navigation .header-search .search-results .result-list .result-item .product-information .product-code {
  padding-bottom: 0; }
.header .main-navigation .header-search .search-results .result-list .result-item .product-information .product-code .text {
  font-size: 0.875rem;
  text-transform: none; }
.header .main-navigation .header-search .search-results .result-list .result-item .product-information .product-code .value {
  font-size: 0.875rem; }
.header .main-navigation .header-search .search-results .result-list .result-item .product-price {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 7rem;
  -ms-flex: 0 0 7rem;
  flex: 0 0 7rem;
  max-width: 7rem;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-right: 0; }
.header .main-navigation .header-search .search-results .result-list .result-item .product-price .price-text {
  font-size: 1rem; }
.header .main-navigation .header-search .search-results .result-list .result-item .product-price .price-value {
  font-size: 1rem; }
.header .main-navigation .header-search .search-results .result-list .result-item.promotional-price .product-image:after {
  content: '';
  position: absolute;
  top: -0.125rem;
  right: -0.125rem;
  width: 1.8125rem;
  height: 1.8125rem;
  background: url("../../images/promotional-icon.png") no-repeat center/cover; }
.header .main-navigation .header-search .search-results .result-list .result-item:hover .product-information .product-name {
  color: #0070c9;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.header .main-navigation .account-nav {
  margin-right: 3.25%; }
.header .main-navigation .account-nav .account-link {
  display: block;
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer; }
.header .main-navigation .account-nav .account-link.logged-in{
  border-radius: 1.25rem;
  padding: 0.5rem;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  background: #0275D8;}

.header .main-navigation .menu-link.support-button:hover img{
  transform: scale(1.2);
  transition: all 0.45s ease-in-out;
}
.header .main-navigation .menu-link.support-button img,
.header .main-navigation .account-nav .account-link img
{
  display: block;
  width: 24px;
  height: 23px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.header .main-navigation .account-nav .account-link:hover img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.header .main-navigation .account-nav .account-link.logged-in:hover img{
  transform: scale(1);
  transition: all 0.45s ease-in-out;
}
.header .main-navigation .account-nav .account-link.logged-in:hover{
  transform: scale(1.2);
  transition: all 0.45s ease-in-out;
}
.touchevents .header .main-navigation .account-nav .account-link.logged-in:hover{
  transform: scale(1);
}
.account-nav .account-modal {
  /*display: none;*/
  position: absolute;
  top: -webkit-calc(100% + 1.25rem);
  top: calc(100% + 1.25rem);
  right: 4.25rem;
  width: 23rem;
  -webkit-box-shadow: 0 3px 7px #c4c4c4;
  box-shadow: 0 3px 7px #c4c4c4;
  background-color: #fff;
  border-radius: 5px;
  z-index: 1; }
.account-nav .account-modal:before {
  content: '';
  position: absolute;
  top: -1.875rem;
  right: 1.875rem;
  border: 0.9375rem solid transparent;
  border-bottom: 0.9375rem solid #fff;
  z-index: 2; }
.account-nav .account-modal .login-form,
.login-form.not-modal {
  padding: 1.875rem; }
.account-nav .account-modal .login-form form .field,
.login-form.not-modal form .field {
  margin: 0 auto 0.9375rem;
  padding-left: 1.625rem; }
.account-nav .account-modal .login-form form .field:last-child,
.login-form.not-modal form .field:last-child {
  margin-bottom: 0; }
.account-nav .account-modal .login-form form .field label,
.login-form.not-modal form .field label {
  position: relative;
  display: block;
  margin: 0; }
/*.account-nav .account-modal .login-form form .field label.email-icon:before {*/
/*  content: '';*/
/*  position: absolute;*/
/*  top: -webkit-calc(50% - 0.375rem);*/
/*  top: calc(50% - 0.375rem);*/
/*  left: -1.625rem;*/
/*  width: 1rem;*/
/*  height: 0.75rem;*/
/*  background: url("../../images/email-icon.svg") no-repeat center/cover; }*/
/*.account-nav .account-modal .login-form form .field label.password-icon:before {*/
/*  content: '';*/
/*  position: absolute;*/
/*  top: -webkit-calc(50% - 0.5625rem);*/
/*  top: calc(50% - 0.5625rem);*/
/*  left: -1.5625rem;*/
/*  width: 0.875rem;*/
/*  height: 1.125rem;*/
/*  background: url("../../images/password-icon.svg") no-repeat center/cover; }*/
.login-form.not-modal form .field label input,
.account-nav .account-modal .login-form form .field label input {
  position: relative;
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  padding: 0.625rem;
  background: #fff;
  border: 1px solid #d8d8d8;
  width: 100%;
  z-index: 1; }
.login-form.not-modal form .field label .show-password ,
.account-nav .account-modal .login-form form .field label .show-password ,
.account-content .account-form .account-information  .show-password
{
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: 15px;
  right: 0;
  width: 2.5rem;
  height: 100%;
  cursor: pointer;
  z-index: 2; }

.account-content .account-form .account-information label .show-password {
  position:relative;
  right:3%;
}
.account-content .account-form .account-information  .show-password svg ,
.login-form.not-modal form .field label .show-password svg,
.account-nav .account-modal .login-form form .field label .show-password svg {
  display: block;
  fill: #7c7c7c;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.account-content .account-form .account-information .show-password svg.eye-open ,
.login-form.not-modal form .field label .show-password svg.eye-open,
.account-nav .account-modal .login-form form .field label .show-password svg.eye-open {
  width: 1.3125rem;
  height: 0.9375rem; }
.account-content .account-form .account-information  .show-password svg.eye-close ,
.login-form.not-modal form .field label .show-password svg.eye-close,
.account-nav .account-modal .login-form form .field label .show-password svg.eye-close {
  width: 0;
  height: 0; }
.account-content .account-form .account-information  .show-password.show svg ,
.login-form.not-modal form .field label .show-password.show svg,
.account-nav .account-modal .login-form form .field label .show-password.show svg {
  display: block;
  fill: #0070c9;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.account-content .account-form .account-information  .show-password.show svg.eye-open ,
.login-form.not-modal form .field label .show-password.show svg.eye-open,
.account-nav .account-modal .login-form form .field label .show-password.show svg.eye-open {
  width: 0;
  height: 0; }
.account-content .account-form.default .account-information  .show-password{
  display: none;
}

.account-content .account-form .account-information  .show-password.show svg.eye-close ,
.login-form.not-modal form .field label .show-password.show svg.eye-close,
.account-nav .account-modal .login-form form .field label .show-password.show svg.eye-close {
  width: 1.3125rem;
  height: 0.9375rem; }
.login-form.not-modal form .field .forgot-password,
.account-nav .account-modal .login-form form .field .forgot-password {
  display: inline-block;
  color: #337ab7;
  font: normal 0.875rem/1.3em "HelveticaNeueCyrMedium", sans-serif;
  background: transparent;
  border: 0;
  cursor: pointer; }
.login-form.not-modal form .field.submit,
.account-nav .account-modal .login-form form .field.submit {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }
.account-nav .account-modal .login-form form .field.submit input,
.login-form.not-modal form .field.submit input {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 6.75rem;
  -ms-flex: 0 0 6.75rem;
  flex: 0 0 6.75rem;
  max-width: 6.75rem;
  display: block;
  font: normal 0.9375rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  padding: 0.625rem 0;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  color: #000;
  border: 1px solid #5f5f5f;
  background: #fff;}
.account-nav .account-modal .login-form form .field.submit input:hover,
.login-form.not-modal form .field.submit input:hover {
  color: #fff;
  background: #000;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.account-create .login-form form .field.submit .create-account,
.login-form.not-modal form .field.submit .create-account,
.account-nav .account-modal .login-form form .field.submit .create-account,
.login-form.not-modal form .field.submit .create-account
{
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 9.25rem;
  -ms-flex: 0 0 9.25rem;
  flex: 0 0 9.25rem;
  max-width: 9.25rem;
  display: block;
  color: #fff;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  background: #4bbae7;
  padding: 0.625rem 0;
  border: 1px solid #4bbae7;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.account-create .login-form form .field.submit .create-account:hover,
.account-nav .account-modal .login-form form .field.submit .create-account:hover,
.account-nav .account-modal .login-form form .field.submit .login-button:hover.account-nav .account-modal .login-form form .field.submit.social
.login-form.not-modal form .field.submit .create-account:hover,
.login-form.not-modal form .field.submit .create-account:hover,
.login-form.not-modal form .field.submit .login-button:hover.account-nav .account-modal .login-form form .field.submit.social
{
  color: #4bbae7;
  background: #fff;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.account-nav .account-modal .login-form {
  display: block;
}
.account-nav .account-modal .account-information {
  padding: 1.875rem; }
.account-information-wrapper .account-sidebar .account-name{
  white-space: pre-wrap;      /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap;     /* Opera <7 */
  white-space: -o-pre-wrap;   /* Opera 7 */
  word-wrap: break-word;      /* IE */
}
.account-nav .account-modal .account-information .account-name,
.account-information-wrapper .account-sidebar .account-name{
  color: #000;
  font: normal 1rem/1.2em "HelveticaNeueCyrRoman", sans-serif; }
.account-nav .account-modal .account-information .account-name p,
.account-information-wrapper .account-sidebar .account-name p{
  color: #444444;
  font-size: 0.8rem;}
.account-nav .account-modal .account-information .account-name,
.account-nav .account-modal .account-information .logout-btn{
  display: inline-block;}
.account-nav .account-modal .account-information .logout-btn,
.account-information-wrapper .account-sidebar .logout-btn{
  float: right;
  padding: 0.2rem 1.2rem;
  border: 1px solid #0275D8;
  color: #0275D8;
  border-radius: 4px;
  background: transparent;
  font-size: 0.8rem;
  cursor: pointer;
}
.account-nav .account-modal .account-information .account-name b {
  font: normal 1.125rem/1.2em "HelveticaNeueCyrMedium", sans-serif; }
.account-nav .account-modal .account-information .account-list,
.account-information-wrapper .account-information .account-list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start; }
.account-nav .account-modal .account-information .account-list li {
  flex: 0 0 25%;
  max-width: 25%;
  margin-top: 1rem;
}
.account-nav .account-modal .account-information .account-list a,
.account-nav .account-modal .account-information .account-list button
{
  display: block;     background: none;
  border: none;}
.account-nav .account-modal .account-information .account-list a svg ,
.account-nav .account-modal .account-information .account-list button svg,
.account-nav .account-modal .account-information .account-list a i,
.account-nav .account-modal .account-information .account-list a img
{
  display: block;
  width: 1.875rem;
  height: 1.875rem;
  fill: #000;
  margin: 0 auto 0.75rem;
  transition: all 0.45s ease-in-out;}
.account-nav .account-modal .account-information .account-list a span ,
.account-nav .account-modal .account-information .account-list button span
{
  display: block;
  color: #000;
  font: normal 0.9375rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  transition: all 0.45s ease-in-out;}
.account-nav .account-modal .account-information .account-list a:hover svg ,
.account-nav .account-modal .account-information .account-list button:hover svg,
.account-nav .account-modal .account-information .account-list a:hover i{
  fill: #4bbae7;
}
.account-nav .account-modal .account-information .account-list a:hover span ,
.account-nav .account-modal .account-information .account-list a:hover i,
.account-nav .account-modal .account-information .account-list button:hover span {
  color: #4bbae7; }

.header .main-navigation .account-nav .forgot-modal {
  display: block; }
.header .main-navigation .mini-cart {
  padding-right: 0.625rem; }
.header .main-navigation .mini-cart .cart-button ,
.product-layout .product-thumb .product-buttons .cart-button
{
  position: relative;
  display: block;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.header .main-navigation .mini-cart .cart-button img,
.product-layout .product-thumb .product-buttons .cart-button img
{
  display: block;
  width: 26px;
  height: 23px; }
.header .main-navigation .mini-cart .cart-button .number {
  position: absolute;
  top: -0.575rem;
  right: -0.75rem;
  color: #fff;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif; }
.header .main-navigation .mini-cart .cart-button:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.header .main-navigation .mini-cart .mini-cart-content {
  display: none;
  position: absolute;
  top: -webkit-calc(100% + 1.25rem);
  top: calc(100% + 1.25rem);
  right: 1.25rem;
  width: 62.75rem;
  -webkit-box-shadow: 0 3px 7px #c4c4c4;
  box-shadow: 0 3px 7px #c4c4c4;
  background-color: #fff;
  z-index: 1; }
.header .main-navigation .mini-cart .mini-cart-content:before {
  content: '';
  position: absolute;
  top: -1.875rem;
  right: 0;
  border: 0.9375rem solid transparent;
  border-bottom: 0.9375rem solid #fff;
  z-index: 2;
}
.header .main-navigation .mini-cart .mini-cart-content .mini-cart-header {
  padding: 1rem 0;
  border-bottom: 1px solid #f2f2f2; }
.header .main-navigation .mini-cart .mini-cart-content .mini-cart-header .mini-cart-title {
  color: #141414;
  font: normal 1.5rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  margin-bottom: 0; }
.header .main-navigation .mini-cart .mini-cart-content .mini-cart-header .mini-cart-title.no-empty {
  display: none; }
.header .main-navigation .mini-cart .mini-cart-content .product-list {
  padding: 1.25rem;
  max-height: 70vh;
  overflow: auto; }
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  /*padding-bottom: 1.25rem;*/
  /*margin-bottom: 0.65rem;*/
  border-bottom: 1px solid #f2f2f2; }
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0; }
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .remove-product {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 2.625rem;
  -ms-flex: 0 0 2.625rem;
  flex: 0 0 2.625rem;
  max-width: 2.625rem; }
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .remove-product .remove {
  display: block;
  background: transparent;
  border: 0;
  padding: 0.25rem;
  width: 1.25rem;
  margin: 0 auto;
  cursor: pointer; }
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .remove-product .remove .remove-icon {
  display: block;
  fill: #ff0000;
  width: 0.75rem;
  height: 0.75rem;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-image {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 4.625rem;
  -ms-flex: 0 0 4.625rem;
  flex: 0 0 4.625rem;
  max-width: 4.625rem;
  margin-right: 0.525rem; }
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-image a {
  display: block;
  border: 1px solid #e9e9e9;
  background-color: #fff; }
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-image a img {
  display: block; }
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-image span img
{
  height:50px;
  margin: 0.5rem 0;
  min-width: 20px;
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-image a{
  margin: 0.5rem 0;
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-image img{
  height:50px;
}



.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-information {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 12.3125rem;
  -ms-flex: 0 0 12.3125rem;
  flex: 0 0 12.3125rem;
  max-width: 18.3125rem;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  margin-right: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-information p {
  /*color: #000;*/
  /*font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;*/
  /*margin-bottom: 0.625rem;*/
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-information p a {
  display: block;
  color: inherit;
  font: inherit; }
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-information p b {
  color: inherit;
  font: inherit; }
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-information p b.old-price {
  color: #777;
  font-size: 1rem; }
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-information p.product-title,
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .kit-fields span.properties

{
  text-align: center;
  font: inherit;
  /*font-family: "HelveticaNeueCyrMedium", sans-serif; */
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-information p.product-number .number {
  /*font-family: "HelveticaNeueCyrMedium", sans-serif;*/
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-information p.product-price {
  font: inherit;
  padding-top: 0.625rem; }
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-information p.product-price .price {
  margin-left: 0.3125rem;
  font: inherit;
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-quantity {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 6.125rem;
  -ms-flex: 0 0 6.125rem;
  flex: 0 0 6.125rem;
  max-width: 6.125rem;
  margin-right: 0.625rem; }
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-quantity .quantity-buttons {
  margin: 0 auto; }
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-total-price {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 12.5rem;
  -ms-flex: 0 0 12.5rem;
  flex: 0 0 12.5rem;
  max-width: 12.5rem; }
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-total-price .price {
  color: #505050;
  /*font: normal 1.5rem/1em "HelveticaNeueCyrRoman", sans-serif; */
  font: inherit;
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-price .price-value{
  font: inherit;
}

.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-total-price .price span {
  color: #4bbae7;
  font: inherit; }

.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item>div{
  -webkit-flex-basis: 0!important;
  flex-basis: 0!important;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
  flex-grow: 1!important;
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-options {
  font-size: 14px;
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-options .name-group-option {
  font-weight: 700;
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-options .name-option {
  color: #858585;
}



.header .main-navigation .mini-cart .mini-cart-content .total-price {
  background: #f8f8f8; }
.header .main-navigation .mini-cart .mini-cart-content .total-price .price {
  /*color: #000;*/
  /*font: normal 1.5rem/1em "HelveticaNeueCyrRoman", sans-serif;*/
  font: inherit;

  text-align: center;
  padding: 1rem 0; }
.header .main-navigation .mini-cart .mini-cart-content .total-price .price span{
  color: #4bbae7;
}

.header .main-navigation .mini-cart .mini-cart-content .total-price .price .number {
  color: inherit;
  font: inherit;
  /*font-family: "HelveticaNeueCyrMedium", sans-serif;*/
  margin-left: 0.625rem; }
.account-content .orders-history .order-item .order-products .link ,
.header .main-navigation .mini-cart .mini-cart-content .to-cart .link {
  display: block;
  color: #fff;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  text-transform: uppercase;
  background-color: #4bbae7;
  padding: 1.125rem 0;
  border: 0.125rem solid #4bbae7;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.header .main-navigation .mini-cart .mini-cart-content .to-cart .link:hover {
  color: #4bbae7;
  background: #fff;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.forgot-content {
  width: 28.75rem;
  max-width: 100%;
  -webkit-box-shadow: 0 1px 10px #818181;
  box-shadow: 0 1px 10px #818181;
  background-color: #fff; }
.forgot-content .forgot-title {
  padding: 1rem 0;
  border-bottom: 1px solid #ececed; }
.forgot-content .forgot-title h4 {
  color: #141414;
  font: normal 1.5rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  margin: 0; }
.forgot-content .forgot-form {
  padding: 1.25rem; }
.forgot-content .forgot-form form .field {
  margin-bottom: 1rem; }
.forgot-content .forgot-form form .field:last-child {
  margin-bottom: 0; }
.forgot-content .forgot-form form .field p {
  color: #505050;
  font: normal 0.875rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  margin-bottom: 0; }
.forgot-content .forgot-form form .field label {
  position: relative;
  display: block;
  margin: 0; }
.forgot-content .forgot-form form .field label:before {
  content: '';
  position: absolute;
  top: -webkit-calc(50% - 0.375rem);
  top: calc(50% - 0.375rem);
  left: -1.625rem;
  width: 1rem;
  height: 0.75rem;
  background: url("../../images/email-icon.svg") no-repeat center/cover; }
.forgot-content .forgot-form form .field label input {
  position: relative;
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  padding: 0.625rem;
  background: #fff;
  border: 1px solid #d8d8d8;
  width: 100%;
  z-index: 1; }
.forgot-content .forgot-form form .field.email {
  padding-left: 1.625rem; }
.forgot-content .forgot-form form .field.submit {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 1rem; }
.forgot-content .forgot-form form .field.submit .back-to-login {
  position: relative;
  display: block;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 6.875rem;
  -ms-flex: 0 0 6.875rem;
  flex: 0 0 6.875rem;
  max-width: 6.875rem;
  color: #989898;
  font: normal 0.875rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-decoration: underline;
  padding: 0 0 0 0.875rem;
  background: transparent;
  border: 0;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.forgot-content .forgot-form form .field.submit .back-to-login:before {
  content: '';
  position: absolute;
  top: -webkit-calc(50% - 0.3125rem);
  top: calc(50% - 0.3125rem);
  left: 0;
  width: 0.375rem;
  height: 0.625rem;
  background: url("../../images/arrow-left-icon.png"); }
.forgot-content .forgot-form form .field.submit .back-to-login:hover {
  color: #4bbae7;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.forgot-content .forgot-form form .field.submit input {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 6.75rem;
  -ms-flex: 0 0 6.75rem;
  flex: 0 0 6.75rem;
  max-width: 6.75rem;
  display: block;
  color: #fff;
  font: normal 0.9375rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  background: #4bbae7;
  padding: 0.625rem 0;
  border: 1px solid #4bbae7;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.forgot-content .forgot-form form .field.submit input:hover {
  color: #4bbae7;
  background: #fff;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

div.breadcrumb {
  display: block;
  margin: 0;
  padding: 0 15px;
  background: transparent;
  border: 0; }
div.breadcrumb .breadcrumb-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start; }
div.breadcrumb .breadcrumb-list .breadcrumb-item {
  position: relative;
  padding-right: 1.875rem; }
div.breadcrumb .breadcrumb-list .breadcrumb-item:before {
  display: none; }
div.breadcrumb .breadcrumb-list .breadcrumb-item:after {
  content: '';
  position: absolute;
  top: -webkit-calc(50% - 0.40625rem);
  top: calc(50% - 0.40625rem);
  right: 0.625rem;
  width: 0.5625rem;
  height: 0.8125rem;
  background: url("../../images/breadcrumb-icon.svg") no-repeat center/cover; }
div.breadcrumb .breadcrumb-list .breadcrumb-item:last-child {
  padding-right: 0; }
div.breadcrumb .breadcrumb-list .breadcrumb-item:last-child:after {
  display: none; }
div.breadcrumb .breadcrumb-list .breadcrumb-item .breadcrumb-link {
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
div.breadcrumb .breadcrumb-list .breadcrumb-item .breadcrumb-link .home-icon {
  display: block;
  width: 1.375rem;
  height: 1.25rem;
  fill: #000;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
div.breadcrumb .breadcrumb-list .breadcrumb-item .breadcrumb-link:hover {
  color: #6eacdc;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
div.breadcrumb .breadcrumb-list .breadcrumb-item .breadcrumb-link:hover .home-icon {
  fill: #6eacdc;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
div.breadcrumb .breadcrumb-list .breadcrumb-item .breadcrumb-current {
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif; }

.main-video {
  padding-left: 0;
  padding-right: 0;
  overflow: hidden; }
.main-video video {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto; }
.main-video img {
  width: 100%; }

.our-mission {
  color: #fff;
  background: #1a1a1a; }
.our-mission .about-text.col-12,
.our-mission .about-text {
  padding: 2.5rem 3.125rem; }
.our-mission .about-video {
  position: relative; }
.our-mission .about-video video {
  display: block; }
.our-mission .about-video .video-play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  z-index: 2; }
.our-mission .about-video .video-play svg {
  fill: #0070c9; }
.our-mission .about-video .video-play .play-icon {
  display: block;
  width: 5.25rem;
  height: 5.25rem; }
.our-mission .about-video .video-play .pause-icon {
  display: none !important;
  width: 0;
  height: 0; }
.our-mission .about-video .video-play.play svg {
  opacity: 0; }
.our-mission .about-video .video-play.play .play-icon {
  width: 0;
  height: 0; }
.our-mission .about-video .video-play.play .pause-icon {
  width: 5.25rem;
  height: 5.25rem; }
.our-mission .about-video .video-play.play:hover svg {
  opacity: 1; }
.our-mission .hide-description {
  display: none; }

.cabinets-section .room-navigation {
  border:0; }
.cabinets-section .room-navigation .nav-item {
  position: relative;
  bottom: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.333%;
  -ms-flex: 0 0 33.333%;
  flex: 0 0 33.333%;
  max-width: 33.333%;
  padding: 0.875rem;}
.cabinets-section .room-navigation .nav-item a {
  color: #1a1a1a;
  font: normal 1.875rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  padding: 1rem;
  border: 1px solid #1a1a1a;
  -webkit-border-radius: 1.875rem;
  border-radius: 1.875rem;
  background-color: transparent;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.cabinets-section .room-navigation .nav-item a.active {
  color: #fff;
  background: #1a1a1a;
  margin-bottom: 0;
}
.cabinets-section .calculator-element {
  background: transparent;
  margin-bottom: 1.875rem;
  height: auto;
  border:0; }

.kitchen-project {
  position: relative; }
.kitchen-project .svg-wrapper {
  margin: 0 auto;
  max-width: 1100px; }
.kitchen-project #kitchen {
  overflow: visible; }
.kitchen-project #kitchen #kitchen_b_bg {
  opacity: 1;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }
.kitchen-project #kitchen.hover #kitchen_b_bg {
  opacity: 0.3;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }
.kitchen-project path {
  fill: none;
  stroke: transparent;
  stroke-width: 3;
  pointer-events: all;
  opacity: 1;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }
.kitchen-project a:hover path {
  stroke: #6eacdc;
  opacity: 1;
  fill: url(#kitchenRamapattern);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }
.kitchen-project a.ahover path {
  stroke: #1a1a1a;
  opacity: 1;
  fill: url(#kitchenRamapattern);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }
.kitchen-project .tooltip {
  fill: #fff;
  font: normal 1rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  -webkit-border-radius: 0;
  border-radius: 0; }
.kitchen-project .tooltip_bg {
  fill: #000;
  stroke: #fff;
  stroke-width: 1;
  -webkit-border-radius: 0;
  border-radius: 0;
  opacity: 0.8; }
.kitchen-project .cabinet-list {
  position: absolute;
  bottom: 0;
  left: -webkit-calc(50% - 7rem);
  left: calc(50% - 7rem);
  width: 14rem;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid #fff;
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.kitchen-project .cabinet-list.active {
  bottom: 3rem;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.kitchen-project .cabinet-list .cabinet-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
.kitchen-project .cabinet-list .cabinet-item:last-child {
  border-bottom: 0; }
.kitchen-project .cabinet-list .cabinet-item a {
  display: block;
  color: #fff;
  font: normal 1rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  padding: 0.625rem;
  background: transparent;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.kitchen-project .cabinet-list .cabinet-item a:hover {
  color: #000;
  background: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.cabinet-elements .elements-list {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right; }
.cabinet-elements .elements-list .element-item {
  margin-bottom: 0.25rem; }
.cabinet-elements .elements-list .element-item:last-child {
  margin-bottom: 0; }
.cabinet-elements .elements-list .element-item a {
  position: relative;
  display: inline-block;
  color: #1a1a1a;
  font: normal 1.125rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.cabinet-elements .elements-list .element-item a:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 1px;
  background: #1a1a1a;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.cabinet-elements .elements-list .element-item a:hover:after {
  width: 100%;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.cabinet-elements .elements-list .element-item a.active {
  font-weight: 600; }
.cabinet-elements .elements-list .element-item a.active:after {
  width: 100%;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.bathroom-project {
  position: relative; }
.bathroom-project .svg-wrapper {
  margin: 0 auto;
  max-width: 1100px; }
.bathroom-project #bathroom {
  overflow: visible; }
.bathroom-project #bathroom #bathroom_b_bg {
  opacity: 1;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }
.bathroom-project #bathroom.hover #bathroom_b_bg {
  opacity: 0.3;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }
.bathroom-project path {
  fill: none;
  stroke: transparent;
  stroke-width: 3;
  pointer-events: all;
  opacity: 1;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }
.bathroom-project a:hover path {
  stroke: #6eacdc;
  opacity: 1;
  fill: url(#bathroomRamapattern);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }
.bathroom-project a.ahover path {
  stroke: #1a1a1a;
  opacity: 1;
  fill: url(#bathroomRamapattern);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }
.bathroom-project .tooltip {
  fill: #fff;
  font: normal 1rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  -webkit-border-radius: 0;
  border-radius: 0; }
.bathroom-project .tooltip_bg {
  fill: #000;
  stroke: #fff;
  stroke-width: 1;
  -webkit-border-radius: 0;
  border-radius: 0;
  opacity: 0.8; }
.bathroom-project .cabinet-list {
  position: absolute;
  bottom: 0;
  left: -webkit-calc(50% - 7rem);
  left: calc(50% - 7rem);
  width: 14rem;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid #fff;
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.bathroom-project .cabinet-list.active {
  bottom: 3rem;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.bathroom-project .cabinet-list .cabinet-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
.bathroom-project .cabinet-list .cabinet-item:last-child {
  border-bottom: 0; }
.bathroom-project .cabinet-list .cabinet-item a {
  display: block;
  color: #fff;
  font: normal 1rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  padding: 0.625rem;
  background: transparent;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.bathroom-project .cabinet-list .cabinet-item a:hover {
  color: #000;
  background: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.closet-project {
  position: relative; }
.closet-project .svg-wrapper {
  margin: 0 auto;
  max-width: 1100px; }
.closet-project #closet {
  overflow: visible; }
.closet-project #closet #closet_b_bg {
  opacity: 1;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }
.closet-project #closet.hover #closet_b_bg {
  opacity: 0.3;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }
.closet-project path {
  fill: none;
  stroke: transparent;
  stroke-width: 3;
  pointer-events: all;
  opacity: 1;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }
.closet-project a:hover path {
  stroke: #6eacdc;
  opacity: 1;
  fill: url(#closetRamapattern);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }
.closet-project a.ahover path {
  stroke: #1a1a1a;
  opacity: 1;
  fill: url(#closetRamapattern);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }
.closet-project .tooltip {
  fill: #fff;
  font: normal 1rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  -webkit-border-radius: 0;
  border-radius: 0; }
.closet-project .tooltip_bg {
  fill: #000;
  stroke: #fff;
  stroke-width: 1;
  -webkit-border-radius: 0;
  border-radius: 0;
  opacity: 0.8; }
.closet-project .cabinet-list {
  position: absolute;
  bottom: 0;
  left: -webkit-calc(50% - 7rem);
  left: calc(50% - 7rem);
  width: 14rem;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid #fff;
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.closet-project .cabinet-list.active {
  bottom: 3rem;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.closet-project .cabinet-list .cabinet-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
.closet-project .cabinet-list .cabinet-item:last-child {
  border-bottom: 0; }
.closet-project .cabinet-list .cabinet-item a {
  display: block;
  color: #fff;
  font: normal 1rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  padding: 0.625rem;
  background: transparent;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.closet-project .cabinet-list .cabinet-item a:hover {
  color: #000;
  background: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.assembly .assembly-items {
  margin-top: -2.5rem; }
.assembly .assembly-items .assembly-item {
  margin-top: 2.5rem; }
.assembly .assembly-items .assembly-item a {
  position: relative;
  display: block;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.assembly .assembly-items .assembly-item a img {
  display: block;
  width: 100%;
  margin-bottom: 0.875rem;
  border-bottom: 0.375rem solid transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  z-index: 1; }
.assembly .assembly-items .assembly-item a .assembly-title {
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.assembly .assembly-items .assembly-item a:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  z-index: 2;
}
.assembly .assembly-items .assembly-item a:after {
  content: '';
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  width: 2.8125rem;
  height: 3.125rem;
  z-index: 3; }
.assembly .assembly-items .assembly-item a.pdf:after {
  background: url("../../images/pdf-icon.svg") no-repeat center/cover; }
.assembly .assembly-items .assembly-item a.youtube:after {
  background: url("../../images/youtube-icon.svg") no-repeat center/cover;
  height: 2.8125rem; }
.assembly .assembly-items .assembly-item a:hover img {
  border-bottom: 0.375rem solid #6eacdc;
  -webkit-box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.35);
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.35);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.assembly .assembly-items .assembly-item a:hover .assembly-title {
  color: #6eacdc;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.assembly.owl-carousel {
  max-width: -webkit-calc(100% - 5rem);
  max-width: -moz-calc(100% - 5rem);
  max-width: calc(100% - 5rem);
  margin: 0 auto; }
.assembly.owl-carousel .owl-nav {
  display: block; }
.assembly.owl-carousel .owl-nav .owl-prev {
  position: absolute;
  top: 0;
  left: -2.5rem;
  width: 2.5rem;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /*background: #1a1a1a;*/
  /*border: 0.0625rem solid #1a1a1a;*/
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  z-index: 5; }
.assembly.owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 0;
  right: -2.5rem;
  width: 2.5rem;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  z-index: 5; }
.assembly.owl-carousel .owl-nav svg {
  display: block;
  fill: #fff;
  width: 2rem;
  height: auto;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.assembly.owl-carousel .owl-nav .owl-prev:hover svg,
.assembly.owl-carousel .owl-nav .owl-next:hover svg {
  fill: #1a1a1a;
}

.exterior-material-gallery .gallery-list {
  margin: -1.25rem -1.25rem 0;
  padding: 0;
  list-style: none;
}
.exterior-material-gallery.calculator-material.modal-gallery .gallery-list {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;
}
.exterior-material-gallery.calculator-material.modal-gallery .gallery-list .gallery-item {
  flex: 0 0 16.667%;
  max-width: 16.667%;
  margin: 0;
  padding: 0;
}
.exterior-material-gallery.calculator-material.modal-gallery .gallery-list .gallery-item a {
  position: relative;
  display: block;
  background: #fff;
  box-shadow: none;
  padding: .625rem;
  z-index: 1;
}
.exterior-material-gallery.calculator-material .gallery-list .gallery-item a img {
  box-shadow: none;
  transition: all .45s ease-in-out;
}


.exterior-material-gallery .gallery-list .gallery-item:nth-child(6n) {
  margin-right: 0; }
.exterior-material-gallery .gallery-list .gallery-item button {
  position: relative;
  display: block;
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
  padding: 0;
  width: 100%;
  border: 0;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  height:13rem}
.exterior-material-gallery .gallery-list .gallery-item button img {
  width: 100%;
  transform: scale(1.12);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.exterior-material-gallery .gallery-list .gallery-item button .material-title {
  position: absolute;
  display: block;
  color: #fff;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.25rem;
  background: #000;
  -webkit-transform: translate3d(0, 100% ,0);
  transform: translate3d(0, 100% ,0);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  z-index: 2; }
/*.exterior-material-gallery .gallery-list .gallery-item button:hover img {*/
/*  -webkit-transform: scale(1) translate3d(0, -1.25rem, 0);*/
/*  transform: scale(1) translate3d(0, -1.25rem, 0);*/
/*  -webkit-transition: all 0.45s ease-in-out;*/
/*  -o-transition: all 0.45s ease-in-out;*/
/*  transition: all 0.45s ease-in-out; }*/
.exterior-material-gallery .gallery-list .gallery-item button:hover .material-title {
  -webkit-transform: translate3d(0, 0 ,0);
  transform: translate3d(0, 0 ,0); }
.exterior-material-gallery.owl-carousel {
  max-width: -webkit-calc(100% - 5rem);
  max-width: -moz-calc(100% - 5rem);
  max-width: calc(100% - 5rem);
  margin: 0 auto; }

.exterior-material-gallery .gallery-list .gallery-item .modal-material-gallery {
  display: none;
}
.modal-material-gallery .lSSlideOuter .lightSlider li {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}
.modal-material-gallery .lSSlideOuter .lightSlider li img {
  max-height: 100%;
}
.modal-material-gallery .lSSlideOuter .lSPager.lSGallery li {
  background: #fff;
}
.modal-material-gallery .lSSlideOuter .lSPager.lSGallery a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.modal-material-gallery .lSSlideOuter .lSPager.lSGallery img {
  width: 90px;
  height: 90px;
}
.modal-gallery .carousel-slider .slider .slide {
  background: transparent; }
.modal-gallery .carousel-slider .slider .slide img {
  display: block;
  width: auto;
  max-height: 70vh;
  margin: 0 auto; }
.modal-gallery .carousel .thumbs .thumb {
  width: 60px;
  height: 60px;
  padding: 0;
  overflow: hidden; }
.modal-gallery .carousel .thumbs .thumb img {
  display: block;
  height: 100%;
  width: auto;
  max-width: 1000px;
  margin: 0 auto; }
.modal-gallery .carousel .thumbs-wrapper {
  margin: 1.875rem 0 0; }
.styles_closeButton__20ID4 {
  z-index: 5; }

.exterior-material-gallery.owl-carousel .owl-nav {
  display: block; }
.exterior-material-gallery.owl-carousel .owl-nav .owl-prev {
  position: absolute;
  top: 0;
  left: -2.5rem;
  width: 2.5rem;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  z-index: 5; }
.exterior-material-gallery.owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 0;
  right: -2.5rem;
  width: 2.5rem;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  z-index: 5; }
.exterior-material-gallery.owl-carousel .owl-nav svg {
  display: block;
  fill: #fff;
  width: 2rem;
  height: auto;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.exterior-material-gallery.owl-carousel .owl-nav .owl-prev:hover svg,
.exterior-material-gallery.owl-carousel .owl-nav .owl-next:hover svg {
  fill: #1a1a1a; }

.exterior-material-gallery.calculator-material {
  width: 100%;
  height: 80vh;
  overflow-y: scroll;
  max-width: 51.25rem; }
.exterior-material-gallery.calculator-material .gallery-list {
  margin: 0;
}
.exterior-material-gallery.calculator-material .gallery-list .gallery-item {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.667%;
  -ms-flex: 0 0 16.667%;
  flex: 0 0 16.667%;
  max-width: 16.667%;
  margin: 0;
  padding: 0; }
.exterior-material-gallery.calculator-material .gallery-list .gallery-item a {
  position: relative;
  display: block;
  background: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0.625rem;
  z-index: 1; }
.exterior-material-gallery.calculator-material .gallery-list .gallery-item a:before, .exterior-material-gallery.calculator-material .gallery-list .gallery-item a:after {
  display: none; }
.exterior-material-gallery.calculator-material .gallery-list .gallery-item a img {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.exterior-material-gallery.calculator-material .gallery-list .gallery-item a .material-name {
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
  color: #494949;
  font: normal 0.875rem/1.5em "HelveticaNeueCyrRoman", sans-serif;
  text-transform: uppercase;
  padding: 0.5rem 0.625rem;
  width: 100%;
  opacity: 0;
  height: 0;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.exterior-material-gallery.calculator-material .gallery-list .gallery-item a:hover {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
  z-index: 2; }
.exterior-material-gallery.calculator-material .gallery-list .gallery-item a:hover .material-name {
  background: #fff;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
  opacity: 1;
  height: auto;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.exterior-material-gallery.calculator-material .gallery-list .gallery-item a.checked img {
  -webkit-box-shadow: 0 0 0 0.1875rem #68baea;
  box-shadow: 0 0 0 0.1875rem #68baea;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.exterior-material-gallery.calculator-material .gallery-list .gallery-item a.checked .material-name {
  color: #68baea;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.slider {
  width: 100%;
}
.slider ul {
  list-style: none outside none;
  padding-left: 0;
  margin-bottom: 0; }
.slider li {
  display: block;
  float: left;
  margin-right: 0;
  cursor: pointer; }
.slider img {
  display: block;
  height: auto;
  width: 100%;
  max-width: 100%;
}
.slider .lSSlideOuter .lSPager.lSGallery {
  margin-top: 2.5rem !important; }
.slider .lSSlideOuter .lSPager.lSGallery li {
  -webkit-border-radius: 0;
  border-radius: 0; }
.slider .lSSlideOuter .lSPager.lSGallery li a {
  position: relative;
  display: block; }
.slider .lSSlideOuter .lSPager.lSGallery li a img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.slider .lSSlideOuter .lSPager.lSGallery li.active a img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.search .search-title h1 {
  color: #000;
  font: normal 1.875rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1.875rem; }
.search .search-title h1 .search-word {
  color: #0070c9;
  font: inherit;
  font-family: "HelveticaNeueCyrMedium", sans-serif; }
.search .search-title h1 .number {
  color: #0070c9;
  font: inherit;
  font-family: "HelveticaNeueCyrMedium", sans-serif; }

.search .result-items {
  max-width: 60rem;
  margin: 1.875rem auto 0; }
.search .result-items .result-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid #d8d8d8; }
.search .result-items .result-item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0; }
.search .result-items .result-item .product-image {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 6.25rem;
  -ms-flex: 0 0 6.25rem;
  flex: 0 0 6.25rem;
  max-width: 6.25rem;
  margin-right: 1.25rem; }
.search .result-items .result-item .product-image a {
  display: block;
  width: 6.25rem;
  height: 6.25rem;
  border: 1px solid #d8d8d8;
  background-color: #fff; }
.search .result-items .result-item .product-information {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 15.3125rem;
  -ms-flex: 1 0 15.3125rem;
  flex: 1 0 15.3125rem;
  max-width: 100%;
  margin-right: 1.25rem; }
.search .result-items .result-item .product-information .product-name {
  color: #000;
  font: normal 1.25rem/1.2em "HelveticaNeueCyrMedium", sans-serif;
  margin-bottom: 0.625rem;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.search .result-items .result-item .product-information .product-name a {
  color: inherit;
  font: inherit; }
.search .result-items .result-item .product-information .product-code {
  padding-bottom: 1rem; }
.search .result-items .result-item .product-information .product-code .text {
  font-size: 1rem;
  text-transform: none; }
.search .result-items .result-item .product-information .product-code .value {
  font-size: 1rem; }
.search .result-items .result-item .product-information .product-price {
  margin-right: 0; }
.search .result-items .result-item .product-information .product-price .price-text {
  font-size: 1rem; }
.search .result-items .result-item .product-information .product-price .price-value {
  font-size: 1rem; }
.search .result-items .result-item .product-link {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 9.25rem;
  -ms-flex: 0 0 9.25rem;
  flex: 0 0 9.25rem;
  max-width: 9.25rem; }
.search .result-items .result-item .product-link a {
  display: block;
  color: #5f5f5f;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  width: 100%;
  padding: 0.625rem 0;
  border: 1px solid #5f5f5f;
  background: transparent;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.search .result-items .result-item .product-link a:hover {
  color: #fff;
  background: #5f5f5f;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.search .result-items .result-item.promotional-price .product-image:after {
  content: '';
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  width: 3rem;
  height: 3rem;
  background: url("../../images/promotional-icon.png") no-repeat center/cover; }
.search .result-items .result-item:hover .product-information .product-name {
  color: #0070c9;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }


.contact-map iframe {
  display: block;
  max-width: 100%;
  height: 100%; }

.category-main-image {
  padding-left: 0;
  padding-right: 0; }
.category-main-image img {
  display: block;
  height: auto;
  width: 100%; }

.category-title h1 {
  text-transform: uppercase;
  color: #000;
  font: normal 2.25rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  margin-bottom: 1.25rem; }
.category-description{
  margin: 0 auto;
  text-align: center;
}
.touchevents .category-description{
  display: none;
}
.category-list.row {
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 0;
  margin-right: 0; }
.module.module-viewed-products .description{
  margin: 1rem auto;
}
.category-list .category-item{
  flex: 0 0 15%;
  max-width: 15%;
  background: #fff;
  padding: 0;
  border: 2px solid #E5E5E5;
  border-radius: 5px;
  margin: 0 0.5rem;
  display:flex;
  flex-direction: column;
}
.category-list .category-item:hover{
  border-color: #0275D8;
}
.category-list .category-item .category-name{
  margin: 1rem 1.5rem;
  text-align: center;
  text-transform: uppercase;
  flex: 0 0 15%;
  max-height: 15%;
}
.category-list .category-item:hover .category-name,
.category-list .category-item .category-name a:hover{
  color: #0275D8;
}
.category-list .category-item .category-image{
  flex: 0 0 60%;
  max-height: 60%;
  overflow: hidden;
}
.category-list .category-item .category-view-btn {
  flex: 0 0 11%;
  max-height: 11%;
  margin: auto;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 2px solid #0275D8;
  border-radius: 5px;
  background: #0275D8;
  color: #fff;
  visibility: hidden;
}

.category-list .category-item:hover .category-view-btn{
  visibility: visible;
}
.module .module-title {
  margin-bottom: 2.5rem; }
.module .module-title h3 {
  color: #000;
  font: normal 2.25rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  margin-bottom: 0; }

.module .banner {
  position: relative;
  overflow: hidden;
  margin-bottom: 1.875rem; }
.module .banner .banner-image {
  position: relative;
  z-index: 1; }
.module .banner .banner-image a {
  display: block;
  overflow: hidden; }
.module .banner .banner-image a img {
  display: block;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  width: 100%;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.module .banner .banner-title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  z-index: 2;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.module .banner .banner-title h5 {
  color: #fff;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0; }
.module .banner .banner-title h5 a {
  display: block;
  color: inherit;
  font: inherit;
  padding: 0.75rem 0; }
.module .banner:hover .banner-image a img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.module .banner:hover .banner-title {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.module .viewed-nav {
  display: none; }
.module .viewed-nav.show {
  display: block;
  padding-top: 1.25rem; }
.module .viewed-nav.show .owl-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
.module .viewed-nav.show .owl-nav .owl-prev {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  background: #fff;
  border: 1px solid #d8d8d8;
  -webkit-border-radius: 2.5rem;
  border-radius: 2.5rem;
  margin-right: 1.25rem;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.module .viewed-nav.show .owl-nav .owl-prev .arrow-left {
  display: block;
  width: 1.375rem;
  height: 0.625rem;
  fill: #000;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.module .viewed-nav.show .owl-nav .owl-prev:hover {
  border-color: #0070c9;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.module .viewed-nav.show .owl-nav .owl-prev:hover .arrow-left {
  fill: #0070c9;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.module .viewed-nav.show .owl-nav .owl-next {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  background: #fff;
  border: 1px solid #d8d8d8;
  -webkit-border-radius: 2.5rem;
  border-radius: 2.5rem;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.module .viewed-nav.show .owl-nav .owl-next .arrow-right {
  display: block;
  width: 1.375rem;
  height: 0.625rem;
  fill: #000;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.module .viewed-nav.show .owl-nav .owl-next:hover {
  border-color: #0070c9;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.module .viewed-nav.show .owl-nav .owl-next:hover .arrow-right {
  fill: #0070c9;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.module .viewed-products {
  padding: 0;
}
.module .viewed-products .viewed-product {
  padding: 0 0.625rem;
}
.module .viewed-products .viewed-product .product-thumb {
  border-radius: 3px;
  padding: 1rem;
  position: relative;
  border: 2px solid transparent;
  background-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.module .viewed-products .viewed-product:hover .product-thumb {
  border-color: #0275D8;
}
.module .viewed-products .viewed-product .product-thumb .product-image {
  margin-bottom: 1rem;
  position: relative;
  z-index: 1; }
.module .viewed-products .viewed-product .product-thumb .product-image a {
  display: block; }
.module .viewed-products .viewed-product .product-thumb .product-image a img {
  display: block;
  width: 100%; }
.module .viewed-products .viewed-product .product-thumb .product-title {
  /*position: absolute;*/
  /*bottom: 0;*/
  /*left: 0;*/
  /*width: 100%;*/
  /*margin: 0;*/
  z-index: 2; }
.module .viewed-products .viewed-product .product-thumb .product-title {
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  margin-bottom: 0;
  border-bottom: 0 solid transparent;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.module .viewed-products .viewed-product .product-thumb .product-title a {
  display: block;
  color: inherit;
  font: inherit;
  padding: 0.75rem 0; }
.module .viewed-products .viewed-product .product-thumb:hover {
  -webkit-box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.35);
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.35);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
/*.module .viewed-products .viewed-product .product-thumb:hover .product-title {*/
/*  color: #0070c9;*/
/*  border-bottom: 0.375rem solid #6eacdc;*/
/*  -webkit-transition: all 0.45s ease-in-out;*/
/*  -o-transition: all 0.45s ease-in-out;*/
/*  transition: all 0.45s ease-in-out; }*/
.module .viewed-products .viewed-product.promotional-price .viewed-content:after {
  content: '';
  position: absolute;
  top: -0.4378rem;
  right: -0.4378rem;
  width: 4.3125rem;
  height: 4.375rem;
  background: url("../../images/promotional-icon.png") no-repeat center/cover;
  z-index: 2; }
.module .viewed-products .owl-carousel {
  display: none; }
.module .viewed-products .owl-carousel.owl-loaded {
  display: block; }
.module .viewed-products .owl-carousel.owl-loaded .viewed-product {
  max-width: 100%; }

.category-navigation {
  position: relative;
  margin-bottom: 1.875rem; }
.category-navigation .current-category {
  position: relative;
  color: #000;
  font: normal 0.9375rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-transform: uppercase;
  padding: 1.0625rem 1.25rem;
  border: 1px solid #5f5f5f;
  background: #fff;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  cursor: pointer;
  z-index: 2; }
.category-navigation .current-category:after {
  content: '';
  position: absolute;
  top: -webkit-calc(50% - 0.25rem);
  top: calc(50% - 0.25rem);
  right: 1.25rem;
  width: 0.9375rem;
  height: 0.5rem;
  background: url("../../images/arrow_icon_down.svg") no-repeat center/cover;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.category-navigation .category-list {
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  border: 1px solid #5f5f5f;
  background: #f5f5f5;
  border-top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  z-index: 3; }
.category-navigation .category-list .list-item {
  position: relative;
  color: #000;
  font: normal 0.9375rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-transform: uppercase;
  padding: 1.0625rem 1.25rem;
  border-bottom: 1px solid #5f5f5f;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  cursor: pointer; }
.category-navigation .category-list .list-item:last-child {
  border-bottom: 0; }
.category-navigation .category-list .list-item:hover, .category-navigation .category-list .list-item.current {
  color: #fff;
  background: #0070c9;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.category-navigation.active .current-category:after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.category-navigation.active .category-list {
  top: 100%;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.filters .filters-title {
  color: #ababab;
  font: normal 1rem/1.5em "HelveticaNeueCyrRoman", sans-serif;
  text-transform: uppercase;
  margin-bottom: 1.875rem; }

.filters .filter{
  border-top: 1px solid #e5e5e5;
  margin-bottom: 1rem;
  padding-top: 1rem; }
.filters .filter:first-child{
  border: none;
  padding-top: 0; }
.filters .filter:last-child{
  margin-bottom: 0;}
.filters .filter .filter-title {
  position: relative;
  color: #000;
  font: normal 1rem/1.5em "HelveticaNeueCyrMedium", sans-serif;
  cursor: pointer;
  margin-bottom: 0; }
.filters .filter .filter-list {
  padding: 0;
  margin-right: -10px;
  margin-left: -10px;
  display: none;}
.filters .filter .filter-list.active {
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  display: block;}
.filters .filter .filter-list .filter-item {
  padding-left: 10px;
  padding-right: 10px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch; }
.filters .filter .filter-list .filter-item label {
  position: relative;
  display: block;
  margin: 0;
  height: 100%;
  cursor: pointer; }
.filters .filter .filter-list .filter-item label input {
  /*position: absolute;
  opacity: 0; */}
.filters .filter .filter-list .filter-item label .filter-name {
  color: #000;
  font: normal 0.875rem/1em "HelveticaNeueCyrLight", sans-serif;
  text-align: center;
  background: #fff;
  height: 100%;
  padding: 0 0.35rem;
  opacity: 1;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.filters .filter .filter-list .filter-item label .filter-name:hover {
  color: #0070c9;
  border-color: #0070c9;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.filters .filter .filter-list .filter-item label input:checked + .filter-name {
  color: #0275D8;
  background: #fff;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.filters .filter .filter-list .filter-item.hide-filter {
  display: none; }
.filters .filter .filter-list .filter-item.deactivated label {
  cursor: default; }
.filters .filter .filter-list .filter-item.deactivated label .filter-name {
  color: #000;
  border-color: #d8d8d8;
  opacity: 0.5;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.filters .filter .show-more {
  margin: 1.25rem 0 0; }
.filters .filter .show-more .more-button {
  position: relative;
  display: inline-block;
  color: #000;
  font: normal 0.875rem/2.15em "HelveticaNeueCyrRoman", sans-serif;
  padding: 0 0 0 1.5625rem;
  border: 0;
  cursor: pointer;
  background: transparent; }
.filters .filter .show-more .more-button .text {
  color: inherit;
  font: inherit;
  width: 2.25rem; }
.filters .filter .show-more .more-button .number {
  color: inherit;
  font: inherit;
  opacity: 1;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.filters .filter .show-more .more-button:after {
  content: '';
  position: absolute;
  top: -webkit-calc(50% - 0.25rem);
  top: calc(50% - 0.25rem);
  left: 0;
  width: 0.9375rem;
  height: 0.5rem;
  background: url("../../images/arrow_icon_down.svg") no-repeat center/cover;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.filters .filter .show-more .more-button.active .number {
  opacity: 0;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.filters .filter .show-more .more-button.active:after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.filters .filter .filter-title:after {
  background: url("../../images/arrow_icon_down.svg") no-repeat center/cover;
  content: '';
  position: absolute;
  top: 0.25rem;
  right: 0;
  width: 0.9375rem;
  height: 0.5rem;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  transition: all 0.45s ease-in-out;}
.filters .filter .filter-title.active:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.filters .filter.load-capacity .input-range {
  margin: 3.875rem auto 2rem;
  max-width: -webkit-calc(100% - 1.75rem);
  max-width: calc(100% - 1.75rem); }
.filters .filter.load-capacity .input-range .input-range__track--background {
  position: relative;
  top: auto;
  height: 0.1875rem;
  border: 0;
  background: #d8d8d8;
  margin-top: 0; }
.filters .filter.load-capacity .input-range .input-range__track--active {
  background: #6eacdc;
  height: 100%;
  outline: none; }
.filters .filter.load-capacity .input-range .input-range__slider {
  top: -webkit-calc(50% - 0.35rem);
  top: calc(50% - 0.35rem);
  width: 1.75rem;
  height: 1.75rem;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
  border: 1px solid #d8d8d8;
  background-color: #fff;
  -webkit-border-radius: 1.75rem;
  border-radius: 1.75rem;
  margin-left: -0.875rem;
  outline: none;
  cursor: pointer; }
.filters .filter.load-capacity .input-range .input-range__label-container {
  position: absolute;
  left: -webkit-calc(50% - 1.625rem);
  left: calc(50% - 1.625rem);
  top: -1rem;
  display: block;
  color: #000;
  font: normal 0.875rem/2em "HelveticaNeueCyrLight", sans-serif;
  background: #fff;
  width: 3.25rem;
  text-align: center; }

.category-sorting {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end; }
.category-sorting .matching-products .matching-number {
  color: #000;
  font: normal 1rem/2.25em "HelveticaNeueCyrRoman", sans-serif;
  margin-bottom: 0; }
.category-sorting .matching-products .matching-number .number {
  color: inherit;
  font: inherit; }
.category-sorting .sorting {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 12rem;
  -ms-flex: 1 0 12rem;
  flex: 1 0 12rem;
  max-width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  /*-webkit-justify-content: flex-end;*/
  -ms-flex-pack: end;
  /*justify-content: flex-end;*/ }
.category-sorting .sorting .sorting-text {
  color: #000;
  font: normal 0.875rem/1em "HelveticaNeueCyrLight", sans-serif;
  margin-right: 0.625rem; }
.category-sorting .sorting .sorting-variant {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 7.75rem;
  -ms-flex: 0 0 7.75rem;
  flex: 0 0 7.75rem;
  max-width: 7.75rem; }
.category-sorting .sorting .sorting-variant .current-sorting {
  position: relative;
  color: #000;
  font: normal 0.875rem/1em "HelveticaNeueCyrLight", sans-serif;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  padding: 0.625rem 2.875rem 0.625rem 1.25rem;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.category-sorting .sorting .sorting-variant .current-sorting:after {
  content: '';
  position: absolute;
  top: -webkit-calc(50% - 0.25rem);
  top: calc(50% - 0.25rem);
  right: 1.25rem;
  width: 0.9375rem;
  height: 0.5rem;
  background: url("../../images/arrow_icon_down.svg") no-repeat center/cover;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.category-sorting .sorting .sorting-variant .sorting-list {
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  border: 1px solid #d8d8d8;
  background: #f5f5f5;
  border-top: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 3;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.category-sorting .sorting .sorting-variant .sorting-list .list-item {
  position: relative;
  color: #000;
  font: normal 0.875rem/1em "HelveticaNeueCyrLight", sans-serif;
  padding: 0.625rem 1.25rem;
  border-bottom: 1px solid #d8d8d8;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  cursor: pointer; }
.category-sorting .sorting .sorting-variant .sorting-list .list-item:last-child {
  border-bottom: 0; }
.category-sorting .sorting .sorting-variant .sorting-list .list-item.current, .category-sorting .sorting .sorting-variant .sorting-list .list-item:hover {
  color: #fff;
  background: #0070c9;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.category-sorting .sorting .sorting-variant.active .current-sorting:after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.category-sorting .sorting .sorting-variant.active .sorting-list {
  top: 100%;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.category-sorting .filter-result {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 0.625rem; }
.category-sorting .filter-result .result-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start; }
.category-sorting .filter-result .result-list .result-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-border-radius: 1.125rem;
  border-radius: 1.125rem;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  margin-right: 0.875rem; }
.category-sorting .filter-result .result-list .result-item:last-child {
  margin-right: 0; }
.category-sorting .filter-result .result-list .result-item .result {
  display: block;
  color: #000;
  font: normal 0.875rem/1em "HelveticaNeueCyrLight", sans-serif;
  padding: 0.625rem 0 0.625rem 1.25rem; }
.category-sorting .filter-result .result-list .result-item .remove-result {
  padding: 0.75rem;
  background: transparent;
  border: 0;
  cursor: pointer; }
.category-sorting .filter-result .result-list .result-item .remove-result .close-icon {
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  fill: #000;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.category-sorting .filter-result .result-list .result-item:hover {
  border-color: #0070c9;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.category-sorting .filter-result .result-list .result-item:hover .remove-result .close-icon {
  fill: #ff9797;
  stroke: #ff9797;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.category-sorting .filter-result .result-list .result-item.clear {
  border-color: #ff9797;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.category-sorting .filter-result .result-list .result-item.clear .clear-all {
  display: block;
  color: #000;
  font: normal 0.875rem/1em "HelveticaNeueCyrLight", sans-serif;
  padding: 0.625rem 1.25rem;
  background: transparent;
  border: 0;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.category-sorting .filter-result .result-list .result-item.clear:hover {
  background: #ff9797;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.category-sorting .filter-result .result-list .result-item.clear:hover .clear-all {
  color: #fff;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.product-layout .product-thumb {
  position: relative;
  background-color: #fff;
  margin-top: 1.875rem;
  -webkit-box-shadow: 0 0 0 1px #d8d8d8;
  box-shadow: 0 0 0 1px #d8d8d8;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.product-layout .product-thumb.promotional-price:after {
  content: '';
  position: absolute;
  top: -0.4378rem;
  right: -0.4378rem;
  width: 4.3125rem;
  height: 4.375rem;
  background: url("../../images/promotional-icon.png") no-repeat center/cover;
  z-index: 2; }
.product-layout .product-thumb .product-image {
  position: relative;
  z-index: 1; }
.product-layout .product-thumb .product-image a {
  display: block;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.product-layout .product-thumb .product-image a img {
  display: block;
  width: 100%; }
.samples .product-layout .product-thumb{
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  padding: 2rem 1rem;
  background: #F5F5F5;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 10px;
}
.touchevents .samples .product-layout .product-thumb{
  height: 100%;
  padding-bottom: 0;
}
.touchevents .samples .product-layout{
  margin-bottom: 2rem!important;
}
@media (max-width: 1600px) {
  .samples .product-layout .product-thumb{
    width: 95%;
  }
}

@media (max-width: 1200px) {
  .samples-header{
    padding: 0 4rem;
  }
  img.responsive{
    width:auto!important;
    height:auto!important;
  }
}
@media (max-width: 900px) {
  .anchor-item {
    width: 85%;
  }
}



.samples .product-layout .product-thumb .product-image{
  flex:0 0 50%;
  max-height:50%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.samples .product-layout .product-thumb .sample-bottom{
  flex:0 0 50%;
  max-height:50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.samples .product-layout .product-thumb .product-buttons input[type="number"]{
  width: 30%;
}
.samples .product-layout .product-thumb .product-buttons{
  background: rgba(0, 0, 0, 0.7);
  padding: 0.3rem;
  display: flex;
  justify-content: space-around;
}


.product-layout .product-thumb .product-title,
.samples .product-layout .product-thumb .product-buttons
{
  position: absolute;
  bottom: 0;
  left: 0;
  color: #000;
  font: normal 1rem/1.5em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  margin-bottom: 0;
  border-bottom: 0 solid transparent;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  width: 100%;
  z-index: 2; }
.samples .product-layout .product-thumb .product-buttons,
.samples .product-layout .product-thumb .product-title
{
  position: static;
}
.samples .product-layout .product-thumb .product-image img{
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 0 0;
}
.samples .product-layout .product-thumb .product-title{
  color: #373A3C;
  text-align: left;
  bottom: 3rem;}
.samples .product-layout .product-thumb .product-price,
.samples .product-layout .product-thumb .total-btn{
  margin: 0;
  justify-content: space-between;
}
.samples.mobile .product-layout .product-thumb .product-price{
  justify-content: space-around;
}
.samples.mobile .product-layout .product-thumb .total-btn{
  justify-content: center;
}
.samples.mobile .product-layout .product-thumb .product-price .price-value{
  width: 100%;
  text-align: left;
}
.samples .product-layout .product-thumb .product-price .price-value{
  color: #979797;
  font: normal 0.9rem/1.25em "HelveticaNeueCyrRoman", sans-serif;
}
.samples .product-layout .product-thumb .product-buttons{
  bottom: 0;
}
.samples .product-layout .product-thumb .total-btn-row .total-btn .price-total{
  display: block;
}
.samples.mobile .product-layout .product-thumb .total-btn-row .total-btn .total-price,
.samples.mobile .product-layout .product-thumb .total-btn-row .total-btn .price-total{
  font: normal 0.9rem/1.25em "HelveticaNeueCyrRoman", sans-serif;
}
.samples.mobile .product-layout .product-thumb .total-btn-row .total-btn .price-total{
  display: inline;
}
.samples.mobile .product-layout .product-thumb .total-btn-row .total-btn .total-price-btn{
  width: 125%;
  margin: 5px -15px 0;
}
.samples .product-layout .product-thumb .total-btn-row .total-btn .total-price-btn .cart-button {
  background: #28A745;
  border: none;
  border-radius: 4px;
  width: 100%;
  display: block;
  color: #fff;
  padding: 0.3rem 0.9rem;
  cursor: pointer;
}
.product-layout .product-thumb .product-buttons .cart-button:hover{
  transform: scale(1.2);
}
.product-layout .product-thumb .product-title a {
  display: block;
  color: inherit;
  font: inherit;
  padding:  1.25rem 0 0;
}
.touchevents .product-layout .product-thumb:hover{
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
.product-layout .product-thumb:hover
{
  -webkit-box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.35);
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.35);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
/*.product-layout .product-thumb:hover .product-title {*/
/*  color: #0070c9;*/
/*  border-bottom: 0.375rem solid #6eacdc;*/
/*  -webkit-transition: all 0.45s ease-in-out;*/
/*  -o-transition: all 0.45s ease-in-out;*/
/*  transition: all 0.45s ease-in-out; }*/

.product-pagination .pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: center;
  justify-content: flex-end; }
.product-pagination .pagination .pagination-item {
  margin-right: 1.25rem; }
.product-pagination .pagination .pagination-item:last-child {
  margin-right: 0; }
.product-pagination .pagination .pagination-item .pagination-link {
  position: relative;
  display: block;
  color: #ababab;
  font: normal 0.875rem/1em "HelveticaNeueCyrLight", sans-serif;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.product-pagination .pagination .pagination-item .pagination-link svg {
  display: block;
  width: 0.65rem;
  height: 1rem;
  fill: #000;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.product-pagination .pagination .pagination-item .pagination-link:after {
  content: '';
  position: absolute;
  bottom: -0.125rem;
  left: 0;
  width: 0;
  height: 0.125rem;
  background: #6eacdc;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.product-pagination .pagination .pagination-item .pagination-link:hover {
  color: #096DD9;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.product-pagination .pagination .pagination-item .pagination-link.current {
  color: #096DD9;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.product-pagination .pagination .pagination-item .pagination-link.current:after {
  width: 100%;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.product-pagination .pagination .pagination-item.pagination-next.disabled .pagination-link:hover,
.product-pagination .pagination .pagination-item.pagination-prev.disabled .pagination-link:hover{
  color: #ababab!important;
}
.product-pagination .pagination .pagination-item .pagination-next:not(.disabled),
.product-pagination .pagination .pagination-item .pagination-prev:not(.disabled),
.product-pagination .pagination .pagination-item .pagination-next:not(.disabled):hover,
.product-pagination .pagination .pagination-item .pagination-prev:not(.disabled):hover{
  color: #096DD9;
}

.gallery .product-gallery {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start; }
.gallery .product-gallery .main-images {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 75%;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
  border: 1px solid #d8d8d8;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1; }
.gallery .product-gallery .main-images .main-item {
  background: #fff; }
.gallery .product-gallery .main-images .main-item a {
  display: block; }
.gallery .product-gallery .main-images .main-item a img {
  display: block;
  width: 100%; }
.gallery .product-gallery .slick-slider img {
  height: auto;
}
.gallery .product-gallery .thumbnail-images {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 15%;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  max-width: 15%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-right: 5%; }
.gallery .product-gallery .thumbnail-images .thumbnail-item {
  background: #fff;
  border: 1px solid transparent;
  margin-bottom: 1.25rem;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.gallery .product-gallery .thumbnail-images .thumbnail-item:last-child {
  margin-bottom: 0; }
.gallery .product-gallery .thumbnail-images .thumbnail-item a {
  display: block; }
.gallery .product-gallery .thumbnail-images .thumbnail-item a img {
  display: block;
  width: 100%; }
.gallery .product-gallery .thumbnail-images .thumbnail-item:hover {
  border-color: #6eacdc;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.promotional-price .gallery .product-gallery .main-images {
  position: relative; }
.promotional-price .gallery .product-gallery .main-images:after {
  content: '';
  position: absolute;
  top: -0.4378rem;
  right: -0.4378rem;
  width: 4.3125rem;
  height: 4.375rem;
  background: url("../../images/promotional-icon.png") no-repeat center/cover; }

.product-title {
  color: #000;
  font: normal 1.875rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  margin-bottom: 1.25rem; }

.product-code {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding-bottom: 1.875rem; }
.product-code .text {
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-transform: uppercase;
  margin-right: 0.1875rem; }
.product-code .value {
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrMedium", sans-serif;
  text-transform: uppercase; }

.options {
  margin-bottom: 3.125rem; }
.options .options-title {
  color: #000;
  font: normal 1rem/1.5em "HelveticaNeueCyrMedium", sans-serif;
  margin-bottom: 1rem; }

.option {
  margin-bottom: 1.25rem; }
.option:last-child {
  margin-bottom: 0; }
.option .option-name {
  color: #000;
  font: normal 1rem/1.5em "HelveticaNeueCyrLight", sans-serif;
  margin-bottom: 0.5rem; }
.option .option-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start; }
.option .option-list .option-item {
  margin-right: 0.625rem;
  flex: 0 0 15%;
  max-width: 15%;
  position: relative;
}
.option .option-list .option-item svg {
  display: none;
}
.option .option-list .option-item.disabled svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: #e5e5e5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.option .option-list .option-item:last-child {
  margin-right: 0; }
.option .option-list .option-item label {
  position: relative;
  display: block;
  margin: 0;
  cursor: pointer; }
.option .option-list .option-item label input {
  position: absolute;
  opacity: 0;
  visibility: hidden; }
.option .option-list .option-item label .value {
  display: block;
  /*color: #000;*/
  font: normal 0.875rem/1em "HelveticaNeueCyrLight", sans-serif;
  text-align: center;
  /*padding: 0.625rem 0;*/
  /*border: 1px solid #d8d8d8;*/
  /*background-color: #fff;*/
  /*min-width: 7rem;*/
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.option .option-list .option-item.checked,
.option .option-list .option-item:hover{
  border-color: #0275D8;
  color: #0275D8;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.option .option-list .option-item label:hover .value {
  border-color: #6eacdc;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.option .option-list .option-item label input:checked + .value {
  /*color: #fff;*/
  /*border-color: #6eacdc;*/
  /*background: #6eacdc;*/
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.option .option-list .option-item.disabled,
.option .option-list .option-item.disabled label input + .value{
  border-color: #c0c0c0 !important;
  color: #c0c0c0!important;
  background: transparent;
}
.price-quantity {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.product-price,
.total-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-right: 3.75rem; }
.product-price .price-text {
  color: #000;
  font: normal 1.5rem/1.25em "HelveticaNeueCyrRoman", sans-serif;
  margin-bottom: 0;
  margin-right: 0.25rem; }
.product-price .price-value {
  color: #4bbae7;
  font: normal 1.5rem/1.25em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  margin-bottom: 0; }

.mini-cart .product-price {
  margin-right: 0.75rem;
}
.cart .product-price .price-value,
.mini-cart .product-price .price-value {
  line-height: 0.9em; }
.product-price .price-value b {
  position: relative;
  display: block;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  color: inherit;
  font: inherit; }
.product-price .price-value b.old-price {
  color: #777; }
.product-price .price-value b.old-price:after {
  content: '';
  position: absolute;
  top: 50%;
  left: -5%;
  width: 110%;
  height: 1px;
  background: #ff0000; }

.product-quantity {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
.product-quantity .quantity-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
.product-quantity .quantity-buttons .quantity {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 1.5rem;
  -ms-flex: 0 0 1.5rem;
  flex: 0 0 1.5rem;
  max-width: 1.5rem;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch; }
.product-quantity .quantity-buttons .quantity .quantity-minus,
.product-quantity .quantity-buttons .quantity .quantity-plus {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  /*border: 0;*/
  background: transparent;
  padding: 0;
  height: 100%;
  width: 1.5rem;
  cursor: pointer;
  border: 1px solid #d8d8d8;

}
.product-quantity .quantity-buttons .quantity .quantity-minus:hover,
.product-quantity .quantity-buttons .quantity .quantity-plus:hover{
  background-color: #ebebeb;
}
.product-quantity .quantity-buttons .quantity .quantity-minus svg,
.product-quantity .quantity-buttons .quantity .quantity-plus svg {
  display: block;
  fill: #000;
  width: 1rem;
  height: 1rem; }
.product-quantity .quantity-buttons .quantity .quantity-number {
  display: block;
  color: #000;
  font: normal 1.0rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  width: 1.5rem;
  background: #fff;
  border: 1px solid #d8d8d8;
  border-left:none;
  border-right:none;
  padding: 2px;
  cursor: default; }

.product-button {
  margin-top: 1.25rem; }
.product-button .add-to-cart {
  display: block;
  color: #fff;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  width: 9.125rem;
  padding: 0.875rem 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #5f5f5f;
  background-color: #6eacdc;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.product-button .add-to-cart:hover {
  -webkit-box-shadow: 0 5px 10px #8c8c8c;
  box-shadow: 0 5px 10px #8c8c8c;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.product-tabs {
  margin: 3.75rem 0 0; }
.product-tabs .tab-navigation {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  z-index: 1; }
.product-tabs .tab-navigation:after {
  content: '';
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #d8d8d8; }
.product-tabs .tab-navigation .navigation-item {
  position: relative;
  margin-right: 2.5rem;
  z-index: 2; }
.product-tabs .tab-navigation .navigation-item:last-child {
  margin-right: 0; }
.product-tabs .tab-navigation .navigation-item a {
  position: relative;
  display: block;
  color: #444;
  font: normal 1rem/1.5em "HelveticaNeueCyrMedium", sans-serif;
  padding-bottom: 0.5rem;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.product-tabs .tab-navigation .navigation-item a:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0.1875rem;
  background: #6eacdc;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.product-tabs .tab-navigation .navigation-item a.active  {
  color: #000;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.product-tabs .tab-navigation .navigation-item a.active:after {
  left: 0;
  width: 100%;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }


.checkout-form .account-content:after {
  display: none;
}
.product-tabs .tabs .tab {
  display: none; }
.product-tabs .tabs .tab.active {
  display: block; }
.product-tabs .tabs .tab .tab-content {
  color: #000;
  padding-top: 1.25rem;
  border:none;
}
.product-tabs .tabs.tab-content {
  color: #000;
  padding-top: 1.25rem;
  border:none;
}
.product-tabs .nav-tabs {
  border:none;
}
.product-tabs .tabs .tab .specifications-list {
  border: 1px solid #d8d8d8; }
.product-tabs .tabs .tab .specifications-list .specifications-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-bottom: 1px solid #d8d8d8; }
.product-tabs .tabs .tab .specifications-list .specifications-item:last-child {
  border-bottom: 0; }
.product-tabs .tabs .tab .specifications-list .specifications-item span {
  display: block;
  color: #000;
  padding: 0.5rem; }
.product-tabs .tabs .tab .specifications-list .specifications-item span.properties {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 40%;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;
  font: normal 1rem/1.5em "HelveticaNeueCyrMedium", sans-serif; }
.product-tabs .tabs .tab .specifications-list .specifications-item span.value {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 60%;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%;
  font: normal 1rem/1.5em "HelveticaNeueCyrLight", sans-serif; }
.product-tabs .tabs .tab .documents-list {
  padding-top: 0;
  padding-bottom: 0; }
.product-tabs .tabs .tab .documents-list .documents-item {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  margin-bottom: 1.875rem; }
.product-tabs .tabs .tab .documents-list .documents-item .document-link {
  position: relative;
  display: block;
  height: 100%;
  -webkit-box-shadow: 0 0 0 1px #d8d8d8;
  box-shadow: 0 0 0 1px #d8d8d8;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.product-tabs .tabs .tab .documents-list .documents-item .document-image {
  position: relative;
  display: block;
  width: 100%;
  z-index: 1; }
.product-tabs .tabs .tab .documents-list .documents-item .document-name {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  width: 100%;
  padding: 0.75rem 0;
  background: #fff;
  border-bottom: 0;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  z-index: 2; }
.product-tabs .tabs .tab .documents-list .documents-item:hover .document-link {
  -webkit-box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.35);
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.35);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.product-tabs .tabs .tab .documents-list .documents-item:hover .document-link .document-name {
  color: #0070c9;
  border-bottom: 0.375rem solid #6eacdc;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.shopping-cart .cart-title {
  color: #000;
  font: normal 1.875rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  margin-bottom: 1.875rem; }

.shopping-cart .cart-content {
  width: 100%;
  max-width: 100%;
  margin: 0 auto; }
.shopping-cart .cart-content .product-list {
  padding: 1.25rem 0; }
.shopping-cart .cart-content .product-list .product-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid #f2f2f2; }
.shopping-cart .cart-content .product-list .product-item .remove-product {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 2.625rem;
  -ms-flex: 0 0 2.625rem;
  flex: 0 0 2.625rem;
  max-width: 2.625rem; }
.account-content .orders-history .product-item .remove-product .remove,
.admin-view .table .product-item .remove-product .remove,
.admin-form .product-item .remove-product .remove,
.account-content .orders-history .order-item .edit-project,
.account-content .orders-history .order-item .remove-product .remove,
.shopping-cart .cart-content .product-list .product-item .remove-product .remove

{
  display: block;
  background: transparent;
  border: 0;
  padding: 0.25rem;
  width: 1.25rem;
  margin: 0 auto;
  cursor: pointer; }
.account-content .orders-history .product-item .remove-product .remove .remove-icon,
.admin-view .table .product-item .remove-product .remove .remove-icon,
.admin-form .product-item .remove-product .remove .remove-icon,
.account-content .orders-history .order-item .remove-product .remove .remove-icon,
.shopping-cart .cart-content .product-list .product-item .remove-product .remove .remove-icon
{
  display: block;
  fill: #ff0000;
  width: 0.75rem;
  height: 0.75rem;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.account-content .orders-history .product-item .remove-product .remove:hover,
.admin-view .table .product-item .remove-product .remove:hover,
.admin-form .product-item .remove-product .remove:hover,
.shopping-cart .cart-content .product-list .product-item .remove-product .remove:hover {
  color: #167495;
}
.shopping-cart .cart-content .product-list .product-item .product-image {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 5.625rem;
  -ms-flex: 0 0 5.625rem;
  flex: 0 0 5.625rem;
  max-width: 5.625rem;
  margin-right: 0.625rem; }
.shopping-cart .cart-content .product-list .product-item .product-image a {
  display: block;
  border: 1px solid #e9e9e9;
  background-color: #fff; }
.shopping-cart .cart-content .product-list .product-item .product-image a img {
  display: block;
}
.shopping-cart .cart-content .product-list .product-item .product-image  img {
  height: 100px;
}
.shopping-cart .cart-content .product-list .product-item .product-information {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 10rem;
  -ms-flex: 0 0 10rem;
  flex: 0 0 10rem;
  max-width: 10rem;
  margin-right: 0.625rem; }
.shopping-cart .cart-content .product-list .product-item .product-information p {
  /*color: #000;*/
  /*font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;*/
  font:inherit;
  margin-bottom: 0.625rem; }
.shopping-cart .cart-content .product-list .product-item .product-information p a {
  display: block;
  color: inherit;
  font: inherit; }
.shopping-cart .cart-content .product-list .product-item .product-information p b {
  color: inherit;
  font: inherit; }
.shopping-cart .cart-content .product-list .product-item .product-information p b.old-price {
  /*color: #777;*/
  font-size: 1rem;
}
.shopping-cart .cart-content .product-list .product-item .product-information p.product-title {
  /*font-family: "HelveticaNeueCyrMedium", sans-serif; */
  font:inherit;
}
.shopping-cart .cart-content .product-list .product-item .product-information p.product-number .number {
  /*font-family: "HelveticaNeueCyrMedium", sans-serif;*/
  font:inherit;
}
.shopping-cart .cart-content .product-list .product-item .product-price {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 10.125rem;
  -ms-flex: 0 0 10.125rem;
  flex: 0 0 10.125rem;
  max-width: 10.125rem;
  /*color: #000;*/
  /*font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;*/
  font:inherit;
  padding-top: 0;
  margin-right: 0.625rem;
  margin-left: auto;}
.shopping-cart .cart-content .product-list .product-item .product-price .price {
  margin-left: 0.3125rem;
  font:inherit}
.shopping-cart .cart-content .product-list .product-item .kit-fields {
  flex: 0 0 10rem;
  max-width: 100%;
  margin-right: 0.625rem;
}
.shopping-cart .cart-content .product-list .product-item .product-quantity {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 8.125rem;
  -ms-flex: 0 0 8.125rem;
  flex: 0 0 8.125rem;
  max-width: 8.125rem;
  margin-right: 0.625rem; }
.shopping-cart .cart-content .product-list .product-item .product-quantity .quantity-buttons {
  margin: 0 auto; }
.shopping-cart .cart-content .product-list .product-item .product-total-price {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 8.5rem;
  -ms-flex: 0 0 8.5rem;
  flex: 0 0 8.5rem;
  max-width: 8.5rem;
  text-align: right; }
.shopping-cart .cart-content .product-list .product-item .product-total-price .price {
  /*color: #505050;*/
  /*font: normal 1.5rem/1em "HelveticaNeueCyrRoman", sans-serif; */
  font:inherit;
}
.shopping-cart .cart-content .product-list .product-item .product-total-price .price span {
  font: inherit;
  color: #4bbae7;
  text-align: center;
  margin-bottom: 0;
}
.shopping-cart .cart-content .total-price {
  padding: 0 0 1.25rem; }
.shopping-cart .cart-content .total-price p {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  /*color: #000;*/
  /*font: normal 1rem/1.5em "HelveticaNeueCyrRoman", sans-serif;*/
  font:inherit;
  margin-bottom: 0.25rem; }
.shopping-cart .cart-content .total-price p:last-child {
  margin-bottom: 0; }
.shopping-cart .cart-content .total-price p>span {
  display: block;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 8.625rem;
  -ms-flex: 0 0 8.625rem;
  flex: 0 0 8.625rem;
  max-width: 8.625rem;
  margin-left: 5rem;
  text-align: right; }
.shopping-cart .cart-content .total-price p>span b {
  color: inherit;
  font: inherit; }
.shopping-cart .cart-content .total-price p.price-total {
  font-size: 1.5rem; }
.shopping-cart .cart-content .to-checkout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1.25rem 0 0; }
.shopping-cart .cart-content .to-checkout .link ,
.modal-body .link.saveCart
{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 11.5rem;
  height: 2.9375rem;
  color: #fff;
  /*font: normal 0.9375rem/1em "HelveticaNeueCyrRoman", sans-serif;*/
  font:inherit;
  padding: 0;
  background: #6eacdc;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #5f5f5f;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  text-align: center; }
.shopping-cart .cart-content .to-checkout .link:hover {
  -webkit-box-shadow: 0 5px 10px #8c8c8c;
  box-shadow: 0 5px 10px #8c8c8c;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.shopping-cart .checkout-form form .row {
  padding-top: 0;
  padding-bottom: 0; }

.shopping-cart .checkout-form form .field-group {
  position: relative; }
.shopping-cart .checkout-form form .field-group .group-title {
  color: #000;
  font: normal 1rem/1.5em "HelveticaNeueCyrMedium", sans-serif;
  margin-bottom: 1.875rem; }

.shopping-cart .checkout-form form .payment-method {
  margin-bottom: 3.75rem; }
.shopping-cart .checkout-form form .payment-method:last-child {
  margin-bottom: 0; }
.shopping-cart .checkout-form form .payment-method label {
  display: block;
  width: 100%;
  margin: 0; }
.shopping-cart .checkout-form form .payment-method label img {
  display: inline-block;
  margin-left: 1.25rem;
  cursor: pointer;
  opacity: 0.55;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.shopping-cart .checkout-form form .payment-method label input[type=radio]:checked + img {
  opacity: 1;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.shopping-cart .checkout-form form .payment-method .payment-information {
  display: none; }
.shopping-cart .checkout-form form .payment-method.card {
  position: relative;
  display: block;
  background: transparent;
  border: 0;
  -webkit-border-radius: 0;
  border-radius: 0; }
.shopping-cart .checkout-form form .payment-method.card .payment-information {
  display: block; }
.shopping-cart .checkout-form form .payment-method.card .card-fields {
  padding-top: 1.25rem;
  margin-right: -5px;
  margin-left: -5px; }
.shopping-cart .checkout-form form .payment-method.card .card-fields .card-field {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 1.25rem; }
.shopping-cart .checkout-form form .payment-method.card .card-fields .card-field:last-child {
  margin-bottom: 0; }
.shopping-cart .checkout-form form .payment-method.card .card-fields .field-text {
  display: block;
  color: #000;
  font: normal 0.875rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  margin-bottom: 0.25rem; }
.shopping-cart .checkout-form form .payment-method.card .card-fields input {
  position: relative;
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  padding: 0.625rem;
  background: #fff;
  border: 1px solid #d8d8d8;
  width: 100%; }

.shopping-cart .checkout-form form .edit-address {
  position: absolute;
  top: 0;
  right: 15px;
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  padding: 0.625rem 0;
  width: 5rem;
  border: 1px solid #5f5f5f;
  background: #fff;
  opacity: 1;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.shopping-cart .checkout-form form .edit-address:hover {
  color: #fff;
  background: #000;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.shopping-cart .checkout-form form .shipping-address .shipping-field {
  margin-bottom: 0.75rem; }
.shopping-cart .checkout-form form .shipping-address .shipping-field:last-child {
  margin-bottom: 0; }
.shopping-cart .checkout-form form .shipping-address .shipping-field label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 0; }
.shopping-cart .checkout-form form .shipping-address .shipping-field label .text {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 10.8125rem;
  -ms-flex: 0 0 10.8125rem;
  flex: 0 0 10.8125rem;
  max-width: 10.8125rem;
  display: block;
  color: #000;
  font: normal 1rem/1.5em "HelveticaNeueCyrMedium", sans-serif;
  margin-right: 1.25rem; }
.shopping-cart .checkout-form form .shipping-address .shipping-field label input {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20rem;
  -ms-flex: 0 0 20rem;
  flex: 0 0 20rem;
  max-width: 20rem;
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  width: 100%;
  padding: 0.625rem;
  background: #fff;
  border: 1px solid #d8d8d8;
  cursor: initial;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.shopping-cart .checkout-form form .shipping-address.default .shipping-field label input {
  padding: 0 0.625rem;
  border-color: transparent;
  cursor: unset;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.shopping-cart .checkout-form form .total-information {
  margin-top: 1.875rem;
  padding-top: 2.5rem;
  border-top: 1px solid #d8d8d8; }
.shopping-cart .checkout-form form .total-information .total-price {
  margin-bottom: 1.875rem; }
.shopping-cart .checkout-form form .total-information .total-price p,
.shopping-cart .checkout-form form .total-information .total-price div.price
{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  color: #000;
  font: normal 1rem/1.5em "HelveticaNeueCyrRoman", sans-serif;
  margin-bottom: 0.25rem; }
.shopping-cart .checkout-form form .total-information .total-price p:last-child {
  margin-bottom: 0; }
.shopping-cart .checkout-form form .total-information .total-price p>span,
.shopping-cart .checkout-form form .total-information .total-price div select,
.shopping-cart .checkout-form form .total-information .total-price div span
{
  display: block;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20.625rem;
  -ms-flex: 0 0 20.625rem;
  flex: 0 0 20.625rem;
  max-width: 20.625rem;
  margin-left: 5rem; }
.shopping-cart .checkout-form form .total-information .total-price p span.discount-input{
  display:flex;
}

.shopping-cart .checkout-form form .total-information .total-price p span b {
  color: inherit;
  font: inherit; }
.shopping-cart .checkout-form form .total-information .total-price p.price-total {
  font-size: 1.5rem; }
.shopping-cart .checkout-form form .total-information .pay-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 1rem; }
.shopping-cart .checkout-form form .total-information .pay-button .pay,
.shopping-cart .checkout-form  .pay-button .pay,
.modal form .callback-sumbit,
.modal form .callback-cancel
{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 11.5rem;
  height: 2.9375rem;
  color: #fff;
  font: normal 0.9375rem/1em "HelveticaNeueCyrRoman", sans-serif;
  padding: 0;
  background: #6eacdc;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #5f5f5f;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.shopping-cart .checkout-form form .total-information .pay-button .pay:disabled,
.shopping-cart .checkout-form  .pay-button .pay:disabled,
.modal form .callback-sumbit:disabled,
.modal form .callback-cancel{
  opacity: 0.5;
}
.modal form .callback-cancel{
  background-color: transparent;
  color:#000
}
.shopping-cart .checkout-form  .pay-button .pay:hover ,
.shopping-cart .checkout-form form .total-information .pay-button .pay:hover,
.modal form .callback-sumbit:hover,
.modal form .callback-cancel:hover
{
  -webkit-box-shadow: 0 5px 10px #8c8c8c;
  box-shadow: 0 5px 10px #8c8c8c;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.mini-cart .kit-fields {
  /*display: none;*/
}
.kit-fields .select-item {
  position: relative;
  height: auto;
  margin-top: 0.125em; }
.cart-products .product-item .kit-fields .select-item {
  height: auto; }
.kit-fields .select-item .properties {
  color: #444;
  font: normal 0.875rem/1.25em "HelveticaNeueCyrRoman", sans-serif;
  margin-right: 0.5rem; }
.kit-fields .select-item .value {
  color: #000;
  font: normal 0.875rem/1.25em "HelveticaNeueCyrMedium", sans-serif;
  text-transform: uppercase; }
.animate-image {
  position: fixed;
  transform: scale(1);
  opacity: 1;
  z-index: 5000;
  transition: all 1s ease-in-out; }
.product-gallery .animate-image {
  border: 1px solid #d8d8d8;
}
.animate-image.animate {
  top: -25% !important;
  left: 100% !important;
  transform: scale(0.1);
  opacity: 0;
  transition: all 1s ease-in-out; }

.success-checkout {
  text-align: center; }
.success-checkout h1 {
  color: #000;
  font: normal 1.875rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  margin-bottom: 1.125rem; }
.success-checkout p {
  color: #000;
  font: normal 1rem/1.5em "HelveticaNeueCyrRoman", sans-serif;
  margin-bottom: 2.5rem; }
.success-checkout p:last-child {
  margin-bottom: 0; }
.success-checkout p .link {
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  padding: 0.625rem 0;
  width: 11.5rem;
  border: 1px solid #5f5f5f;
  background: #fff;
  margin: 0 auto;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.success-checkout p .link:hover {
  color: #fff;
  background: #000;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.content-404 {
  text-align: center; }
.content-404 h1 {
  color: #666;
  font: normal 12.5rem/1em "HelveticaNeueCyrMedium", sans-serif;
  text-transform: uppercase;
  margin-bottom: 1.875rem; }
.content-404 h3 {
  color: #999;
  font: normal 3.75rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-transform: uppercase;
  margin-bottom: 3.75rem; }
.content-404 p {
  color: #999;
  font: normal 1.5rem/1.5em "HelveticaNeueCyrRoman", sans-serif;
  text-transform: uppercase;
  margin-bottom: 2.5rem; }
.content-404 p:last-child {
  margin-bottom: 0; }
.content-404 p .link {
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  padding: 0.625rem 0;
  width: 11.5rem;
  border: 1px solid #5f5f5f;
  background: #fff;
  margin: 0 auto;
  text-transform: none;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.content-404 p .link:hover {
  color: #fff;
  background: #000;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.account-sidebar{
  position: fixed;
  left: 0;
  height: 100%;
  width: 20rem;
  background: rgba(229, 229, 229, 0.54);
}
.account-sidebar .account-navigation .navigation-item {
  padding: 0.7rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3); }
.account-sidebar .account-navigation .navigation-item:last-child {
  margin-bottom: 0; }
.account-sidebar .account-navigation .navigation-item a {
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  padding: 0.46875rem 0;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.account-sidebar .account-navigation .navigation-item a:hover {
  color: #0275D8;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.account-sidebar .account-navigation .navigation-item.active span ,
.account-sidebar .account-navigation .navigation-item.active a
{
  display: block;
  color: #0275D8;
  font: normal 1rem/1em "HelveticaNeueCyrMedium", sans-serif;
  padding: 0.46875rem 0;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.account-content {
  position: relative; }
.account-content .account-title {
  color: #000;
  font: normal 1.875rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  margin-bottom: 1.875rem; }
.account-content .account-information {
  position: relative;
  padding-bottom: 1.875rem;
  margin-bottom: 1.875rem;}
.account-content .account-information:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0; }
.account-content .account-information .information-title {
  color: #000;
  font: normal 1.25rem/1.5em "HelveticaNeueCyrMedium", sans-serif;
  width: 100%;
  margin-bottom: 1.875rem; }
.account-content .account-information .account-form,
.account-content .account-form .account-information {
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  position: relative;
  z-index: 1; }
.touchevents .account-content .account-information .account-form{
  width: 100%;
}
.account-content .account-information .account-form .field,
.account-content .account-form .account-information .field {
  flex: 0 50%;
  max-width: 50%;
  padding-left: 2rem;
  margin-bottom: 0.75rem; }
.touchevents .account-content .account-information .account-form .field{
  flex: 0 100%;
  max-width: 100%;
}
.account-content .account-information.hide-information .account-form .field,
.account-content .account-form .account-information.hide-information .field{
  display:none;
}
.account-content .account-information .account-form .field:nth-last-child(2),
.account-content .account-form .account-information .field:nth-last-child(2) {
  margin-bottom: 0; }
.account-content .account-information .account-form .field label,
.account-content .account-form .account-information .field label{
  width: 100%;
}
.account-content .account-information .account-form .field label .field-name,
.account-content .account-form .account-information .field label .field-name {
  display: block;
  color: #000;
  font: normal 0.9rem/1.5em "HelveticaNeueCyrLight", sans-serif;
  margin-right: 1.25rem; }
.account-content .account-information .account-form .field label>div,
.account-content .account-form .account-information .field label>div{
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20rem;
  -ms-flex: 0 0 20rem;
  flex: 0 0 20rem;
  max-width: 20rem;
  display: block;
  width: 100%;
  cursor: initial;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;}
.account-content .account-information.mobile .account-form .field label select,
.account-content .account-form .account-information.mobile .field label select{
  -webkit-flex: 0 0 75%;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.account-content .account-information.mobile .account-form .field label>div,
.account-content .account-form .account-information.mobile .field label>div {
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;}
.account-content .account-information .account-form .field label input ,
.account-content .account-information .account-form .field label select,
.account-content .account-form .account-information .field label input ,
.account-content .account-form .account-information .field label select
{
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20rem;
  -ms-flex: 0 0 20rem;
  flex: 0 0 20rem;
  max-width: 20rem;
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  width: 100%;
  padding: 0.625rem;
  background: #fff;
  border: 1px solid #d8d8d8;
  cursor: initial;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.account-content .account-information .account-form .submit,
.account-content .account-form .account-information .submit {
  display: none; }
.account-content .account-information .account-form .submit .submit-buttons,
.account-content .account-form .account-information .submit .submit-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 2.5rem; }

.account-content .account-information .account-form .submit .submit-buttons.login,
.account-content .account-form .account-information .submit .submit-buttons.login {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.account-content .account-information .account-form .submit .submit-buttons button,
.account-content .account-form .account-information .submit .submit-buttons button {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 7.5rem;
  -ms-flex: 0 0 7.5rem;
  flex: 0 0 7.5rem;
  max-width: 7.5rem;
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  background: #fff;
  padding: 0.625rem 0;
  border: 1px solid #5f5f5f;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.account-content .account-information .account-form .submit .submit-buttons button.cancel,
.account-content .account-form .account-information .submit .submit-buttons button.cancel {
  margin-right: 1.25rem; }
.account-content .account-information .account-form .submit .submit-buttons button:hover,
.account-content .account-form .account-information .submit .submit-buttons button:hover {
  color: #fff;
  background: #000;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.account-content .account-information .account-form.default .field label input,
.account-content .account-information .account-form.default .field label select,
.account-content .account-form.default .account-information .field label input,
.account-content .account-form.default .account-information .field label select
{
  padding: 0 0.625rem;
  border-color: transparent;
  cursor: unset;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.account-content .account-information .account-form.default .field label input::-webkit-input-placeholder,
.account-content .account-information .account-form.default .field label input::-moz-placeholder,
.account-content .account-information .account-form.default .field label input::-ms-input-placeholder ,
.account-content .account-information .account-form.default .field label input::placeholder,
.account-content .account-form.default .account-information .field label input::-webkit-input-placeholder,
.account-content .account-form.default .account-information .field label input::-moz-placeholder,
.account-content .account-form.default .account-information .field label input::-ms-input-placeholder ,
.account-content .account-form.default .account-information .field label input::placeholder
{
  color: transparent;
}

.account-content .account-form.default .account-information.default .field label sele-ms-expand {
  display: none;
}
.account-content .account-information .account-form.default .field label select,
.account-content .account-form.default .account-information .field label select{
  -webkit-appearance: none;
  appearance: none;
}
.account-content .account-information .save-as {
  padding-left: 2rem;
  display: block;
  width: 100%; }
.account-content .account-information .save-as label {
  position: relative;
  display: block;
  margin: 0;
  cursor: pointer; }
.account-content .account-information .save-as label input {
  position: absolute;
  opacity: 0;
  visibility: hidden; }
.account-content .account-information .save-as label .save-text {
  position: relative;
  display: block;
  color: #000;
  font: normal 1rem/2.375em "HelveticaNeueCyrRoman", sans-serif;
  padding-left: 2.125rem; }
.account-content .account-information .save-as label .save-text:before {
  content: '';
  position: absolute;
  top: 0.4375rem;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #5f5f5f;
  background-color: #fff; }
.account-content .account-information .save-as label .save-text:after {
  content: '';
  position: absolute;
  top: 0.875rem;
  left: 0.3125rem;
  width: 0.875rem;
  height: 0.625rem;
  background: url("../../images/check-icon.svg") no-repeat center/cover;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.account-content .account-information .save-as label input:checked + .save-text:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.account-content .account-information .edit-information {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  padding: 0.625rem 0;
  width: 5rem;
  border: 1px solid #5f5f5f;
  background: #fff;
  opacity: 1;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  z-index: 2; }
.account-content .account-information .edit-information.edit-email,
.account-content .account-information .edit-information.edit-password{
  width:15rem;
}
.account-content .account-information .edit-information:hover {
  color: #fff;
  background: #000;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.account-content .account-information.hide-information .account-form {
  display: none; }
.account-content .account-information.hide-information .edit-information {
  opacity: 0.3;
  cursor: default; }
.account-content .account-information.hide-information .edit-information:hover {
  color: #000;
  background: #fff;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.account-create .account-title {
  color: #000;
  font: normal 1.875rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  margin-bottom: 1.875rem; }

.account-create .create-form form .field {
  width: 35rem;
  max-width: 90%;
  margin: 0 auto 1.25rem; }
.account-create .create-form form .field:last-child {
  margin-bottom: 0; }
.account-create .create-form form .field label {
  position: relative;
  display: block;
  margin: 0; }
.account-create .create-form form .field label.email-icon:before {
  content: '';
  position: absolute;
  top: -webkit-calc(50% - 0.375rem);
  top: calc(50% - 0.375rem);
  left: -1.625rem;
  width: 1rem;
  height: 0.75rem;
  background: url("../../images/email-icon.svg") no-repeat center/cover; }
.account-create .create-form form .field label.password-icon:before {
  content: '';
  position: absolute;
  top: -webkit-calc(50% - 0.5625rem);
  top: calc(50% - 0.5625rem);
  left: -1.5625rem;
  width: 0.875rem;
  height: 1.125rem;
  background: url("../../images/password-icon.svg") no-repeat center/cover; }
.account-create .create-form form .field label input {
  position: relative;
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  padding: 0.625rem;
  background: #fff;
  border: 1px solid #d8d8d8;
  width: 100%;
  z-index: 1; }
.account-create .create-form form .field label .show-password {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: 0;
  right: 0;
  width: 2.5rem;
  height: 100%;
  cursor: pointer;
  z-index: 2; }
.account-create .create-form form .field label .show-password svg {
  display: block;
  fill: #7c7c7c;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.account-create .create-form form .field label .show-password svg.eye-open {
  width: 1.3125rem;
  height: 0.9375rem; }
.account-create .create-form form .field label .show-password svg.eye-close {
  width: 0;
  height: 0; }
.account-create .create-form form .field label .show-password.show svg {
  display: block;
  fill: #0070c9;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.account-create .create-form form .field label .show-password.show svg.eye-open {
  width: 0;
  height: 0; }
.account-create .create-form form .field label .show-password.show svg.eye-close {
  width: 1.3125rem;
  height: 0.9375rem; }
.account-create .create-form form .field.submit {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-top: 1.25rem; }
.account-create .create-form form .field.submit .cancel-create {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 7.5rem;
  -ms-flex: 0 0 7.5rem;
  flex: 0 0 7.5rem;
  max-width: 7.5rem;
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  background: #fff;
  padding: 0.625rem 0;
  border: 1px solid #5f5f5f;
  margin-right: 1.25rem;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.account-nav .account-modal .login-form form .field.submit.social,
.login-form.not-modal form .field.submit.social{
  justify-content: center;
}

form .field.submit.social>span,
form .field.submit.social>button
{
  font-size: 1.5rem!important;
  width: 100%;
}

form .field.submit.social>span>button
{
  width: 100%;
}
.account-create .create-form form .field.submit.social{
  width:20rem;
}


form .field.submit.social .kep-login-facebook{
  padding: calc(.34435vw + 3.38843px) calc(.34435vw + 2.38843px);
}

.account-create .create-form form .field.submit .cancel-create:hover {
  color: #fff;
  background: #000;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.account-create .create-form form .field.submit input {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 9.375rem;
  -ms-flex: 0 0 9.375rem;
  flex: 0 0 9.375rem;
  max-width: 9.375rem;
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  background: #fff;
  padding: 0.625rem 0;
  border: 1px solid #5f5f5f;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.main .admin-view  .table  form .field.submit input:hover ,
.account-create .create-form form .field.submit input:hover {
  color: #fff;
  background: #000;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.main .admin-view  .table  .order-item ,
.account-content .orders-history .order-item {
  margin-bottom: 1.25rem;
}
.main .admin-view  .table  .order-item:last-child ,
.account-content .orders-history .order-item:last-child {
  margin-bottom: 1.25rem;
}
.main .admin-view  .table  .order-information ,
.account-content .orders-history .order-information {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem;
  border: 1px solid #d8d8d8;
}
.main .admin-view  .table  .order-information li ,
.account-content .orders-history .order-information li {
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
}
.main .admin-view  .table  .order-information li span ,
.account-content .orders-history .order-information li span {
  color: inherit;
  font: inherit;
}
.main .admin-view  .table .order-information li span.completed ,
.account-content .orders-history .order-information li span.completed {
  color: #61a670;
}
.main .admin-view  .table .order-information li span.canceled ,
.account-content .orders-history .order-information li span.canceled {
  color: #db3f3f;
}
.main .admin-view  .table .order-information li.order-number ,
.account-content .orders-history .order-information li.order-number {
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrMedium", sans-serif;
}
.main .admin-view  .table  .order-information li.orders .show-orders ,
.account-content .orders-history .order-information li.orders .show-orders {
  position: relative;
  display: block;
  padding: 0.125rem;
  background: transparent;
  border: 0;
  cursor: pointer;
}
.main .admin-view  .table .order-information li.orders .show-orders svg ,
.account-content .orders-history .order-information li.orders .show-orders svg {
  position: relative;
  display: block;
  width: 0.9375rem;
  height: auto;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.main .admin-view  .table  .active .order-information li.orders .show-orders svg ,
.account-content .orders-history .active .order-information li.orders .show-orders svg {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.main .admin-view  .table  .order-products ,
.account-content .orders-history .order-products {
  /*display: none;*/
  padding: 1.25rem;
  border: 1px solid #d8d8d8;
  border-top: 0;
}
.main .admin-view  .table  .product-item ,
.main .admin-form  .product-item ,
.account-content .orders-history .product-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid #d8d8d8;
}
.account-content .orders-history .product-item {
  justify-content: space-between;
  align-items: center;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  background: #fff;
  padding: 1.25rem;
  position: relative;
}
.main .admin-form .product-item{
  position: relative;
}
.account-content .orders-history .product-item .product-btn-block{
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.main .admin-form .product-item .product-btn-block{
  position: absolute;
  top: 3.5rem;
  right: 0.5rem;
}

.account-content .orders-history .product-item .product-btn-block .btn {
  margin-left: 0.5rem;
  padding: 0.1rem 0.3rem;
}

.main .admin-view  .table  .product-item,
.main .admin-form    .product-item
{
  align-items: flex-start;
}
.main .admin-view  .table  .product-item:last-child ,
.main .admin-form  .product-item:last-child ,
.account-content .orders-history .product-item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}
.main .admin-view  .table  .product-item .product-image ,
.main .admin-form  .form-group  .product-item .product-image ,
.account-content .orders-history .product-item .product-image {
  -ms-flex: 0 0 6.25rem;
  flex: 0 0 6.25rem;
  max-width: 6.25rem;
  margin-right: 1.25rem;
}
.main .admin-view  .table  .product-item .product-image a ,
.main .admin-form  .form-group  .product-item .product-image a ,
.account-content .orders-history .product-item .product-image a {
  display: block;
  border: 1px solid #e9e9e9;
  background-color: #fff;
}
.main .admin-view  .table  .product-item .product-image a img ,
.main .admin-form  .form-group  .product-item .product-image a img ,
.account-content .orders-history .product-item .product-image a img {
  display: block;
}
.main .admin-view  .table  .product-item .product-information ,
.main .admin-form  .form-group  .product-item.product-information {
  -ms-flex: 0 0 19.3125rem;
  flex: 0 0 19.3125rem;
  max-width: 19.3125rem;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  margin-right: 1.25rem;
}
.main .admin-view  .table  .product-item .product-information p ,
.main .admin-form  .form-group  .product-item .product-information p ,
.account-content .orders-history .product-item .product-information p {
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  margin-bottom: 0.625rem;
}
.main .admin-view  .table  .product-item .product-information .product-title,
.main .admin-form  .form-group  .product-item.product-information .product-title,
.account-content .orders-history .product-item .product-information .product-title {
  font-family: "HelveticaNeueCyrMedium", sans-serif;
}
.main .admin-view  .table  .product-item .product-information .product-title a,
.main .admin-form  .form-group  .product-item .product-information .product-title a,
.account-content .orders-history .product-item .product-information .product-title a {
  display: block;
  color: inherit;
  font: inherit;
}
.main .admin-view  .table .product-item .product-information .product-number span,
.main .admin-form  .form-group  .product-item .product-information .product-number span,
.account-content .orders-history .product-item .product-information .product-number span {
  color: inherit;
  font:inherit;
}
.main .admin-view  .table .product-item .product-information .product-number .number,
.main .admin-form  .form-group  .product-item .product-information .product-number .number,
.account-content .orders-history .product-item .product-information .product-number .number {
  font-family: "HelveticaNeueCyrMedium", sans-serif;
}
.main .admin-view  .table .product-item .product-information .product-price,
.main .admin-form  .form-group  .product-item .product-information .product-price,
.account-content .orders-history .product-item .product-information .product-price
{
  padding-top: 0.625rem;
  margin-bottom: 0;
}

.main .admin-view  .table .product-item .product-information .product-price .price,
.main .admin-form  .form-group  .product-item .product-information .product-price .price,
.account-content .orders-history .product-item .product-information .product-price .price {
  margin-left: 0.3125rem;
}
.main .admin-view  .table .product-item .product-quantity,
.main .admin-form  .form-group  .product-item .product-quantity,
.account-content .orders-history .product-item .product-quantity {
  -ms-flex: 1 0 5.125rem;
  flex: 1 0 5.125rem;
  max-width: 100%;
  display: block;
  text-align: right;
  margin-right: 1.75rem;
}
.main .admin-view  .table .product-item .product-information .product-options,
.main .admin-form  .form-group  .product-item .product-information .product-options,
.account-content .orders-history .product-item .product-information .product-options {
  padding: 5px 0;
  font-size: 16px;
}
.main .admin-view  .table .product-item .product-information .product-options .item-option,
.main .admin-form  .form-group  .product-item .product-information .product-options .item-option,
.account-content .orders-history .product-item .product-information .product-options .item-option {
  padding: 3px 0;
}
.main .admin-view  .table .product-item .product-information .product-options .name-group-option,
.main .admin-form  .form-group  .product-item .product-information .product-options .name-group-option,
.account-content .orders-history .product-item .product-information .product-options .name-group-option {
  font-weight: 700;
}
.main .admin-view  .table .product-item .product-information .product-options .name-option,
.main .admin-form  .form-group  .product-item .product-information .product-options .name-option,
.account-content .orders-history .product-item .product-information .product-options .name-option {
  color: #858585;
}
.account-content .orders-history .product-item .product-quantity{
  flex: 0 0 5rem;
  max-width: unset;
}
.main .admin-view  .table .product-item .product-quantity .quantity,
.main .admin-form  .form-group  .product-item .product-quantity .quantity,
.account-content .orders-history .product-item .product-quantity .quantity {
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
}
.main .admin-view  .table .product-item .product-quantity .quantity span,
.main .admin-form  .form-group  .product-item .product-quantity .quantity span,
.account-content .orders-history .product-item .product-quantity .quantity span {
  color: inherit;
  font: inherit;
}
.main .admin-view  .table .product-item .product-total-price .price ,
.main .admin-form  .form-group  .product-item .product-total-price .price ,
.account-content .orders-history .product-item .product-total-price .price {
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
}
.account-content .orders-history .product-item .product-total-price .price{
  font: bold 1rem/1.5em "HelveticaNeueCyrLight", sans-serif;
}
.main .admin-view  .table .product-item .product-total-price .price span,
.main .admin-form  .form-group  .product-item .product-total-price .price span,
.account-content .orders-history .product-item .product-total-price .price span {
  color: inherit;
  font: inherit;
}



.main .admin-view  .table .product-item .kit-information,
.main .admin-form  .form-group  .product-item .kit-information,
.main .admin-form  .form-group  .product-item .product-information,
.account-content .orders-history .product-item .kit-information {
  flex: 0 0 5.3125rem;
  max-width: 5.3125rem;
  margin-right: 1.25rem;
}
.main .admin-view  .table .product-item .kit-information p,
.main .admin-form  .form-group  .product-item .kit-information p,
.account-content .orders-history .product-item .kit-information p {
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
}
.main .admin-view  .table .product-item .kit-information .product-title,
.main .admin-form  .form-group  .product-item .kit-information .product-title,
.main .admin-form  .form-group  .product-item .product-information .product-title,
.account-content .orders-history .product-item .kit-information .product-title {
  font-family: "HelveticaNeueCyrMedium", sans-serif;
}

.main .admin-view  .table .product-item .kit-information .product-title a,
.main .admin-form  .form-group  .product-item .kit-information .product-title a,
.account-content .orders-history .product-item .kit-information .product-title a {
  display: block;
  color: inherit;
  font: inherit;
}
.main .admin-view  .table .product-item .kit-information .product-number span,
.main .admin-form  .form-group  .product-item .kit-information .product-number span,
.account-content .orders-history .product-item .kit-information .product-number span {
  color: inherit;
  font:inherit;
}
.main .admin-view  .table .product-item .kit-information .product-number .number,
.main .admin-form  .form-group  .product-item .kit-information .product-number .number,
.account-content .orders-history .product-item .kit-information .product-number .number {
  font-family: "HelveticaNeueCyrMedium", sans-serif;
}
.main .admin-view  .table .product-item .kit-information .product-price,
.main .admin-form  .form-group  .product-item .kit-information .product-price,
.account-content .orders-history .product-item .kit-information .product-price {
  padding-top: 0.625rem;
  margin-bottom: 0;
}
.account-content .orders-history .product-item .product-price {
  margin-right: 0;
}

.main .admin-view  .table .product-item .product-total-price .currency,
.main .admin-form  .form-group  .product-item .product-total-price .currency,
.main .admin-view  .table .product-item .product-total-price .number,
.main .admin-form  .form-group  .product-item .product-total-price .number,
.account-content .orders-history .product-item .kit-information .currency,
.account-content .orders-history .product-item .kit-information .number,
.account-content .orders-history .product-item .product-total-price .price .currency,
.account-content .orders-history .product-item .product-total-price .price .number
{
  color:#4bbae7;
}

.main .admin-view  .table .kit-fields,
.main .admin-form  .form-group  .kit-fields,
.account-content .orders-history .product-item .kit-fields
{
  -ms-flex:0 0 22.3125rem;
  flex: 0 0 18.3125rem;
  max-width: 18.3125rem;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  margin-right: 1.25rem;
}
.account-content .orders-history .product-item .kit-fields {
  align-self: center;
}
.back-to-top {
  display: none !important;
  position: fixed;
  bottom: 7.5rem;
  right: 0.9375rem;
  display: none;
  padding: 0.625rem 0.9375rem;
  background: transparent;
  border: 0;
  cursor: pointer;
  z-index: 100; }
.back-to-top svg {
  width: 1.25rem;
  height: auto;
  fill: #000;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.back-to-top:hover svg {
  fill: #6eacdc;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }

.footer {
  background-color: #fff;
}
.footer .footer-information .contact-information {
  position: relative;
  padding: 2.5rem 3.125rem;
  z-index: 2; }
.footer .footer-information .contact-information .information-title {
  color: #fff;
  font: normal 2.25rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  margin-bottom: 2.5rem; }
.footer .footer-information .contact-information .information {
  position: relative;
  padding-left: 3.125rem;
  margin-bottom: 1.875rem; }
.footer .footer-information .contact-information .information:last-child {
  margin-bottom: 0; }
.footer .footer-information .contact-information .information .information-title {
  color: #fff;
  font: normal 1rem/1.5em "HelveticaNeueCyrRoman", sans-serif;
  text-transform: uppercase;
  margin-bottom: 0; }
.footer .footer-information .contact-information .information .information-text {
  color: #fff;
  font: normal 1rem/1.5em "HelveticaNeueCyrRoman", sans-serif; }
.footer .footer-information .contact-information .information .information-text a {
  display: block;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.footer .footer-information .contact-information .information .information-text a:hover {
  color: #0070c9;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out; }
.footer .footer-information .contact-information .information.social .information-text {
  display:flex;
}
.footer .footer-information .contact-information .information.social .information-text  a{
  margin-right: 1rem;
}



.footer .footer-information .contact-information .information.address:before {
  content: '';
  position: absolute;
  top: -webkit-calc(50% - 1.25rem);
  top: calc(50% - 1.25rem);
  left: 0;
  width: 1.875rem;
  height: 2.5rem;
  background: url("../../images/map_icon.svg") no-repeat center/cover;
  margin-bottom: 1rem; }
.footer .footer-information .contact-information .information.social:before {
  content: '';
  position: absolute;
  top: -webkit-calc(50% - 1.25rem);
  top: calc(50% - 1.25rem);
  left: 0;
  width: 1.875rem;
  height: 2.5rem;
  background: url("../../images/social_icon.svg") no-repeat center/cover;
  margin-bottom: 1rem; }
.footer .footer-information .contact-information .information.phones:before {
  content: '';
  position: absolute;
  top: -webkit-calc(50% - 1.03125rem);
  top: calc(50% - 1.03125rem);
  left: 0;
  width: 2.0625rem;
  height: 2.0625rem;
  background: url("../../images/phone_icon.svg") no-repeat center/cover;
  margin-bottom: 1rem; }
.footer .footer-information .contact-information .information.email:before {
  content: '';
  position: absolute;
  top: -webkit-calc(50% - 0.71875rem);
  top: calc(50% - 0.71875rem);
  left: 0;
  width: 2rem;
  height: 1.4375rem;
  background: url("../../images/mail_icon.svg") no-repeat center/cover;
  margin-bottom: 1rem; }
.footer .footer-information .contact-information .information.email a {
  color: #fff;
  font: inherit;
  font-family: "HelveticaNeueCyrRoman", sans-serif; }
.footer .footer-information .contact-map {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  min-height: 20rem;
  z-index: 1; }
.footer .footer-bottom {
  background: #e4e5e6; }
.footer .footer-bottom .footer-bottom-information {
  padding: 1.5rem 0; }
.footer .footer-bottom .footer-bottom-information p {
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center; }



.touchevents .menu-item.flex{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
}

.touchevents .header .main-navigation {
  position: inherit; }
.touchevents .header .main-navigation nav .mobile-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.touchevents .header .main-navigation nav .nav.fadeOut {
  visibility: hidden;
}
.touchevents .header .main-navigation nav .nav.fadeIn {
  visibility: visible;
}
.no-touchevents .header .main-navigation nav .nav.fadeOut {
  visibility: visible;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}


.touchevents .header .main-navigation nav .nav .menu-item {
  margin-right: 0;
  margin-bottom: 0.75rem; }
.touchevents .header .main-navigation nav .nav .menu-item:last-child {
  margin-bottom: 0; }
.touchevents .header .main-navigation nav .nav .menu-item .menu-link {
  padding: 0.35rem 0; }
.touchevents .header .main-navigation  .menu-link.single {

}
.touchevents .header .main-navigation>.header-search{
  display: none;
}
.touchevents .header .main-navigation .header-search .search-button:hover {
  background: transparent; }
.touchevents .header .main-navigation .header-search .search-button:hover svg {
  fill: #fff; }
.touchevents .header .main-navigation .header-search .search-button.active {
  background: #fff; }
.touchevents .header .main-navigation .header-search .search-button.active svg {
  fill: #000; }

.touchevents .our-mission .about-video .video-play.play:hover svg {
  opacity: 0; }
.touchevents .room-type .room .room-link:after {
  display: none; }

.touchevents .main-video {
  height: auto; }
.touchevents .main-video img {
  height: auto; }

.touchevents .assembly .assembly-items .assembly-item a {
  border: 0.125rem solid transparent; }
.touchevents body.home main .assembly .assembly-items .assembly-item a img {
  transform: scale(1) translate3d(0, -1.25rem, 0); }
.touchevents body.home main .assembly .assembly-items .assembly-item a .assembly-title {
  transform: translate3d(0, 0 ,0); }

.touchevents .module .banner .banner-title {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0); }

body.home main .container,
body.home main .container-fluid {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0; }

body.home main .row {
  margin-left: 0;
  margin-right: 0;
  padding-top: 0;
  padding-bottom: 0; }

body.home main .col-1, body.home main .col-2, body.home main .col-3, body.home main .col-4, body.home main .col-5, body.home main .col-6, body.home main .col-7, body.home main .col-8, body.home main .col-9, body.home main .col-10, body.home main .col-11, body.home main .col-12, body.home main .col, body.home main .col-auto, body.home main .col-sm-1, body.home main .col-sm-2, body.home main .col-sm-3, body.home main .col-sm-4, body.home main .col-sm-5, body.home main .col-sm-6, body.home main .col-sm-7, body.home main .col-sm-8, body.home main .col-sm-9, body.home main .col-sm-10, body.home main .col-sm-11, body.home main .col-sm-12, body.home main .col-sm, body.home main .col-sm-auto, body.home main .col-md-1, body.home main .col-md-2, body.home main .col-md-3, body.home main .col-md-4, body.home main .col-md-5, body.home main .col-md-6, body.home main .col-md-7, body.home main .col-md-8, body.home main .col-md-9, body.home main .col-md-10, body.home main .col-md-11, body.home main .col-md-12, body.home main .col-md, body.home main .col-md-auto, body.home main .col-lg-1, body.home main .col-lg-2, body.home main .col-lg-3, body.home main .col-lg-4, body.home main .col-lg-5, body.home main .col-lg-6, body.home main .col-lg-7, body.home main .col-lg-8, body.home main .col-lg-9, body.home main .col-lg-10, body.home main .col-lg-11, body.home main .col-lg-12, body.home main .col-lg, body.home main .col-lg-auto, body.home main .col-xl-1, body.home main .col-xl-2, body.home main .col-xl-3, body.home main .col-xl-4, body.home main .col-xl-5, body.home main .col-xl-6, body.home main .col-xl-7, body.home main .col-xl-8, body.home main .col-xl-9, body.home main .col-xl-10, body.home main .col-xl-11, body.home main .col-xl-12, body.home main .col-xl, body.home main .col-xl-auto {
  padding-left: 0;
  padding-right: 0; }

body.home main .cabinets-section .calculator-element {
  margin-bottom: 0; }

body.home main .assembly .assembly-items {
  margin-top: 0; }

body.home main .assembly .assembly-items .assembly-item {
  margin-top: 0; }

body.home main .assembly .assembly-items .assembly-item a {
  overflow: hidden; }

body.home main .assembly .assembly-items .assembly-item a img {
  /*transform: scale(1.12);*/
  margin-bottom: 0;
  border-bottom: 0; }

body.home main .assembly .assembly-items .assembly-item a .assembly-title {
  position: absolute;
  color: #fff;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.25rem;
  background: #000;
  transform: translate3d(0, 100% ,0);
  z-index: 2; }



body.home main .exterior-material-gallery .gallery-list {
  margin: 0; }

body.home main .exterior-material-gallery .gallery-list .gallery-item {

  margin: 0 3px;
}

body.home main .slider .lSSlideOuter .lSPager.lSGallery {
  margin-top: 0 !important; }

body.home main  .slider .lSSlideOuter .lSPager.lSGallery li {
  margin-right: 0 !important;
}

@media (min-width: 1601px) {
  body .container {
    max-width: 1600px; } }

@media (min-width: 1200px) and (max-width: 1600px) {
  body .container {
    max-width: 1200px; }
  .header .main-navigation .mini-cart .mini-cart-content {
    top: -webkit-calc(100% + 0.5rem);
    top: calc(100% + 0.5rem);
  }
  .account-nav .account-modal {
    right: 7.75rem;
    top: -webkit-calc(100% + 0.65rem);
    top: calc(100% + 0.65rem); }
  .our-mission {
    font-size: 0.75rem; }
  .category-list .category-item {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .category-list .category-item:nth-child(5):before, .category-list .category-item:nth-child(6):before {
    display: block; }
  .category-list .category-item:nth-child(6n):after {
    display: block; }
  .category-list .category-item:nth-child(4n):after {
    display: none; }
  .kit-fields .select-item .properties,
  .kit-fields .select-item .value {
    font-size: 0.75rem; } }

@media (max-width: 1199px) {
  html {
    font-size: 14px; }
  section {
    padding: 3.5rem 0; }
  .header .main-navigation .mini-cart .mini-cart-content {
    top: -webkit-calc(100% + 0.25rem);
    top: calc(100% + 0.25rem);
    right: 1.375rem; }
  .account-nav .account-modal {
    top: -webkit-calc(100% + 0.5rem);
    top: calc(100% + 0.5rem);
    right: 4.75rem; }
  .category-list .category-item:nth-child(5):before, .category-list .category-item:nth-child(6):before {
    display: block; }
  .category-list .category-item:nth-child(6n):after {
    display: block; }
  .category-list .category-item:nth-child(4n):after {
    display: none; }
  .cabinet-elements h2 {
    margin: 1rem 0; }
  .cabinet-elements .elements-list .element-item {
    margin-bottom: 0.5rem; }
  .cabinet-elements .elements-list .element-item:last-child {
    margin-bottom: 0; }
  .module.module-viewed-products .module-title h3 {
    font-size: 1.3rem; }
  .shopping-cart .cart-content .product-list .product-item .product-information {
    flex: 1 0 6rem;
    max-width: 100%;
  }
  .shopping-cart .cart-content .product-list .product-item .kit-fields {
    order: 1;
    margin: 0.625rem 0 0 2.5rem; } }

@media (max-width: 990px) {
  section.with-full-title {
    padding: 7.25rem 0 0; }
  section.with-full-title.first-title.with-promo {
    padding-top:15.5rem;
  }
  .container {
    max-width: 98%; }
  .header .main-navigation .header-search .search-form.show {
    width: 27rem; }
  .header .main-navigation .mini-cart .mini-cart-content {
    top: -webkit-calc(100% + 0.5rem);
    top: calc(100% + 0.5rem);
    right: 1.125rem; }
  .header .main-navigation .mini-cart .mini-cart-content:before {
    right: 0.3rem; }
  .account-nav .account-modal {
    top: -webkit-calc(100% + 0.5rem);
    top: calc(100% + 0.5rem);
    right: 2.5rem; }
  .account-nav .account-modal:before {
    right: 2rem; }
  .full-width-title {
    padding: 2.5rem 0; }
  .cabinet-elements h2 {
    font-size: 1.7rem; }
  .cabinet-elements .elements-list .element-item a {
    font-size: 1rem; }
  .kitchen-project .cabinet-list.active,
  .bathroom-project .cabinet-list.active {
    bottom: 1rem; }
  .exterior-material-gallery .gallery-list .gallery-item {
    -webkit-box-flex: -webkit-calc(20% - 1.25rem);
    -webkit-flex: -webkit-calc(20% - 1.25rem);
    -ms-flex: calc(20% - 1.25rem);
    flex: calc(20% - 1.25rem);
    max-width: -webkit-calc(20% - 1.25rem);
    max-width: calc(20% - 1.25rem); }
  .exterior-material-gallery .gallery-list .gallery-item:nth-child(6n) {
    margin-right: 1.25rem; }
  .exterior-material-gallery .gallery-list .gallery-item:nth-child(5n) {
    margin-right: 0; }
  .category-list .category-item .category-name h4 {
    font-size: 0.875rem; }
  .category-list .category-item .category-name h4 a {
    padding: 0.75rem 0.25rem; }
  .category {
    position: relative; }
  .category-content {
    position: relative;
    z-index: 1; }
  .category-content .category-title {
    margin-bottom: 5.7rem; }
  .sidebar {
    position: absolute;
    top: 5.7rem;
    left: 0;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    z-index: 2; }
  .sidebar .category-navigation {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 47.5%;
    -ms-flex: 0 0 47.5%;
    flex: 0 0 47.5%;
    max-width: 47.5%;
    margin-right: 5%; }
  .sidebar .filters {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 47.5%;
    -ms-flex: 0 0 47.5%;
    flex: 0 0 47.5%;
    max-width: 47.5%; }
  .sidebar .filters .filters-title {
    position: relative;
    color: #000;
    font: normal 0.9375rem/1em "HelveticaNeueCyrRoman", sans-serif;
    text-transform: uppercase;
    padding: 1.0625rem 1.25rem;
    border: 1px solid #5f5f5f;
    background: #fff;
    z-index: 2; }
  .sidebar .filters .filters-title:after {
    content: '';
    position: absolute;
    top: -webkit-calc(50% - 0.5rem);
    top: calc(50% - 0.5rem);
    right: 1.25rem;
    width: 1rem;
    height: 1rem;
    background: url("../../images/filter-icon.png") no-repeat center/cover; }
  .sidebar .filters .filters-list {
    position: absolute;
    top: 0;
    left: 15px;
    width: 50%;
    padding: 30px;
    background: #fff;
    -webkit-box-shadow: -100rem 10rem 10rem 105rem rgba(0, 0, 0, 0.35);
    box-shadow: -100rem 10rem 10rem 105rem rgba(0, 0, 0, 0.35);
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
    z-index: 5; }
  .sidebar .filters .filters-list.active {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out; }
  .shopping-cart .checkout-form form .shipping-address .shipping-field label .text {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8rem;
    -ms-flex: 0 0 8rem;
    flex: 0 0 8rem;
    max-width: 8rem; }
  .shopping-cart .checkout-form form .shipping-address .shipping-field label input {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 12rem;
    -ms-flex: 1 0 12rem;
    flex: 1 0 12rem; }
  .contact-map iframe {
    height: 18.57rem; }
  .account-content .orders-history .product-item .product-information {
    flex: 1 0 19.3125rem;
    max-width: 100%;
    align-self: stretch;
    margin-right: 0; }
  .main .admin-view  .table .product-item .product-quantity,
  .account-content .orders-history .product-item .product-quantity,
  .account-content .orders-history .product-item .product-total-price {
    flex: 0 0 50%;
    max-width: 50%;
    margin: 1.25rem 0 0;
  }
  .main .admin-view  .table .product-item .product-quantity,
  .account-content .orders-history .product-item .product-quantity {
    text-align: left;
  }
  .account-content .orders-history .product-item .product-total-price {
    text-align: right;
  }
  .footer .footer-information .contact-information {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .footer .footer-information .contact-information .information-title {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .footer .footer-information .contact-information .information {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 47.5%;
    -ms-flex: 0 0 47.5%;
    flex: 0 0 47.5%;
    max-width: 47.5%; }
  .footer .footer-information .contact-information .information.phones {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; } }

@media (max-width: 767px) {
  section {
    padding: 2.5rem 0; }
  section.with-full-title {
    padding-top: 5.5rem; }
  h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem; }
  .header {
    height: 48px;
    padding: 0.5rem 0; }
  .header .logo {
    position: absolute;
    top: 10px;
    left: 0;
    border:none;
    width: 25%; }
  .header.with-promo-code .logo{
    bottom: 10px;
    top: inherit;
  }


  .header .logo img {
    max-width: 5rem; }
  .header .main-navigation .header-search .search-form.show {
    width: 16rem; }
  .header .main-navigation .mini-cart .mini-cart-content {
    width: 22.85rem; }
  .header .main-navigation .mini-cart .mini-cart-content .mini-cart-header {
    padding: 0.75rem 0; }
  .header .main-navigation .mini-cart .mini-cart-content .mini-cart-header .mini-cart-title {
    font-size: 1.3rem; }
  .header .main-navigation .mini-cart .mini-cart-content .product-list {
    overflow: auto; }
  .header .main-navigation .mini-cart .mini-cart-content .product-list .product-options .item-option {
    line-height: 1;
    padding: 3px 0;
  }
  .header .main-navigation .mini-cart .mini-cart-content .product-list .product-options {
    padding: 5px 0;
    font-size: 13px;
  }
  .header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .remove-product {
    max-width: 12%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 12%;
    -ms-flex: 0 0 12%;
    flex: 0 0 12%; }
  .header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-image {
    max-width: 30%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 30%;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    margin-right: 3%; }
  .header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-information {
    max-width: 55%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 55%;
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
    margin-right: 0; }
  .header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-information p.product-price {
    margin-bottom: 0;
    padding-top: 0; }
  .header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-quantity {
    max-width: 48.5%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 48.5%;
    -ms-flex: 0 0 48.5%;
    flex: 0 0 48.5%;
    margin-right: 3%;
    margin-top: 6%; }
  .header .main-navigation .mini-cart .mini-cart-content .product-list .product-item .product-total-price {
    max-width: 48.5%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 48.5%;
    -ms-flex: 0 0 48.5%;
    flex: 0 0 48.5%;
    margin-top: 6%;
    text-align: center; }
  .header .main-navigation .mini-cart .mini-cart-content  .product-list .product-item .product-price {
    margin-right: 0.75rem; }
  .header .main-navigation .mini-cart .mini-cart-content .to-cart .link {
    padding: 0.77rem 0; }
  .account-nav .account-modal {
    right: 2rem; }

  .full-width-title {
    padding: 1.25rem 0; }
  .text-align-center .title {
    margin: 0 2rem;
  }
  .full-width-title .title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    min-height: 3rem; }
  .cabinet-elements h2 {
    text-align: center; }
  .cabinet-elements .elements-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .cabinet-elements .elements-list .element-item {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 47.5%;
    -ms-flex: 0 0 47.5%;
    flex: 0 0 47.5%;
    max-width: 47.5%;
    text-align: left; }
  .cabinets-section .room-navigation .nav-item a {
    font-size: 1.125rem;
    padding: 0.875rem; }
  .assembly .assembly-items .assembly-item a:after {
    width: 1.8125rem;
    height: 2.125rem; }
  .exterior-material-gallery .gallery-list .gallery-item {
    -webkit-box-flex: -webkit-calc(25% - 1.25rem);
    -webkit-flex: -webkit-calc(25% - 1.25rem);
    -ms-flex: calc(25% - 1.25rem);
    flex: calc(25% - 1.25rem);
    max-width: -webkit-calc(25% - 1.25rem);
    max-width: calc(25% - 1.25rem); }
  .exterior-material-gallery .gallery-list .gallery-item:nth-child(5n) {
    margin-right: 1.25rem; }
  .exterior-material-gallery .gallery-list .gallery-item:nth-child(4n) {
    margin-right: 0; }
  .modal-gallery .carousel-slider .slider .slide img {
    max-height: 50vh; }
  .slider .lSSlideOuter .lSPager.lSGallery {
    margin-top: 1rem !important; }
  .category-list .category-item:nth-child(4):before {
    display: block; }
  .category-list .category-item:nth-child(4n):after {
    display: block; }
  .category-list .category-item:nth-child(3n):after {
    display: none; }
  .options {
    margin-bottom: 1.125rem; }
  .option .option-list .option-item {
    margin-bottom: 0.625rem;
    max-width: 100%;
  }
  .account-content .account-information .save-as {
    position: relative;
    top: auto;
    right: auto;
    margin-bottom: 0.875rem; }
  .shopping-cart .checkout-form form .shipping-address .shipping-field label .text {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 6rem;
    -ms-flex: 0 0 6rem;
    flex: 0 0 6rem;
    max-width: 6rem; }
  .shopping-cart .checkout-form form .shipping-address .shipping-field label input {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 12rem;
    -ms-flex: 1 0 12rem;
    flex: 1 0 12rem; }
  .account-content .orders-history .order-information li.order-total,
  .account-content .orders-history .order-information li.order-date {
    display: none;
  }
  .content-404 h1 {
    font-size: 6.25rem;
    margin-bottom: 1rem; }
  .content-404 h3 {
    font-size: 1.875rem;
    margin-bottom: 1.5rem; }
  .content-404 p {
    font-size: 1rem;
    margin-bottom: 1.5rem; }
  .exterior-material-gallery .gallery-list {
    display: flex!important;
    justify-content: center;
  }
  .product-information .price-quantity{
    flex-direction: row;
    flex-wrap: wrap;
  }
  .product-information .price-quantity .product-price,
  .product-information .price-quantity .stock-status
  {
    height:auto;
  }
  .product-information .price-quantity .stock-status
  {
    flex-direction: row;
  }
  .product-information .price-quantity .stock-status .stock-indicator
  {
    margin: -3px 0 0 5px;
  }
  .product-information .product-information-wrap .price-quantity {
    padding: 0;
    margin-top: 0;
  }

  .slider-home-wrapper {
    height: 247px;
  }
}

@media (max-width: 479px) {

  /*.slider.banner-slider img {*/
  /*  display: block;*/
  /*  height: 100%;*/
  /*  width: auto;*/
  /*  max-width: none;*/
  /*}*/
  section {
    padding: 1.5rem 0; }
  .header .logo img {
    max-width: 4rem;
    height: auto;
  }
  .header .main-navigation .header-search {
    position: inherit; }
  .header .main-navigation .header-search .search-form {
    top: 100%;
    right: 0; }
  .header .main-navigation .header-search .search-form.show {
    width: 100%; }
  .header .main-navigation .mini-cart .mini-cart-content {
    top: -webkit-calc(100% + 0.5rem);
    top: calc(100% + 0.5rem); }
  .account-nav .account-modal {
    top: -webkit-calc(100% + 0.75rem);
    top: calc(100% + 0.75rem);
    right: 1.5rem; }
  .our-mission .about-video {
    float: none;
    margin: 0 0 1.875rem 0;
    width: 100%; }
  .exterior-material-gallery .gallery-list {
    margin: 0; }
  .exterior-material-gallery .gallery-list .gallery-item {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 47.5%;
    -ms-flex: 0 0 47.5%;
    flex: 0 0 47.5%;
    max-width: 47.5%;
    margin-right: 5%;
    margin-top: 5%; }
  .exterior-material-gallery .gallery-list .gallery-item:nth-child(5n) {
    margin-right: 5%; }
  .exterior-material-gallery .gallery-list .gallery-item:nth-child(2n) {
    margin-right: 0; }
  .category-list .category-item {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .styles_overlay__CLSq- {
    padding: 0; }
  .modal-gallery .carousel-slider .slider .slide img {
    max-height: 70vh; }
  .category-list .category-item:nth-child(3):before, .category-list .category-item:nth-child(4):before {
    display: block; }
  .category-list .category-item:nth-child(1):before, .category-list .category-item:nth-child(2):before {
    display: none; }
  .category-list .category-item:nth-child(3n):after {
    display: block; }
  .category-list .category-item:nth-child(4n):after {
    display: block; }
  .category-list .category-item:nth-child(2n):after {
    display: none; }
  .module .banners > div {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .module.module-viewed-products .module-title h3 {
    font-size: 2.25rem; }
  .category-navigation .current-category {
    font-size: 0.875rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .category-navigation .current-category:after {
    right: 0.5rem; }
  .category-navigation .category-list .list-item {
    font-size: 0.875rem;
    padding: 0.875rem 0.5rem; }
  .sidebar .filters .filters-title {
    font-size: 0.875rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .sidebar .filters .filters-title:after {
    right: 0.5rem; }
  .sidebar .filters .filters-list {
    width: 85%; }
  .category-sorting .matching-products .matching-number {
    font-size: 0.875rem; }
  body.product div.breadcrumb .breadcrumb-list .breadcrumb-item:last-child {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-top: 0.5rem; }
  body.product .product-information {
    margin-top: 1.875rem; }
  .gallery .product-gallery .main-images {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 80%;
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%; }
  .product-tabs .tab-navigation .navigation-item {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: 0; }
  .product-tabs .tab-navigation .navigation-item a {
    padding: 0.5rem 0; }
  .product-tabs .tabs .tab .documents-list .documents-item {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .shopping-cart .cart-content .product-list {
    padding: 0; }
  .shopping-cart .cart-content .product-list .product-item .remove-product {
    max-width: 12%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 12%;
    -ms-flex: 0 0 12%;
    flex: 0 0 12%; }
  .shopping-cart .cart-content .product-list .product-item .product-image {
    max-width: 30%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 30%;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    margin-right: 3%;
    margin-bottom: 1.875rem; }
  .shopping-cart .cart-content .product-list .product-item .product-information {
    max-width: 55%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 55%;
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
    margin-right: 0; }
  .shopping-cart .cart-content .product-list .product-item .kit-fields {
    margin: 1.25rem 0 0 0; }
  .shopping-cart .cart-content .product-list .product-item .product-price {
    flex: 0 0 32%;
    max-width: 32%;
    margin-right: 2%;
    margin-bottom: 0;
    padding-top: 0; }
  .shopping-cart .cart-content .product-list .product-item .product-quantity {
    flex: 0 0 32%;
    max-width: 32%;
    margin-right: 2%;
    margin-top: 0; }
  .shopping-cart .cart-content .product-list .product-item .product-total-price {
    flex: 0 0 32%;
    max-width: 32%;
    margin-right: 0;
    text-align: center; }
  .shopping-cart .cart-content .total-price {
    padding: 1.875rem 0; }
  .shopping-cart .cart-content .total-price p {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .shopping-cart .cart-content .to-checkout {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0; }
  .account-sidebar {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 30%;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%; }
  .account-content {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 95%;
    -ms-flex: 0 0 95%;
    flex: 0 0 95%;
    max-width: 95%; }
  .account-content .account-title {
    font-size: 1.5rem; }
  .account-content .account-information .information-title {
    max-width: -webkit-calc(100% - 5.7rem);
    max-width: calc(100% - 5.7rem); }
  .account-content .account-form .account-information .field label .field-name {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem; }
  .account-content .account-form .account-information .field label input,
  .account-content .account-form .account-information .field label textarea {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .account-content .account-form .account-information .field label input {
    max-width: 75%;
  }
  .account-content .account-form .account-information .submit .submit-buttons button {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 5.5rem;
    -ms-flex: 0 0 5.5rem;
    flex: 0 0 5.5rem;
    max-width: 5.5rem; }
  .account-content .orders-history .product-item .product-image {
    margin-right: 0;
    margin-bottom: 1.25rem;
  }
  .shopping-cart .checkout-form form .field-group {
    margin-bottom: 1.875rem;
    padding-bottom: 0;
    border-bottom: 0; }
  .shopping-cart .checkout-form form .field-group:first-child {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; }
  .shopping-cart .checkout-form form .field-group:last-child {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0; }
  .shopping-cart .checkout-form form .field-group .total-information {
    margin-top: 0;
    /*padding-top: 0;*/
    /*border-top: 0;*/
    padding-top: 2.5rem;
    border-top: 1px solid #d8d8d8;}
  .footer .footer-information .contact-information {
    padding-left: 15px;
    padding-right: 15px; }
  .footer .footer-information .contact-information .information {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .footer .footer-information .contact-information .information.phones {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0; }
  .account-content .account-form.default .account-information .edit-information.edit-email {
    top: 0;}
  .account-content .account-form .account-information .edit-information.edit-email {
    top: 28px;
    width: 5rem;}
  .shopping-cart .checkout-form form .total-information .total-price p {
    justify-content: flex-start;}
  .shopping-cart .checkout-form form .total-information .total-price p span {
    margin-left: 3.45rem;
    margin-right: 1.5rem;}
  .shopping-cart .checkout-form form .total-information .total-price p {
    justify-content: flex-end;}
  .account-content .account-form .account-information .submit .submit-buttons {
    padding-top: 0.5rem;}
}

@media (max-width: 320px) {
  .header .main-navigation nav .mobile-button .menu-icon {
    margin-right: 0; }
  .header .main-navigation nav .mobile-button .mobile-text {
    display: none; }
  .header .main-navigation .mini-cart .mini-cart-content {
    width: 20rem; }
  .account-nav .account-modal {
    width: 20rem; }
  .account-nav .account-modal .login-form form .field.submit input,
  .login-form.not-modal form .field.submit input {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 4.75rem;
    -ms-flex: 0 0 4.75rem;
    flex: 0 0 4.75rem;
    max-width: 4.75rem; }
  .account-create .login-form form .field.submit .create-account ,
  .account-nav .account-modal .login-form form .field.submit .create-account,
  .login-form.not-modal form .field.submit .create-account ,
  .login-form.not-modal form .field.submit .create-account
  {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.25rem;
    -ms-flex: 0 0 8.25rem;
    flex: 0 0 8.25rem;
    max-width: 8.25rem; }
  div.breadcrumb .breadcrumb-list .breadcrumb-item .breadcrumb-current {
    line-height: 2em; }
  .category-navigation .current-category {
    font-size: 0.75rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .sidebar .filters .filters-title {
    font-size: 0.75rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .category-sorting .matching-products {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .category-sorting .sorting {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .product-tabs .tabs .tab .specifications-list .specifications-item span.properties, .product-tabs .tabs .tab .specifications-list .specifications-item span.value {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .account-sidebar .account-navigation .navigation-item a {
    font-size: 0.875rem; }
  .account-sidebar .account-navigation .navigation-item.active span {
    font-size: 0.875rem; }
  .account-content .orders-history .order-information,
  .account-content .orders-history .order-products{
    padding: 0.875rem;
  }
  .account-content .orders-history .order-information li.order-number,
  .account-content .orders-history .order-information li.order-status {
    font-size: 0.875rem;
  }
  .main .admin-view  .table .product-item .product-quantity,
  .main .admin-view  .table .product-item .product-total-price,
  .account-content .orders-history .product-item .product-quantity,
  .account-content .orders-history .product-item .product-total-price {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: left;
    margin-top: 0.625rem;
  }
  .footer .footer-bottom .footer-bottom-information p {
    font-size: 0.875rem; } }


i.lsPrev.prevCarousel,i.lsNext.nextCarousel,
.slick-prev,.slick-next
{

  width: 32px;
  display: block;
  top: 50%;
  height: 32px;
  cursor: pointer;
  position: absolute;
  z-index: 99;
  margin-top: -16px;
  opacity: 0.5;
  -webkit-transition: opacity 0.35s linear 0s;
  transition: opacity 0.35s linear 0s;

}

i.lsNext.nextCarousel,
.slick-next
{
  background: url("/images/slider-right.png") !important;
  right: 5px;
}


i.lsPrev.prevCarousel,
.slick-prev
{
  background: url("/images/slider-left.png") !important;
  left: 5px;
}
.slick-prev:before,.slick-next:before{
  content:none;
}
.slick-slider .slick-prev:hover,
.slick-slider .slick-prev:focus,
.slick-slider .slick-next:hover,
.slick-slider .slick-next:focus {
  color:  #000;
  outline: none;
  background-color: transparent;
}
.slick-slider .slick-list {
  margin: 0 2.5rem;
}
.lazy-load .placeholder {
  background: #f8f8f8;
  height: 200px;
  margin: 10px 0;
  padding-top: 80px;
}

.lazy-load.placeholder{
  position: relative;
  height: 100%;
}
.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #333;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

.option-image .spinner{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}
.spinner.loading{
  margin: 0;

}



@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
}

a.text-blue-link{
  color: #007bff;
}

.modal-body .link.saveCart{
  margin-right: 1rem!important;
}
.modal-body .link.saveCart.close-modal{
  margin-right: 0!important;
}
.modal-body .link.saveCart,
.modal-body .link.saveCart.close-modal{
  width: 45%;
  display: inline-block;
}
.page-section{
  margin-top: 2rem;
  margin-bottom: 2rem;

}
.page-seo-block{
  margin-top: 2rem;
}
/*.page-seo-block img{*/
/*  height:auto;*/
/*}*/
.page-seo-block img.first-screen-image{
  height:auto;
}
.page-seo-block a{
  color: #007bff;
}
.product-list-series{
  margin-top: 1rem;
}
.product-list-series .main-item{
  border: 1px solid #a09d9d;
  padding: 5px 0;
  margin: 0 0.15rem;
  width: unset!important;
  display: block!important;
}
.product-list-series .main-item:hover{
  border-color: #0a94e3;
}
.product-list-series .main-item svg{
  display: block;
}
.product-list-series .main-item:hover svg path{
  fill: #0a94e3;
}
.product-list-series .main-item img,
.product-list-series .main-item svg{
  margin: auto;
}

.product-list-series .main-item.main{
}
.product-layout .product-thumb .product-list-series .main-item a{
  padding:0;

}
.currency-select{
  width:initial;
  background: transparent;
  color: #fff;
  border:none;
}
.currency-select option{
  color: #000;
}
.product-page .product-information{
  justify-content: space-between;
}
.product-page .product-information .product-quantity{
  justify-content: space-around;
}
.product-page .product-information .stock-status{
  font-weight: bold;
  font-size: 1.5rem;
}
.no-pointer{
  cursor: default!important;
}
.account-information-wrapper{
  display: flex;
  position: relative;
  padding: 0 0 0 20rem;
}
.account-information-wrapper .account-sidebar .account-name,
.account-information-wrapper .account-sidebar .logout-btn{
  display: inline-block;
  max-width: 65%;
}
.account-information-wrapper .account-sidebar .logout-btn {
  float: right;}
.account-sidebar .account-sidebar-title{
  padding: 2rem 1rem;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
}
.account-content .account-title:not(.checkout){
  position: fixed;
  background: #FFFFFF;
  /*box-shadow: 0 14px 15px rgba(0, 0, 0, 0.03);*/
  width: 100%;
  z-index: 10;
  padding: 2rem 3rem;
}
.account-content .account-title span{
  display: inline-block;
  font-size: 1rem;}
.account-content .account-title p{
  display: flex;
  justify-content: space-between;
  width: calc(100% - 26rem);
}
.account-information-wrapper .account-content{
  width: 100%;
  /*padding: 2rem 3rem;*/
}
.orders-history{
  padding: 3rem;
}
.account-information-wrapper .account-content .account-information{
  padding: 0 3rem;
}
.account-information-wrapper .account-content .account-information.first{
  padding-top: 10rem;
  width: 100%;
}
.account-information-wrapper .account-content .account-information.first > .field:first-child{
  flex: 0 25%;
  max-width: 25%;
}
.account-information-wrapper .account-content .account-information.first .password-block .field label{
  position:relative;
}
.account-content .account-form .account-information.first label .show-password{
  right: 20px;
  top: 10px;
  position: absolute;
}
.account-information-wrapper .account-content .account-information.first .password-block{
  flex: 0 75%;
  max-width: 75%;
  display: flex;
}
.orders-history{
  padding-top: 6rem;
}
.account-information-wrapper .account-content .orders-list-header {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem;
  border: 1px solid #d8d8d8;
  background: #E5E5E5;
  font-weight: bold;
  position: fixed;
  width: calc(100% - 26rem);
  z-index: 10;
}
.account-content .orders-history .order-item.first{
  padding-top: 4rem;
}
.account-content .orders-history .order-item .btn.btn-add{
  background: #28A745;
  color: #fff;
  font-size: 0.8rem;
  float: right;
}
.account-content .orders-history .order-item .btn.btn-danger.btn-delete{
  background: transparent;
}
.account-content .orders-history .order-item .btn.btn-outline-primary:hover,
.account-content .orders-history .order-item .btn.btn-outline-primary:focus{
  background: transparent;
  color: #007bff;
  box-shadow: none;
}
.account-content .orders-history .order-item .btn.btn-outline-primary:not(.product-item-edit){
  font-size: 0.8rem;
}
.account-content .orders-history .order-item .btn-block{
  display: flex;
  justify-content: flex-end;
  max-width: 11rem;
}.account-content .orders-history .order-item .btn-block button{
   margin-left: 10px;
 }

.account-content .orders-history .header-order-number,
.account-content .orders-history .order-information li.order-number{
  flex: 0 6%;
  max-width: 6%;
}
.account-content .orders-history .header-order-date,
.account-content .orders-history .order-information li.order-date {
  flex: 0 15%;
  max-width: 15%;
}
.account-content .orders-history .header-order-name,
.account-content .orders-history .order-information li.order-name {
  flex: 0 20%;
  max-width: 20%;
}
.account-content .orders-history .header-order-total,
.account-content .orders-history .order-information li.order-total {
  flex: 0 10%;
  max-width: 10%;
}
.account-content .orders-history .header-order-add-to-cart,
.account-content .orders-history .order-add-to-cart{
  flex: 0 10%;
  max-width: 10%;
}
.account-content .orders-history .header-btn-block,
.account-content .orders-history .order-information .btn-block{
  flex: 0 15%;
  max-width: 15%;
}
.account-content .orders-history .header-order-status,
.account-content .orders-history .order-information .order-status{
  flex: 0 15%;
  max-width: 15%;
}


.account-content .orders-history .order-information li.order-name span{
  font-size: 0.75rem;
  color: #6C757D;
}
.testmodal-div{
  position: fixed;
  top: 50%;
  z-index: 99999;
}
.orders-modal button:not(.react-responsive-modal-closeButton),
.orders-modal button:not(.react-responsive-modal-closeButton):focus{
  width: 100%;
  margin-top: 0.5rem;
  box-shadow: none;
}
.account-content .orders-history .order-item{
  margin-bottom: 0;
}
/*.account-content .orders-history .product-item .product-image,*/
.account-content .orders-history .product-item .product-information,
.account-content .orders-history .product-item .kit-information{
  flex: 0 0 17rem;
  max-width: 17rem;
  margin-right: unset;
}
.account-content .orders-history .order-information,
.account-content .orders-history .order-products{
  background: #F9F9F9;
}
.account-content .orders-history .kit-fields .select-item .properties{
  color: #000;
  font-weight: bold;
}
.account-content .orders-history .kit-fields .select-item .value{
  color: #000;
  font-weight: normal;
  font-family: 'HelveticaNeueCyrLight', sans-serif;
  text-transform: capitalize;
}
.account-content .orders-history .product-item .product-total-price .price > span,
.account-content .orders-history .product-item .product-quantity .quantity span,
.account-content .orders-history .product-item .product-price .price .number{
  display: block;
  color: #000;
  text-align: left;
  font-weight: normal;
  font-family: "HelveticaNeueCyrLight", sans-serif;
}
.account-content .orders-history .product-item .product-quantity .quantity span{
  display: inline-block;
}
.account-content .orders-history .product-item .product-quantity .quantity,
.account-content .orders-history .product-item .product-price .price,
.account-content .orders-history .product-item .product-price .text{
  font: bold 1rem/1.5em "HelveticaNeueCyrRoman", sans-serif;
  color: #000;
}
.account-content .orders-history .product-item .product-total-price .price > span .currency,
.account-content .orders-history .product-item .product-total-price .price > span .number{
  color: #000;
  font: normal 1rem/1.5em "HelveticaNeueCyrLight", sans-serif;
}
.account-content .orders-history .product-item .product-total-price{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 8rem;
}
.account-content .orders-history .product-item .product-price{
  align-items: flex-start;
  flex: 0 0 7rem;
}
/*.account-content .orders-history .order-item .btn.btn-outline-primary.product-item-edit{*/
/*  margin-left: 1rem;*/
/*}*/
.empty-orders-wrapper{
  position: relative;
}
.empty-orders{
  position: absolute;
  top: calc(50% - 0rem);
  left: calc(50% - 26rem);
  margin: auto;
  width: 26rem;
  height: 15rem;
  transform: translate(50%, 50%);
  text-align: center;
}
.empty-orders-wrapper .title{
  font-size: 2rem;
  font-weight: bold;
  margin-top: 1rem;
}
.empty-orders-wrapper .text{
  margin-top: 1rem;
}
.empty-orders-wrapper .btn.btn-outline-success {
  margin-top: 2rem;
}
.success-checkout-wrapper{
  margin: 0 auto;
}
.link-grey{
  text-decoration: none;
  color: rgba(0, 0, 0, .5);
}
.link-grey:hover{
  text-decoration: underline;
  color: rgba(0, 0, 0, .7);
}
.account-content .account-form .account-information .password-block{
  flex: 0 50%;
  max-width: 50%;
}

.notification-block .notification-container {
  box-sizing: border-box;
  position: fixed;
  z-index: 999999;
  padding: 0 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
  top: 3.5rem;
  right: 2rem;
  width: 250px;
  display: none;
}
.notification-block .notification-container .notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  color: #fff;
  background-color: #ccc;
  cursor: pointer;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 4px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-bottom: 15px;
  box-shadow: none;
}
.notification-block .notification-container .notification.notification-info  {
  background: #E0F1FF;
  color: #0275D8;
}
.notification-block .notification-container .notification.notification-success {
  background: #ECFBEC;
  color: #5CB85C;
}
.notification-block .notification-container .notification:before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  display: block;
  width: 28px;
  height: 28px;
  font-size: 28px;
  text-align: center;
  line-height: 28px;
}
.notification-block .notification-container .notification:before {
  height: 18px;
  width: 18px;
}
.notification-block .notification-container .notification.notification-info:before {
  content: url('/images/icon_circle_check_blue.svg');
}
.notification-block .notification-container .notification.notification-success:before {
  content: url('/images/icon_circle_check_green.svg');
}
.notification-block .notification-container.active {
  display: block;
}
.touchevents .success-checkout-wrapper{
  margin-top:5rem;
}
.touchevents .header .main-navigation nav.mobile{
  margin-right: 0;
  margin-left: 0.5rem;
}
.touchevents .header .main-navigation .account-nav.currency-select{
  margin-right: 0;
}
.touchevents .header .main-navigation .menu-link.order-now-link.single{
  margin-right: 0.3rem;
}
.touchevents .account-nav .account-modal:before{
  right: 5.5rem;
}
.touchevents .account-information-wrapper{
  padding: 0;
  flex-direction: column;
}
.touchevents .account-sidebar{
  max-width: 100%;
  height: unset;
  width: unset;
  background: #fff;
  padding-top: 2rem;
  z-index: 10;
}
.touchevents .account-sidebar .account-sidebar-title{
  background: #fff;
  padding-bottom: 0;
}
.touchevents .account-information-wrapper .account-navigation{
  /*display: none;*/
}
.touchevents .account-information-wrapper .account-navigation{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1rem;
}
.touchevents .account-information-wrapper .account-navigation li.navigation-item{
  flex: 0 0 25%;
  max-width: 25%;
  border: none;
  text-align: center;
}
.touchevents .account-information-wrapper .account-navigation li.navigation-item a{
  display: block;
  background: none;
  border: none;
}
.account-information-wrapper .account-navigation li.navigation-item a img,
.account-information-wrapper .account-navigation li.navigation-item a i,
.account-information-wrapper .account-navigation li.navigation-item a svg{
  display: none;
}
.touchevents .account-information-wrapper .account-navigation li.navigation-item a img,
.touchevents .account-information-wrapper .account-navigation li.navigation-item a i,
.touchevents .account-information-wrapper .account-navigation li.navigation-item a svg{
  display: block;
  width: 1.875rem;
  height: 1.875rem;
  fill: #000;
  margin: 0 auto 0.75rem;
  transition: all 0.45s ease-in-out;
}
.touchevents .account-information-wrapper .account-navigation li.navigation-item.active a{
  color: #69B9E2;
}
.touchevents .account-information-wrapper .account-navigation li.navigation-item.active a svg{
  fill: #69B9E2;
}
.touchevents .account-information-wrapper .account-content{
  max-width: 100%;
  margin-top: 16rem;
}
.touchevents .account-content .account-title:not(.checkout){
  padding: 1rem;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}
.touchevents .account-content .account-title p{
  width: calc(100% - 4rem);
}
.touchevents .account-content .account-title p span{
  font-size: 0.8rem;
  line-height: 1rem;
  margin-top: 1rem;
}
.touchevents .account-content .account-title:not(.checkout) button{
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.touchevents .notification-block .notification-container{
  right: calc(50% - 250px/2);
}
.touchevents .account-information-wrapper .account-content .account-information{
  width: 100%;
  padding: 0 1rem;
}

.touchevents .account-information-wrapper .account-content .account-information.first{
  flex: 0 0 100%;
  max-width: 100%;
  padding-top: 8rem;
}
.touchevents .account-information-wrapper .account-content .account-information.first > .field:first-child{
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 0;
}

.touchevents .account-content .account-form .account-information .field,
.touchevents .account-content .account-form .account-information .field label select{
  max-width: 100%;
  flex: 0 0 100%;
  padding: 0 2rem;
  display: block;
}
.touchevents .account-content .account-form .account-information.hide-information .field,
.touchevents .account-content .account-form .account-information.hide-information .field label select{
  display: none;
}
.touchevents .account-content .account-form .account-information .field label>div{
  max-width: 100%;
  flex: 0 0 100%;
}
.touchevents .account-content .account-form .account-information .field label input{
  max-width: 100%;
}
.touchevents .account-information-wrapper .account-content .account-information.first .password-block{
  max-width: 100%;
  flex: 0 0 100%;
  flex-direction: column;
  height: 100%;
}
.touchevents .account-content .account-form .account-information.first label .show-password{
  top: 12px;
  right: 0;
}
.touchevents .account-content .account-form .account-information .field.hide{
  display: none;
}
.touchevents .account-information-wrapper .account-content .orders-list-header{
  display: none;
}
.touchevents .account-content .orders-history .order-item.first{
  padding-top: 0;
}
.touchevents .orders-history{
  padding: 6rem 0 0;
}
.touchevents .account-content .orders-history .order-information{
  display: none;
}
.touchevents .order-information-mobile{
  display: block;
  padding: 0.5rem 1rem;
  border-top: 1px solid #E9ECEF;
  border-bottom: 1px solid #E9ECEF;
  background: rgba(204, 204, 204, 0.1);
}
.touchevents .order-information-mobile .top{
  display:flex;
}
.touchevents .order-information-mobile .top .info{
  flex: 0 0 60%;
  max-width: 60%;
}
.touchevents .order-information-mobile .top .btn-block{
  flex: 0 0 40%;
  max-width: 40%;
  height: 2rem;
  justify-content: flex-end;
}
.touchevents .order-information-mobile .top .btn-block img {
  max-width:inherit;
}

.touchevents .order-information-mobile .top .info .order-number{
  font-size: 1.1rem;
  font-weight: bold;
}
.touchevents .order-information-mobile .top .info .order-item-count,
.touchevents .order-information-mobile .top .info .order-date {
  font-size: 0.9rem;
}
.touchevents .account-content .orders-history .order-item .btn-block button{
  padding: 0.1rem 0.3rem;
  margin-right: 0.3rem;
}
.touchevents .order-information-mobile .bottom{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.touchevents .account-content .orders-history .order-item .bottom button.proceedToPayment{
  margin-left: 0.5rem;
}
.touchevents .account-content .orders-history .order-item .bottom .order-total{
  font-weight: bold;
  margin-left: 4rem;
}
.touchevents .account-content .orders-history .product-item .product-price .text{
  display: none;
}
.touchevents .account-content .orders-history .product-item .product-quantity,
.touchevents .account-content .orders-history .product-item .product-total-price{
  max-width: 100%;
}
.touchevents .account-content .orders-history .product-item .right{
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
}
.touchevents .account-content .orders-history .product-item .product-total-price{
  margin-top: 0;
}
.hide-link{
  display: none;
}
.touchevents .hide-link{
  display: block;
  text-decoration: underline;
  color: #373A3C;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  margin-top: 1rem;
}
.account-content .orders-history .order-item .order-information-mobile{
  display: none;
}
.touchevents .account-content .orders-history .order-item .order-information-mobile{
  display: block;
}
.account-content .orders-history .order-products.project-items{
  display: flex;
  flex-wrap: wrap;
}
.account-content .orders-history .order-products.project-items .project-item {
  flex: 0 0 24%;
  max-width: 24%;
  margin-right: 0.5rem;
  cursor: pointer;
}
.account-content .orders-history .order-products.project-items .empty{
  text-align: center;
  font-weight: bold;
  width: 100%;
}
.touchevents .account-content .orders-history .order-products.project-items .project-item{
  flex: 0 0 30%;
  max-width: 30%;
}
.client-files-form img{
  max-width: 200px;
}
.stock-indicator{
  height: 20px;
  width: 20px;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 auto 10px;
}
.product-information .price-quantity .product-price,
.product-information .price-quantity .stock-status,
.product-information .price-quantity .product-quantity{
  margin-right: 7%;
}
.product-information .price-quantity .product-price .price-value .product-price{
  margin-right: 0;
}

.dropdown-menu{
  /*padding: 1.15rem 0;*/
  background: rgba(0,0,0,.7);
  border-top: .2rem solid #000;
  animation-duration: .1s;
  z-index: 5;
}
.touchevents .dropdown-menu{
  position: relative;
  width: 100%;
  box-shadow: none!important;
  border: none;
  background: transparent;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  padding: 0 0 0 0.75rem;
}
.touchevents .dropdown-toggle::after{
  display: none;
}
.touchevents .dropdown-menu .dropdown-item:not(:last-child){
  padding: 0;
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
}
.touchevents .dropdown-submenu>.dropdown-menu{
  left:0;
}

.dropdown-submenu {
  position: relative;
}
.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: 0px;
  margin-left: 0px;
}
.dropdown-item:focus, .dropdown-item:hover{
  background-color: initial;
}
.dropdown-menu .dropdown-item{
  position: relative;
  display: block;
  color: #fff;
  font: normal 0.875rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  text-transform: uppercase;
  transition: all 0.45s ease-in-out;
  padding: .25rem 1.5rem;
  white-space: normal;
}
.dropdown-item.menu-link{
  min-width: 300px;
}
.dropdown-menu .dropdown-item.dropdown-toggle:hover:after{
  transform: rotate(
          -90deg
  );
}
@-webkit-keyframes anim-banner {
  from {
    -webkit-transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.01);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@keyframes anim-banner {
  from {
    transform: scale(1);
  }
  75% {
    transform: scale(1.01);
  }
  to {
    transform: scale(1);
  }
}

.article-banner {
  display: inline-block;
  animation: 1s linear infinite anim-banner;
}
.article-banner img {
  transition: .3s;
}
.article-banner:hover img {
  filter: grayscale(.75);
}



@media screen and (max-width: 1440px) {
  .account-sidebar{
    width: 15rem;
  }
  .account-information-wrapper {
    padding-left: 15rem;
  }
  .orders-history {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .account-information-wrapper .account-content .orders-list-header{
    width: calc(100% - 17rem);
  }
  .account-information-wrapper .account-sidebar .logout-btn{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .account-content .orders-history .order-item .btn-block button {
    padding: 0.2rem 0.3rem;
  }
}
@media screen and (max-width: 1440px) {
  .account-content .orders-history .header-btn-block,
  .account-content .orders-history .order-information .btn-block {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .account-content .orders-history .order-item.first{
    padding-top: 5rem;
  }
}

.article-block{
  border: 2px solid #6c757e;
  border-radius: 20px;
  background: #373a3c;
  color: #fefeff;
}
.article-block img{
  max-height: 200px;
}
.article-block .social a{
  margin-right: 1rem;
}
a.fee4bee-link-no-styled{
  text-align: left;
  display: flex!important;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
a.fee4bee-link{
  text-align: left;
  display: flex!important;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #fedf11;
  padding: 0.5rem 1rem;
  color: #fff;
}
a.fee4bee-link span{
  font-weight: bold;
}
a.fee4bee-link:hover{
  background: #fff;
  border-width: 3px;
}
a.fee4bee-link:hover span{
  color: #000;
}


.promo-top{
  justify-content: center;
  background-color: #7b1b5b;
  margin-top: -0.75rem;
  margin-bottom: 0.75rem;
  padding:0.75rem 0;
}
.promo-top>div{
  color: #fff;
  font-size: 1.2rem;
}



#countdown li {
  display: inline-block;
  font-size: 1.0em;
  list-style-type: none;
  padding: 0 1em;
  text-transform: uppercase;
  text-align: center;
}

#countdown li span {
  display: block;
  font-size: 1.5rem;
}

@media all and (max-width: 768px) {

  #countdown li {
    font-size: calc(1.125rem * var(--smaller));
  }

  #countdown li span {
    font-size: calc(3.375rem * var(--smaller));
  }
}
.articles-sidebar{
  position: sticky;
  top:120px;
}