header {
  height: 62px;
}
.calculator {
  min-height: -webkit-calc(100vh - 4rem);
  min-height: calc(100vh - 4rem);
}
.calculator-steps {
  z-index: 2;
}
.calculator-steps .steps {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.calculator-steps .steps .step {
  position: relative;
  bottom: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  color: #000;
  font: normal 1.875rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  padding: 0;
  border: 1px solid transparent;
  -webkit-border-radius: 1.875rem 1.875rem 0 0;
  border-radius: 1.875rem 1.875rem 0 0;
  background-color: transparent;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-steps .steps .step a {
  display: block;
  color: inherit;
  font: inherit;
  padding: 1.25rem
}
.calculator-steps .steps .step.active {
  bottom: -1px;
  border-color: #888888;
  border-bottom-color: transparent;
  background-color: #f5f5f5;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-elements {
  overflow: hidden;
}
.calculator-elements .owl-stage {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.1875rem;
}
.calculator-elements .owl-dots,.calculator-elements .slick-dots{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 0.125rem;
  background-color: #fff;
  -webkit-border-radius: 0.125rem;
  -moz-border-radius: 0.125rem;
  border-radius: 0.125rem;
  overflow: hidden;
  margin-top: 1.875rem;
}
.calculator-elements .owl-dots .owl-dot,.calculator-elements .slick-dots{
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -moz-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  max-width: 100%;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}
.calculator-elements .owl-dots .owl-dot span,.calculator-elements .slick-dots button{
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  width: 0;
  height: 100%;
  background: #1a1a1a;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  -moz-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-elements .owl-dots .owl-dot.active span,.calculator-elements .slick-dots li.slick-active button{
  left: 0;
  right: auto;
  width: 100%;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  -moz-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-elements .owl-dots.prev .owl-dot span,.calculator-elements .slick-dots button{
  left: 0;
  right: auto;
  width: 0;
}
.calculator-elements .owl-dots.prev .owl-dot.active span,.calculator-elements .slick-dots li.slick-active button{
  left: auto;
  right: 0;
  width: 100%;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  -moz-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-elements .calculator-element .hide-room {
  display: none;
}
.calculator-elements .calculator-element svg {
  max-height: calc(100vh - 16.7rem);
  margin: 0 auto;
  display: block;
}
.calculator-elements .assembly.owl-carousel .owl-nav.disabled {
  display: none;
}
.room-type .row {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.room-type .room .room-link {
  position: relative;
  display: block;
  text-align: center;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calc-categories .room-type .room .room-image{
  margin-bottom: 0;
}
.room-type .room .room-link .coming-soon {
  display: block;
  -webkit-transition: opacity 0.45s ease-in-out;
  -moz-transition: opacity 0.45s ease-in-out;
  transition: opacity 0.45s ease-in-out;
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
}
.room-type .room .room-link:hover .coming-soon {
  visibility: visible;
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: bold;
  color: white;
  opacity: 1;
  -webkit-transition: opacity 0.45s ease-in-out;
  -o-transition: opacity 0.45s ease-in-out;
  transition: opacity 0.45s ease-in-out;
  width: 100%;
  height: auto;
}
.room-type .room .room-link:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.room-type .room .room-link:hover:not(.not-clickable) .room-image {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.room-type .room .room-link:hover:after {
  top: -webkit-calc(100% - 0.375rem);
  top: calc(100% - 0.375rem);
  height: 0.375rem;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.room-type .room .room-image {
  display: block;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0.4;
  /*-webkit-filter: grayscale(100%);*/
  /*filter: grayscale(100%);*/
  margin: 0 auto 2.5rem;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.room-type .room .room-name {
  position: relative;
  display: inline-block;
  color: #000;
  font: normal 1.5rem/1.7em "HelveticaNeueCyrRoman", sans-serif;
  text-transform: uppercase;
}
.room-type .room .room-name:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0.25rem;
  background: #1a1a1a;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.room-type .room.choosed .room-image {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.calculator .cabinet-type.tab-content {
  margin: 0;
  background: transparent;
  border: 0;
}
.calculator .cabinet-type.tab-content .room.tab-pane {
  display: block;
  opacity: 1;
}
.calculator .cabinet-type.tab-content .room .row {
  padding-top: 0;
  padding-bottom: 0;
}
.options .option-item {
  margin-top: 1.875rem;
}
.options .option-item:first-child, .options .option-item:nth-child(2), .options .option-item:nth-child(3), .options .option-item:nth-child(4) {
  margin-top: 0;
}
.options .option-item .option {
  display: block;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.exterior-material-gallery.calculator-material.modal-gallery .option-item .option .option-image,
.options .option-item .option .option-image {
  display: block;
  margin: 0 auto 0.5rem;
  height: auto;
  width: auto;
  max-height: 20vh;
  opacity: 0.7;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.options .option-item.option-item-mobile .option .option-image{
  margin-bottom: 0.5rem;
}
.home-galleries.options .option-item .option .option-image {
  opacity: 1;
  max-height: 30vh;
}
.home-galleries .assembly .assembly-items .assembly-item a:before,#tabs .assembly .assembly-items .assembly-item a:before {
  background: transparent;
}
.home-galleries.options{
  margin-bottom: 0;
}
.options .option-item .option:hover{
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  -webkit-transform: scale(1.0);
  transform: scale(1.0);
}
.options .option-item .option.choose{
  -webkit-transform: scale(1.0);
  -ms-transform: scale(1.0);
  transform: scale(1.0);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.options .option-item .option.choose.option-image{
  opacity: 1;
}
.options .option-item .option .room-name{
  display: block;
  color: #000;
  font: normal 1rem/1.1em "HelveticaNeueCyrRoman", sans-serif;
  text-transform: uppercase;
  margin: 0 auto ;
  width: auto;
  text-align: center;
}
.specifications .specification-image img {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 17.5rem;
}
.specifications .specification-image.gallery img {
  max-width: 100%;
}
.cabinets-section .calculator-elements .calculator-element.specifications {
  margin: 0.9375rem 0;
}
.specifications .all-specifications .specification-list .specification-item {
  position: relative;
  background: transparent;
  margin-bottom: 0.75rem;
}
.specifications .all-specifications .specification-list .specification-item:last-child {
  margin-bottom: 0;
}
.specifications .all-specifications .specification-list .specification-item .properties {
  display: block;
  color: #000;
  font: normal 0.875rem/1.1em "HelveticaNeueCyrRoman", sans-serif;
  text-transform: capitalize;
  margin-bottom: 0;
  padding: 0.8125rem 0;
}
.specifications .all-specifications .specification-list .specification-item .properties.bold {
  font-family: "HelveticaNeueCyrMedium", sans-serif;
  font-weight:bold;
}
.specifications .all-specifications .specification-list .specification-item .value {
  position: relative;
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  margin-bottom: 0;
}
.specifications .all-specifications .specification-list .specification-item .value.fraction-icon:before {
  content: '/';
  position: absolute;
  top: 0;
  left: -4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
  font: normal 1.875rem/1em "HelveticaNeueCyrRoman", sans-serif;
  height: 100%;
}
.specifications .all-specifications .specification-list .specification-item .value select,.specifications .all-specifications .specification-list .specification-item .value input {
  position: relative;
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  padding: 0.75rem 0.625rem;
  background: #fff;
  border: 0;
  width: 100%;
  z-index: 1;
}
.specifications .all-specifications .specification-list .specification-item .unit {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 1.5rem;
  -ms-flex: 0 0 1.5rem;
  flex: 0 0 1.5rem;
  max-width: 1.5rem;
  width: 1.5rem;
  display: block;
  color: inherit;
  font: inherit;
  z-index: 2;
}
.specifications .all-specifications .specification-list .specification-item .change-dimension {
  z-index: 5;
}
.specifications .all-specifications .specification-list .specification-item .update {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 2;
  color: #fff;
  font: normal 0.9375rem/1em "HelveticaNeueCyrRoman", sans-serif;
  padding: 0.75rem 0.625rem;
  background: #1a1a1a;
  border: 1px solid #1a1a1a;
  border-radius: 0.9375rem;
  cursor: pointer;
  transition: all 0.45s ease-in-out;
}
.specifications .all-specifications .specification-list .specification-item .update svg {
  display: block;
  fill: #fff;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 1rem;
  -ms-flex: 0 0 1rem;
  flex: 0 0 1rem;
  max-width: 1rem;
  width: 1rem;
  height: auto;
  margin: 0 0 0 10px;
}
.card-body .product-item .kit-fields .select-item .material-value,
.specifications .all-specifications .specification-list .specification-item .material-value {
  position: relative;
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: left;
  padding: 0.75rem 0.525rem;
  background: #fff;
  border: 0;
  width: 100%;
  cursor: pointer;
  z-index: 1;
}
.card-body .product-item .kit-fields .select-item .material-value{

  background: #e6e6e6;
}
.card-body .product-item .kit-fields .select-item .material-value.select,
.specifications .all-specifications .specification-list .specification-item .material-value.select {
  padding-left: 0.5rem;
  padding-right: 1.0rem;
}
.specifications .all-specifications .specification-list .specification-item .material-value.select select.number{
  background: #fff;
  border: 0;
  width: 100%;
  cursor: pointer;
  z-index: 1;
}
.card-body .product-item .kit-fields .select-item .material-value svg,
.specifications .all-specifications .specification-list .specification-item .material-value svg {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1rem;
  height: 0.5rem;
  fill: #000;
}
.calculator-navigation .navigation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.calculator-navigation .navigation .navigation-item a,.calculator-navigation .navigation .navigation-item button {
  margin-left: 1.25rem;
}
.calculator-navigation .navigation .navigation-item:first-child a,.calculator-navigation .navigation .navigation-item:first-child button {
  margin-left: 0;
}
.calculator-navigation .navigation .navigation-item button,.calculator-navigation .navigation .navigation-item a {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 9.125rem;
  height: 2.9375rem;
  color: #fff;
  font: normal 0.9375rem/1em "HelveticaNeueCyrRoman", sans-serif;
  padding: 0;
  background: #1a1a1a;
  border: 1px solid #1a1a1a;
  border-radius: 0.9375rem;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item button.back-step .arrow-left,.calculator-navigation .navigation .navigation-item a.back-step .arrow-left {
  display: block;
  fill: #fff;
  width: 1.375rem;
  height: 0.625rem;
  margin-right: 0.625rem;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item button.select.hide-button ,.calculator-navigation .navigation .navigation-item a.select.hide-button {
  width: 0;
  border-width: 0;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item button.get-price ,.calculator-navigation .navigation .navigation-item a.get-price {
  width: 0;
  border-width: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item a.get-price.active ,.calculator-navigation .navigation .navigation-item button.get-price.active {
  width: 9.125rem;
  border-width: 1px;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item a:hover ,.calculator-navigation .navigation .navigation-item button:hover {
  color: #1a1a1a;
  background: #f5f5f5;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item a:hover.back-step .arrow-left ,.calculator-navigation .navigation .navigation-item button:hover.back-step .arrow-left {
  fill: #1a1a1a;
  margin-right: 1rem;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item.add-to-cart .cart-link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 0;
  height: 2.9375rem;
  color: #fff;
  font: normal 0.9375rem/1em "HelveticaNeueCyrRoman", sans-serif;
  padding: 0;
  background: #6eacdc;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0 solid #5f5f5f;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item.add-to-cart .cart-link.active {
  width: 9.125rem;
  border-width: 1px;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item.add-to-cart .cart-link:hover {
  -webkit-box-shadow: 0 5px 10px #8c8c8c;
  box-shadow: 0 5px 10px #8c8c8c;
  border-color: #5f5f5f;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item.total-price {
  width: 0;
  opacity: 0;
  visibility: hidden;
  max-height: 2.9375rem;
  overflow: hidden;
}
.calculator-navigation .navigation .navigation-item.total-price.active {
  /*width: 15rem;
  */
  width: auto;
  opacity: 1;
  visibility: visible;
  margin-left: 3.4375rem;
}
.calculator-navigation .navigation .navigation-item.total-price span {
  display: inline-block;
  color: #000;
  font: normal 1.5rem/1em "HelveticaNeueCyrMedium", sans-serif;
}
.calculator-navigation .navigation .navigation-item.total-price.price-value,.calculator-navigation .navigation .navigation-item.total-price .currency {
  color: #4bbae7;
}
/*# sourceMappingURL=maps/calculator.css.map */
.transition-wrapper {
  position: relative;
  z-index: 1;
  height: calc(100vh - 400px);
}
.transition-wrapper .transition-item {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
}
.calculator-element {
  box-sizing: border-box;
  height: 100%;
  transition: transform 1s, opacity 1s;
}
.calculator-element.transition-appear {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.calculator-element.transition-appear.transition-appear-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.calculator-element.transition-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.calculator-element.transition-leave.transition-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.calculator-element.transition-appear.transition-item-back {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.calculator-element.transition-appear.transition-appear-active.transition-item-back {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.calculator-element.transition-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.calculator-element.transition-leave.transition-leave-active.transition-item-back {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.specifications .all-specifications .specification-list .specification-item .value input.isInvalid{
  border:1px solid #ff7584;
}
.specifications .all-specifications .specification-list .specification-item .value input.isInvalid::placeholder {
  color: #ff7584;
  opacity: 1;
}
.list-section.category-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 15px;
}
.list-section.category-list .category-item {
  max-width: none !important;
}
.list-section.category-list .category-item .category-image a {
  display: block;
  height: 100%;
}
.category-list .category-item .category-view-btn {
  max-height: none !important;
  flex: 0 0 auto !important;
}
.category-list .cat-info-row {
  display: flex;
  align-items: center;
  border-radius: 8px;
  flex: 0 0 70%;
}
.list-section.category-list .category-item .category-image {
  max-height: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.list-section.category-list .category-item .category-image img {
  max-height: 100%;
  height: auto;
}
.catalog-category .category-name h4,.catalog-category .category-name h3 {
  font-size: 1rem;
}
@media (max-width: 1199px) {
  .calculator-steps .steps .step {
    font-size: 1.5rem;
  }
}
@media (min-width: 981px) and (max-width: 1199px) {
  .specifications .all-specifications .specification-list .specification-item .value select,.specifications .all-specifications .specification-list .specification-item .value input,.specifications .all-specifications .specification-list .specification-item .material-value {
    font-size: 0.875rem;
  }
}
@media (max-width: 980px) {
  .calculator-steps .steps .step {
    font-size: 1.125rem;
  }
  .calculator-steps .steps .step a {
    padding: 1.125rem;
  }
  .specifications .all-specifications .specification-list .specification-item .properties {
    padding: 0.5rem 0;
  }
  .reviews-wrapper .rating-wrapper {
    margin-bottom: 20px !important;
    margin-top: 0 !important;
  }
  .product-list-series .main-item .link-plus {
    width: auto !important;
    height: auto !important;
  }
}
@media (max-width: 479px) {
  .calculator-steps .steps {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .calculator-steps .steps .step {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    font-size: 1rem;
  }
  .calculator-steps .steps .step a {
    padding: 0.5rem;
  }
  .specifications .all-specifications .specification-list .specification-item .value.in {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .specifications .all-specifications .specification-list .specification-item .change-dimension {
    margin: 1.25rem auto 0;
  }
}
.show{
  display:block!important;
}
.btn-secondary.yes,.btn-secondary:not(:disabled):not(.disabled).active.yes{
  background-color: rgba(99,219,89,0.5);
}
.btn-secondary.no,.btn-secondary:not(:disabled):not(.disabled).active.no{
  background-color: rgba(245,113,114,0.5);
}
.btn-secondary:not(:disabled):not(.disabled).active.yes,.btn-secondary:not(:disabled):not(.disabled).active.no{
  box-shadow: 0 0 0 0.2rem rgba(175, 181, 186, 0.5);
}
.calc-validation-error{
  border: 1px solid red!important;
}
.specification-notes p{
  margin-bottom: 0.5rem;
}
.shopping-cart .cart-content .to-checkout .link.clearCart{
  background-color:#ef6b69;
}
.color-red{
  color:red!important;
}
.color-grey{
  color:grey!important;
}
.shopping-cart .checkout-form form .row .checkout-terms{
  max-height: 200px;
  overflow: auto;
  margin-bottom: 1.618em;
  padding: 1.618em;
  border: 1px solid rgba(0,0,0,.2);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
  background: rgba(0,0,0,.05);
  font-size: 1.25rem;
}
.shopping-cart .checkout-form form .row .checkout-terms section {
  padding: 0;
}
.shopping-cart .checkout-form form .row .checkout-terms section .container div:first-child,.shopping-cart .checkout-form form .row .checkout-terms section .container .title{
  display: none;
}
.table-striped table.table,.table-striped table tbody tr:nth-of-type(odd){
  background-color: inherit;
}
.table-striped table.table{
  margin-bottom: 0;
}
.calculator-navigation .navigation .navigation-item a.next-btn{
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  animation: pulse 2s infinite;
}

@media (max-width: 575px) {
  .calculator-navigation .navigation .navigation-item a#navigate-next {
    position: fixed;
    left: 50%;
    right: 0;
    bottom: 30px;
    margin-left: -3.5rem;
  }
  .calculator-navigation .navigation.navigation-price {
    flex-wrap: wrap;
  }
  .calculator-navigation .navigation .navigation-item.total-price.active {
    margin-left: 0;
    margin-top: 1rem;
  }
  .calculator-navigation .navigation .navigation-item a,.calculator-navigation .navigation .navigation-item button,.calculator-navigation .navigation .navigation-item.add-to-cart .cart-link.active {
    width: 7rem;
  }
}
#how-cut2size-works , #video-items, #numbers {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 3.125rem;
  padding-right: 3.125rem;
  /*background: #e4e5e6;
  */
}
#numbers {
  flex-wrap: wrap;
  padding: 0 4rem;
}
#video-items {
  flex-wrap: wrap;
  padding: 0 2.5rem 2.5rem;
}
#how-cut2size-works {
  padding-bottom: 2.5rem;
}
#how-cut2size-works .item, #video-items .item {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 15%;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  padding: 3rem 0 0 6.5rem;
  background-color: transparent;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  text-align: left;
  min-width: 240px;
  margin-top: 0;
}
#video-items .item{
  padding: 0;
}
#how-cut2size-works .item {
  padding-top: 1rem;
}
#video-items .item {
  padding: 0;
  text-align: center;
  max-width: 33%;
}
#video-items .item .item-title {
  text-transform: uppercase;
  padding: 0;
}
#video-items .item .item-text {
  font: normal 0.9rem/1em "HelveticaNeueCyrRoman", sans-serif;
}
#video-items .item .item-link {
  color: #0275D8;
  text-decoration: underline;
}
#video-items .item .item-title,#video-items .item .item-text,#video-items .item .item-link {
  margin-top: 1rem;
}
.touchevents #how-cut2size-works .item:after{
  color: #0275D8;
  font: bold 2.5rem/1em "HelveticaNeueCyrRoman", sans-serif;
  opacity: 0.3;
  top: 14px;
  left: 63px;
}
#how-cut2size-works .item:after {
  font: bold 10rem/1em "HelveticaNeueCyrRoman", sans-serif;
  position: absolute;
  top: -20px;
  left: 0;
  opacity: 0.53;
  color: #D9EDF7;
}
#how-cut2size-works .item p {
  padding-top: 1rem;
}
#how-cut2size-works .item.item-first:after {
  content: '1';
}
#how-cut2size-works .item.item-second:after {
  content: '2';
}
#how-cut2size-works .item.item-third:after {
  content: '3';
}
#how-cut2size-works .item.item-fourth:after {
  content: '4';
}
#how-cut2size-works .item div img {
  width: 45px;
}
#video-items .calc-button, .touchevents .calc-button {
  width: 100%;
  margin: 4rem 0 0;
}
#video-items .calc-button a,
.touchevents .calc-button a,
body.home main .assembly .assembly-items .assembly-item .customize-button a {
  display: block;
  background: #28a745;
  border: 1px solid #28A745;
  box-sizing: border-box;
  border-radius: 4px;
  width: 146px;
  height: 38px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  line-height: 38px;
}
body.home main .assembly .assembly-items .assembly-item .customize-button a {
  background: orange;
  border: 1px solid orange;
  text-transform: uppercase;
  margin: 1rem auto;
  font-weight: bold;
}
#numbers .item {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33%;
  -ms-flex: 0 0 33%;
  flex: 0 0 33%;
  max-width: 33%;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  min-width: 240px;
  border-right: 1px solid #adb5bd;
  padding:3rem 9rem;
}
#numbers .item:last-child {
  border-right: none;
}
#numbers .number {
  font: bold 3rem/1em "HelveticaNeueCyrRoman", sans-serif;
}
#numbers .text {
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  margin-top: 2rem;
}
#client-comments {
  margin: 0;
  padding: 0;
}
#client-comments .item {
  position: relative;
  color: #000;
  font: normal 1.2rem/1em "HelveticaNeueCyrRoman", sans-serif;
  padding: 0 2rem 0;
  background-color: transparent;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  text-align: left;
  min-width: 240px;
  margin: 2.5rem 0;
}
#client-comments .img {
  width: 64px;
  height: 64px;
  /*background: #4e555b;*/
  float: left;
  margin-right: 0.5rem;
}
#client-comments .name, #client-comments .text {
  color: #373A3C;
  padding-left: 5rem;
}
#client-comments .name {
  font: bold 1.1rem/1em "HelveticaNeueCyrRoman", sans-serif;
}
#client-comments .text {
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  line-height: 24px;
}
#faqs, #contact-us {
  display: block;
  color: #000;
  padding: 13rem 5rem 3rem;
}
#faqs {
  padding-left: 0;
  padding-right: 0;
}
#faqs .faq-container {
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 1rem;
  background: #fff;
  margin-bottom: 0.3rem;
}
#faqs .faq-container:last-child {
  margin-bottom: 0;
}
#faqs .faq-question {
  cursor: pointer;
  border: none;
  width: 100%;
  background: transparent;
}
#faqs .faq-answer {
  background: #e4e5e6;
  border-radius: 3px;
  padding: 1rem 2rem;
  margin-top: 0.4rem;
}
#faqs .faq-arrow {
  float: right;
}
#faqs .faq-question .faq-arrow img {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
#faqs .faq-question.active .faq-arrow img {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
#contact-us .form-group, #contact-us .position-relative.form-group {
  margin-bottom: 0;
}
#contact-us .position-relative.form-group {
  text-align: right;
}
#contact-us .col-form-label {
  padding-top: 0.8rem;
  padding-bottom: 0;
}
#contact-us .form-group:first-child .col-form-label {
  padding-top: 0;
}
#tabs .tab-content {
  border: none;
}
#tabs .tab-content .tab-pane {
  padding: 1rem 0 0;
}
#tabs .nav {
  justify-content: center;
}
#tabs .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
}
#tabs .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background: #0275D8;
}
#tabs .nav-pills .nav-link {
  color: #0275D8;
}
.page-product-wrapper {
  min-height: 150vh;
}
/*@media (max-width: 1050px) {*/
/*  #how-cut2size-works {*/
/*    flex-wrap: wrap;*/
/*  }*/
/*  #how-cut2size-works .item, #video-items .item {*/
/*    -webkit-box-flex: unset;*/
/*    -webkit-flex: unset;*/
/*    -ms-flex: unset;*/
/*    flex: unset;*/
/*    max-width: unset;*/
/*  }*/
/*  #how-cut2size-works .item.item-first{*/
/*    margin-top: 0;*/
/*  }*/
/*  #how-cut2size-works .item {*/
/*    padding-left: 4rem;*/
/*  }*/
/*  #how-cut2size-works .item:after {*/
/*    font: bold 6rem/1em "HelveticaNeueCyrRoman", sans-serif;*/
/*    top: 25px;*/
/*  }*/
/*  #video-items {*/
/*    padding-top: 0;*/
/*    justify-content: space-around;*/
/*  }*/
/*  #video-items .item {*/
/*    padding: 1rem 0;*/
/*  }*/
/*  #numbers .item {*/
/*    padding: 0;*/
/*    border-right: none;*/
/*    margin-bottom: 1rem;*/
/*  }*/
/*  #numbers .text {*/
/*    margin-top: 1rem;*/
/*  }*/
/*  #client-comments .item {*/
/*    max-width: 100%;*/
/*    padding: 0 1rem 0;*/
/*    flex: 0 0 90%;*/
/*  }*/
/*  #contact-us .position-relative.form-group {*/
/*    text-align: center;*/
/*  }*/
/*  #faqs, #contact-us {*/
/*    padding-top: 8rem;*/
/*  }*/
/*  #faqs {*/
/*    padding-right: 0!important;*/
/*    padding-left: 0!important;*/
/*  }*/
/*  #faqs .faq-container span{*/
/*    width: 90%;*/
/*    display: inline-block;*/
/*  }*/
/*  #faqs .faq-container span.faq-arrow{*/
/*    display: inline-block;*/
/*    width: unset;*/
/*  }*/
/*  #numbers {*/
/*    padding-top: 2rem;*/
/*    padding-bottom: 2rem;*/
/*  }*/
/*  .with-full-title.first-title {*/
/*    padding-top: 14rem;*/
/*  }*/
/*  .with-full-title.first-title .full-width-title {*/
/*    padding-top: 4rem;*/
/*  }*/
/*  .with-full-title.first-title .full-width-title .subtitle h3 {*/
/*    font: normal 1.3rem/1rem "HelveticaNeueCyrLight", sans-serif;*/
/*    line-height: 24px;*/
/*  }*/
/*}*/
.product-items-wrapper .item img, .category-products .item img, .viewed-products .item img {
  height: auto;
}
.subtitle {
  padding: 1rem 0;
}
.calc-link {
  color: #28a745;
  text-decoration: underline;
}
.calc-link:hover {
  color: #1c7430;
  text-decoration: underline;
}
.gallery-item .material-title {
  padding-left: 0.5rem;
  margin: 1rem 0;
  display: block;
}
.gallery-item .price {
  color: #fff;
  text-align: right;
  padding: 0.5rem 0.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  background: orange;
  border-radius: 4px;
}
.assembly-item p.price {
  text-align: center;
  color:#4bbae7;
  font: bold 1.1rem/1.0em "HelveticaNeueCyrRoman", sans-serif;
}
#client-comments .assembly.owl-carousel .owl-nav.disabled {
  display: none;
}
#contact {
  background: #1a1a1a;
}
.touchevents .header .header-slogan {
  display: none;
}
.header-slogan {
  color: #fff;
  line-height: 36px;
}
.header-slogan .slogan{
  display: inline-block;
  margin: 0 0.5rem;
}
section.background-grey {
  background: #e4e5e6!important;
}
div.background-grey{
  background: #EFF1F2;
}
section.background-white {
  background: #fff!important;
}
section#faqs-contacts {
  margin: 0;
}
.youtube-iframe{
  max-width: 100%;
  max-height: 100%;
}
.footer-bottom .nav{
  align-items: center;
  justify-content: center;
}
.footer-bottom .nav .menu-item{
  margin:0 1rem;
}
.footer-bottom .nav .menu-item .menu-link{
  text-decoration: underline;
}
.price-format{
  color: #4bbae7;
  font: bolder 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
}
[data-tip] {
  position:relative;
}
[data-tip]:before {
  content:'';
  /* hides the tooltip when not hovered */
  display:block;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #1a1a1a;
  position:absolute;
  top:30px;
  left:35px;
  z-index:8;
  font-size:0;
  line-height:0;
  width:0;
  height:0;
}
[data-tip]:after {
  display:block;
  content:attr(data-tip);
  position:absolute;
  top:35px;
  left:0px;
  padding:5px 8px;
  background:#1a1a1a;
  color:#fff;
  z-index:9;
  font-size: 0.75em;
  height:18px;
  line-height:18px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  white-space:nowrap;
  word-wrap:normal;
}
.calculator {
  min-height: -webkit-calc(100vh - 4rem);
  min-height: calc(100vh - 4rem);
}
.calculator-steps {
  z-index: 2;
}
.calculator-steps .steps {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.calculator-steps .steps .step {
  position: relative;
  bottom: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  color: #000;
  font: normal 1.875rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  padding: 0;
  border: 1px solid transparent;
  -webkit-border-radius: 1.875rem 1.875rem 0 0;
  border-radius: 1.875rem 1.875rem 0 0;
  background-color: transparent;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-steps .steps .step a {
  display: block;
  color: inherit;
  font: inherit;
  padding: 1.25rem
}
.calculator-steps .steps .step.active {
  bottom: -1px;
  border-color: #888888;
  border-bottom-color: transparent;
  background-color: #f5f5f5;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-elements {
  overflow: hidden;
}
.calculator-elements .owl-stage {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.1875rem;
}
.calculator-elements .owl-dots,.calculator-elements .slick-dots{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 0.125rem;
  background-color: #fff;
  -webkit-border-radius: 0.125rem;
  -moz-border-radius: 0.125rem;
  border-radius: 0.125rem;
  overflow: hidden;
  margin-top: 1.875rem;
}
.calculator-elements .owl-dots .owl-dot,.calculator-elements .slick-dots li{
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -moz-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  max-width: 100%;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}
.calculator-elements .owl-dots .owl-dot span,.calculator-elements .slick-dots li button{
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  width: 0;
  height: 100%;
  background: #1a1a1a;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  -moz-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-elements .slick-dots li button{
  background: none;
  width: 100%;
}
.calculator-elements .slick-dots li.slick-active button{
  background: #1a1a1a;
}
.calculator-elements .owl-dots .owl-dot.active span,.calculator-elements .slick-dots li.slick-active button{
  left: 0;
  right: auto;
  width: 100%;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  -moz-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-elements .owl-dots.prev .owl-dot span {
  left: 0;
  right: auto;
  width: 0;
}
.calculator-elements .slick-dots{
  display: flex!important;
}
.calculator-elements .slick-dots li{
  margin: 0;
}
.calculator-elements .owl-dots.prev .owl-dot.active span {
  left: auto;
  right: 0;
  width: 100%;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  -moz-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-elements .calculator-element .hide-room {
  display: none;
}
.calculator-elements .calculator-element svg {
  max-height: calc(100vh - 16.7rem);
  margin: 0 auto;
  display: block;
}
.calculator-elements .assembly.owl-carousel .owl-nav.disabled {
  display: none;
}
.room-type .row {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.room-type .room .room-link {
  position: relative;
  display: block;
  text-align: center;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calc-categories .room-type .room .room-image{
  margin-bottom: 0;
}
.room-type .room .room-link .coming-soon {
  display: block;
  -webkit-transition: opacity 0.45s ease-in-out;
  -moz-transition: opacity 0.45s ease-in-out;
  transition: opacity 0.45s ease-in-out;
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
}
.room-type .room .room-link:hover .coming-soon {
  /*display: block;
  */
  visibility: visible;
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: bold;
  color: white;
  opacity: 1;
  -webkit-transition: opacity 0.45s ease-in-out;
  -o-transition: opacity 0.45s ease-in-out;
  transition: opacity 0.45s ease-in-out;
  width: 100%;
  height: auto;
}
.room-type .room .room-link:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.room-type .room .room-link:hover:not(.not-clickable) .room-image {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.room-type .room .room-link:hover:after {
  top: -webkit-calc(100% - 0.375rem);
  top: calc(100% - 0.375rem);
  height: 0.375rem;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.room-type .room .room-image {
  display: block;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0.4;
  /*-webkit-filter: grayscale(100%);*/
  /*filter: grayscale(100%);*/
  margin: 0 auto 2.5rem;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.room-type .room .room-name {
  position: relative;
  display: inline-block;
  color: #000;
  font: normal 1.5rem/1.7em "HelveticaNeueCyrRoman", sans-serif;
  text-transform: uppercase;
}
.room-type .room .room-name:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0.25rem;
  background: #1a1a1a;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.room-type .room.choosed .room-image {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1!important;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.calculator .cabinet-type.tab-content {
  margin: 0;
  background: transparent;
  border: 0;
}
.calculator .cabinet-type.tab-content .room.tab-pane {
  display: block;
  opacity: 1;
}
.calculator .cabinet-type.tab-content .room .row {
  padding-top: 0;
  padding-bottom: 0;
}
.options .option-item {
  margin-top: 1.875rem;
}
.options .option-item:first-child, .options .option-item:nth-child(2), .options .option-item:nth-child(3), .options .option-item:nth-child(4) {
  margin-top: 0;
}
.options .option-item .option {
  display: block;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.options .option-item .option .option-image {
  display: block;
  margin: 0 auto;
  height: auto;
  width: auto;
  max-height: 20vh;
  opacity: 0.8;
  /*-webkit-filter: grayscale(100%);
  */
  /*filter: grayscale(100%);
  */
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.options .option-item .option.choose .option-image {
  opacity: 1!important;
}


.home-galleries.options .option-item .option .option-image {
  opacity: 1;
  max-height: 30vh;
}
.home-galleries .assembly .assembly-items .assembly-item a:before,#tabs .assembly .assembly-items .assembly-item a:before {
  background: transparent;
}
.home-galleries.options{
  margin-bottom: 0;
}
.options .option-item .option:hover{
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  -webkit-transform: scale(1.0);
  transform: scale(1.0);
}
.options .option-item .option.choose{
  -webkit-transform: scale(1.0);
  -ms-transform: scale(1.0);
  transform: scale(1.0);
  /*-webkit-box-shadow: 0 0 0 0.125rem #1a1a1a;
  */
  /*box-shadow: 0 0 0 0.125rem #1a1a1a;
  */
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.options .option-item .option.choose.option-image{
  /*filter: grayscale(0);
  */
  opacity: 1;
}
.options .option-item .option .room-name{
  display: block;
  color: #000;
  font: normal 1rem/1.1em "HelveticaNeueCyrRoman", sans-serif;
  text-transform: uppercase;
  margin: 0 auto ;
  width: auto;
  text-align: center;
}
.specifications .specification-image img {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 17.5rem;
}
.cabinets-section .calculator-elements .calculator-element.specifications {
  margin: 0.9375rem 0;
}
.specifications .all-specifications .specification-list .specification-item {
  position: relative;
  background: transparent;
  margin-bottom: 0.75rem;
}
.specifications .all-specifications .specification-list .specification-item:last-child {
  margin-bottom: 0;
}
.specifications .all-specifications .specification-list .specification-item .properties {
  display: inline-block;
  color: #000;
  font: normal 0.875rem/1.1em "HelveticaNeueCyrRoman", sans-serif;
  text-transform: capitalize;
  margin-bottom: 0;
  padding: 0.8125rem 0;
}
.specifications .all-specifications .specification-list .specification-item .properties.bold {
  font-family: "HelveticaNeueCyrMedium", sans-serif;
  font-weight:bold;
}
.specifications .all-specifications .specification-list .specification-item .value {
  position: relative;
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  margin-bottom: 0;
}
.specifications .all-specifications .specification-list .specification-item .value.fraction-icon:before {
  content: '/';
  position: absolute;
  top: 0;
  left: -4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
  font: normal 1.875rem/1em "HelveticaNeueCyrRoman", sans-serif;
  height: 100%;
}
.specifications .all-specifications .specification-list .specification-item .value select,.specifications .all-specifications .specification-list .specification-item .value input {
  position: relative;
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  padding: 0.75rem 0.625rem;
  background: #fff;
  border: 0;
  width: 100%;
  z-index: 1;
}
.specifications .all-specifications .specification-list .specification-item .unit {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 1.5rem;
  -ms-flex: 0 0 1.5rem;
  flex: 0 0 1.5rem;
  max-width: 1.5rem;
  width: 1.5rem;
  display: block;
  color: inherit;
  font: inherit;
  z-index: 2;
}
.specifications .all-specifications .specification-list .specification-item .change-dimension {
  z-index: 5;
}
.specifications .all-specifications .specification-list .specification-item .update {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 2;
  color: #fff;
  font: normal 0.9375rem/1em "HelveticaNeueCyrRoman", sans-serif;
  padding: 0.75rem 0.625rem;
  background: #1a1a1a;
  border: 1px solid #1a1a1a;
  border-radius: 0.9375rem;
  cursor: pointer;
  transition: all 0.45s ease-in-out;
}
.specifications .all-specifications .specification-list .specification-item .update svg {
  display: block;
  fill: #fff;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 1rem;
  -ms-flex: 0 0 1rem;
  flex: 0 0 1rem;
  max-width: 1rem;
  width: 1rem;
  height: auto;
  margin: 0 0 0 10px;
}
.specifications .all-specifications .specification-list .specification-item .material-value {
  position: relative;
  display: block;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  text-align: left;
  padding: 0.75rem 0.525rem;
  background: #fff;
  border: 0;
  width: 100%;
  cursor: pointer;
  z-index: 1;
}
.specifications .all-specifications .specification-list .specification-item .material-value.select {
  padding-left: 0.5rem;
  padding-right: 1.0rem;
}
.specifications .all-specifications .specification-list .specification-item .material-value.select select.number,
.specifications .all-specifications .specification-list .specification-item .material-value.paddings input,
.specifications .all-specifications .specification-list .specification-item .material-value.paddings select {
  background: #fff!important;
  border: 0!important;
  width: 100%;
  cursor: pointer;
  z-index: 1;
  -webkit-appearance:none;
}
.specifications .all-specifications .specification-list .specification-item .material-value svg {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1rem;
  height: 0.5rem;
  fill: #000;
}
.calculator-navigation .navigation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.calculator-navigation .navigation .navigation-item a,.calculator-navigation .navigation .navigation-item button {
  margin-left: 1.25rem;
}
.calculator-navigation .navigation .navigation-item:first-child a,.calculator-navigation .navigation .navigation-item:first-child button {
  margin-left: 0;
}
.calculator-navigation .navigation .navigation-item button,.calculator-navigation .navigation .navigation-item a {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 9.125rem;
  height: 2.9375rem;
  color: #fff;
  font: normal 0.9375rem/1em "HelveticaNeueCyrRoman", sans-serif;
  padding: 0;
  background: #1a1a1a;
  border: 1px solid #1a1a1a;
  border-radius: 0.9375rem;
  cursor: pointer;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item button.back-step .arrow-left,.calculator-navigation .navigation .navigation-item a.back-step .arrow-left {
  display: block;
  fill: #fff;
  width: 1.375rem;
  height: 0.625rem;
  margin-right: 0.625rem;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item button.select.hide-button ,.calculator-navigation .navigation .navigation-item a.select.hide-button {
  width: 0;
  border-width: 0;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item button.get-price ,.calculator-navigation .navigation .navigation-item a.get-price {
  width: 0;
  border-width: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item a.get-price.active ,.calculator-navigation .navigation .navigation-item button.get-price.active {
  width: 9.125rem;
  border-width: 1px;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item a:hover ,.calculator-navigation .navigation .navigation-item button:hover {
  color: #1a1a1a;
  background: #f5f5f5;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item a:hover.back-step .arrow-left ,.calculator-navigation .navigation .navigation-item button:hover.back-step .arrow-left {
  fill: #1a1a1a;
  margin-right: 1rem;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item.add-to-cart .cart-link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 0;
  height: 2.9375rem;
  color: #fff;
  font: normal 0.9375rem/1em "HelveticaNeueCyrRoman", sans-serif;
  padding: 0;
  background: #6eacdc;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0 solid #5f5f5f;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item.add-to-cart .cart-link.active {
  width: 9.125rem;
  border-width: 1px;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item.add-to-cart .cart-link:hover {
  -webkit-box-shadow: 0 5px 10px #8c8c8c;
  box-shadow: 0 5px 10px #8c8c8c;
  border-color: #5f5f5f;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.calculator-navigation .navigation .navigation-item.total-price {
  width: 0;
  opacity: 0;
  visibility: hidden;
  max-height: 2.9375rem;
  overflow: hidden;
}
.calculator-navigation .navigation .navigation-item.total-price.active {
  /*width: 15rem;
  */
  width: auto;
  opacity: 1;
  visibility: visible;
  margin-left: 3.4375rem;
}
.calculator-navigation .navigation .navigation-item.total-price span {
  display: inline-block;
  color: #000;
  font: normal 1.5rem/1em "HelveticaNeueCyrMedium", sans-serif;
}
.calculator-navigation .navigation .navigation-item.total-price.price-value,.calculator-navigation .navigation .navigation-item.total-price .currency {
  color: #4bbae7;
}
/*# sourceMappingURL=maps/calculator.css.map */
.transition-wrapper {
  position: relative;
  z-index: 1;
  height: calc(100vh - 400px);
}
.transition-wrapper .transition-item {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
}
.calculator-element {
  box-sizing: border-box;
  height: 100%;
  transition: transform 1s, opacity 1s;
}
.calculator-element.transition-appear {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.calculator-element.transition-appear.transition-appear-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.calculator-element.transition-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.calculator-element.transition-leave.transition-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.calculator-element.transition-appear.transition-item-back {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.calculator-element.transition-appear.transition-appear-active.transition-item-back {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.calculator-element.transition-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.calculator-element.transition-leave.transition-leave-active.transition-item-back {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.specifications .all-specifications .specification-list .specification-item .value input.isInvalid{
  border:1px solid #ff7584;
}
.specifications .all-specifications .specification-list .specification-item .value input.isInvalid::placeholder {
  color: #ff7584;
  opacity: 1;
}
@media (max-width: 1199px) {
  .calculator-steps .steps .step {
    font-size: 1.5rem;
  }
}
@media (min-width: 981px) and (max-width: 1199px) {
  .specifications .all-specifications .specification-list .specification-item .value select,.specifications .all-specifications .specification-list .specification-item .value input,.specifications .all-specifications .specification-list .specification-item .material-value {
    font-size: 0.875rem;
  }
}
@media (max-width: 980px) {
  .calculator-steps .steps .step {
    font-size: 1.125rem;
  }
  .calculator-steps .steps .step a {
    padding: 1.125rem;
  }
  .specifications .all-specifications .specification-list .specification-item .properties {
    padding: 0.5rem 0;
  }
}
@media (max-width: 479px) {
  .calculator-steps .steps {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .calculator-steps .steps .step {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    font-size: 1rem;
  }
  .calculator-steps .steps .step a {
    padding: 0.5rem;
  }
  .specifications .all-specifications .specification-list .specification-item .value.in {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .specifications .all-specifications .specification-list .specification-item .change-dimension {
    margin: 1.25rem auto 0;
  }
  .touchevents #how-cut2size-works {
    height: 200px;
    overflow: hidden;
  }
  .btn-products-widget {
    padding: 10px 28px !important;
  }
  .widget-contacts-wrap {
    right: auto !important;
    left: 5vw;
  }
}
.show{
  display:block!important;
}
.btn-secondary.yes,.btn-secondary:not(:disabled):not(.disabled).active.yes{
  background-color: rgba(99,219,89,0.5);
}
.btn-secondary.no,.btn-secondary:not(:disabled):not(.disabled).active.no{
  background-color: rgba(245,113,114,0.5);
}
.btn-secondary:not(:disabled):not(.disabled).active.yes,.btn-secondary:not(:disabled):not(.disabled).active.no{
  box-shadow: 0 0 0 0.2rem rgba(175, 181, 186, 0.5);
}
.calc-validation-error{
  border: 1px solid red!important;
}
.specification-notes p{
  margin-bottom: 0.5rem;
}
.shopping-cart .cart-content .to-checkout .link.clearCart{
  background-color:#ef6b69;
}
.color-red{
  color:red!important;
}
.color-grey{
  color:grey!important;
}
.shopping-cart .checkout-form form .row .checkout-terms{
  max-height: 200px;
  overflow: auto;
  margin-bottom: 1.618em;
  padding: 1.618em;
  border: 1px solid rgba(0,0,0,.2);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
  background: rgba(0,0,0,.05);
  font-size: 1.25rem;
}
.shopping-cart .checkout-form form .row .checkout-terms section {
  padding: 0;
}
.shopping-cart .checkout-form form .row .checkout-terms section .container div:first-child,.shopping-cart .checkout-form form .row .checkout-terms section .container .title{
  display: none;
}
.table-striped table.table,.table-striped table tbody tr:nth-of-type(odd){
  background-color: inherit;
}
.table-striped table.table{
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .calculator-navigation .navigation .navigation-item a#navigate-next {
    position: fixed;
    left: 50%;
    right: 0;
    bottom: 30px;
    margin-left: -3.5rem;
  }
  .calculator-navigation .navigation.navigation-price {
    flex-wrap: wrap;
  }
  .calculator-navigation .navigation .navigation-item.total-price.active {
    margin-left: 0;
    margin-top: 1rem;
  }
  .calculator-navigation .navigation .navigation-item a,.calculator-navigation .navigation .navigation-item button,.calculator-navigation .navigation .navigation-item.add-to-cart .cart-link.active {
    width: 7rem;
  }
}
.touchevents #how-cut2size-works{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: -2rem;
}
.touchevents #how-cut2size-works .item {
  max-width: 100%;
  width: 100%;
  transition: none;
}
.touchevents #video-items .item {
  transition: none;
}

.touchevents #video-items{
  /*padding-left: 0.5rem;*/
  /*padding-right: 0.5rem;*/
}
.touchevents #video-items .item{
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 1rem;
  align-items: center;
}

#video-items .item .video-item-wrapper{
  display: block;
}
.touchevents #video-items .item .video-item-wrapper{
  display: none;
}
#video-items .item .video-item-wrapper-mobile{
  display: none;
}
.touchevents #video-items .item .video-item-wrapper-mobile{
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  align-items: center;
}
.touchevents #video-items .item .image-wrapper{
  flex: 0 0 30%;
  margin-bottom: 1rem;
  margin-right: 1rem;
  position: relative;
  height: 74px;
}
.touchevents #video-items .item .image-wrapper .lazyload-wrapper {
  height: 100%;
}
.touchevents #video-items .item .image-wrapper .lazyload-wrapper img {
  max-height: 100%;
}
.touchevents #video-items .item .image-wrapper>img{
  width: 30px;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 0;
}

.touchevents #video-items .item .item-title{
  text-transform: capitalize;
  margin-top: 0;
  text-align: left;
}
#how-cut2size-works , #video-items, #numbers {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 3.125rem;
  padding-right: 3.125rem;
  /*background: #e4e5e6;
  */
}
#numbers {
  flex-wrap: wrap;
  padding: 0 4rem;
}
#video-items {
  flex-wrap: wrap;
  padding: 0 2.5rem 2.5rem;
}
#how-cut2size-works {
  padding-bottom: 2.5rem;
}
#how-cut2size-works .item, #video-items .item {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 15%;
  color: #000;
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  padding: 3rem 0 0 6.5rem;
  background-color: transparent;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  text-align: left;
  min-width: 240px;
  margin-top: 0;
}
#video-items .item{
  padding: 0;
}
#how-cut2size-works .item {
  padding-top: 1rem;
}
#video-items .item {
  padding: 0;
  text-align: center;
  max-width: 33%;
}
#video-items .item .item-title {
  text-transform: uppercase;
  padding: 0;
}
#video-items .item .item-text {
  font: normal 0.9rem/1em "HelveticaNeueCyrRoman", sans-serif;
}
#video-items .item .item-link {
  color: #0275D8;
  text-decoration: underline;
}
#video-items .item .item-title,#video-items .item .item-text,#video-items .item .item-link {
  margin-top: 1rem;
}
#how-cut2size-works .item:after {
  font: bold 10rem/1em "HelveticaNeueCyrRoman", sans-serif;
  position: absolute;
  top: -20px;
  left: 0;
  opacity: 0.53;
  color: #D9EDF7;
}
#how-cut2size-works .item p {
  padding-top: 1rem;
}
#how-cut2size-works .item.item-first:after {
  content: '1';
}
#how-cut2size-works .item.item-second:after {
  content: '2';
}
#how-cut2size-works .item.item-third:after {
  content: '3';
}
#how-cut2size-works .item.item-fourth:after {
  content: '4';
}
#how-cut2size-works .item div img {
  width: 45px;
}
#video-items .calc-button {
  width: 100%;
  margin: 4rem 0 0;
}
#video-items .calc-button a, body.home main .assembly .assembly-items .assembly-item .customize-button a {
  display: block;
  background: #28a745;
  border: 1px solid #28A745;
  box-sizing: border-box;
  border-radius: 4px;
  width: 146px;
  height: 38px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  line-height: 38px;
}
body.home main .assembly .assembly-items .assembly-item .customize-button a {
  background: orange;
  border: 1px solid orange;
  text-transform: uppercase;
  margin: 1rem auto;
  font-weight: bold;
}
#numbers .item {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33%;
  -ms-flex: 0 0 33%;
  flex: 0 0 33%;
  max-width: 33%;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  min-width: 240px;
  border-right: 1px solid #adb5bd;
  padding:3rem 9rem;
}
#numbers .item:last-child {
  border-right: none;
}
#numbers .number {
  font: bold 3rem/1em "HelveticaNeueCyrRoman", sans-serif;
}
#numbers .text {
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  margin-top: 2rem;
}
#client-comments {
  margin: 0;
  padding: 0;
}
#client-comments .item {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 40%;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  color: #000;
  font: normal 1.2rem/1em "HelveticaNeueCyrRoman", sans-serif;
  padding: 0 2rem 0;
  background-color: transparent;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  text-align: left;
  min-width: 240px;
  margin: 2.5rem 0;
}
#client-comments .img {
  width: 64px;
  height: 64px;
  /*background: #4e555b;*/
  float: left;
  margin-right: 0.5rem;
}
#client-comments .name, #client-comments .text {
  color: #373A3C;
  padding-left: 5rem;
}
#client-comments .name {
  font: bold 1.1rem/1em "HelveticaNeueCyrRoman", sans-serif;
}
#client-comments .text {
  font: normal 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
  line-height: 24px;
}
#faqs, #contact-us {
  display: block;
  color: #000;
  padding: 13rem 5rem 3rem;
}
#faqs .faq-container {
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 1rem;
  background: #fff;
  margin-bottom: 0.3rem;
}
#faqs .faq-container:last-child {
  margin-bottom: 0;
}
#faqs .faq-question {
  cursor: pointer;
}
#faqs .faq-answer {
  background: #e4e5e6;
  border-radius: 3px;
  padding: 1rem 2rem;
  margin-top: 0.4rem;
}
#faqs .faq-arrow {
  float: right;
}
#faqs .faq-question .faq-arrow img {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
#faqs .faq-question.active .faq-arrow img {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
#contact-us .form-group, #contact-us .position-relative.form-group {
  margin-bottom: 0;
}
#contact-us .position-relative.form-group {
  text-align: right;
}
#contact-us .col-form-label {
  padding-top: 0.8rem;
  padding-bottom: 0;
}
#contact-us .form-group:first-child .col-form-label {
  padding-top: 0;
}
#tabs .tab-content {
  border: none;
}
#tabs .tab-content .tab-pane {
  padding: 1rem 0 0;
}
#tabs .nav {
  justify-content: center;
}
#tabs .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
}
#tabs .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background: #0275D8;
}
#tabs .nav-pills .nav-link {
  color: #0275D8;
}
/*@media (max-width: 1050px) {*/
/*  #how-cut2size-works {*/
/*    flex-wrap: wrap;*/
/*  }*/
/*  #how-cut2size-works .item, #video-items .item {*/
/*    -webkit-box-flex: unset;*/
/*    -webkit-flex: unset;*/
/*    -ms-flex: unset;*/
/*    flex: unset;*/
/*    max-width: unset;*/
/*  }*/
/*  #how-cut2size-works .item.item-first{*/
/*    margin-top: 0;*/
/*  }*/
/*  #how-cut2size-works .item {*/
/*    padding-left: 4rem;*/
/*  }*/
/*  #how-cut2size-works .item:after {*/
/*    font: bold 6rem/1em "HelveticaNeueCyrRoman", sans-serif;*/
/*    top: 25px;*/
/*  }*/
/*  #video-items {*/
/*    padding-top: 0;*/
/*    justify-content: space-around;*/
/*  }*/
/*  #video-items .item {*/
/*    padding: 1rem 0;*/
/*  }*/
/*  #numbers .item {*/
/*    padding: 0;*/
/*    border-right: none;*/
/*    margin-bottom: 1rem;*/
/*  }*/
/*  #numbers .text {*/
/*    margin-top: 1rem;*/
/*  }*/
/*  #client-comments .item {*/
/*    max-width: 100%;*/
/*    padding: 0 1rem 0;*/
/*    flex: 0 0 90%;*/
/*  }*/
/*  #contact-us .position-relative.form-group {*/
/*    text-align: center;*/
/*  }*/
/*  #faqs, #contact-us {*/
/*    padding-top: 8rem;*/
/*  }*/
/*  #numbers {*/
/*    padding-top: 2rem;*/
/*    padding-bottom: 2rem;*/
/*  }*/
/*  .with-full-title.first-title {*/
/*    padding-top: 14rem;*/
/*  }*/
/*  .with-full-title.first-title .full-width-title {*/
/*    padding-top: 4rem;*/
/*  }*/
/*  .with-full-title.first-title .full-width-title .subtitle h3 {*/
/*    font: normal 1.3rem/1rem "HelveticaNeueCyrLight", sans-serif;*/
/*    line-height: 24px;*/
/*  }*/
/*}*/
.subtitle {
  padding: 1rem 0;
}
.calc-link {
  color: #28a745;
  text-decoration: underline;
}
.calc-link:hover {
  color: #1c7430;
  text-decoration: underline;
}
.gallery-item .material-title {
  padding-left: 0.5rem;
  margin: 1rem 0;
  display: block;
}
.gallery-item .price {
  color: #fff;
  text-align: right;
  padding: 0.5rem 0.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  background: orange;
  border-radius: 4px;
}
.assembly-item p.price {
  text-align: center;
  color:#4bbae7;
  font: bold 1.1rem/1.0em "HelveticaNeueCyrRoman", sans-serif;
}
#client-comments .assembly.owl-carousel .owl-nav.disabled {
  display: none;
}
#contact {
  background: #1a1a1a;
}
.touchevents .header .header-slogan {
  display: none;
}
.header-slogan {
  color: #fff;
  line-height: 36px;
}
section.background-grey {
  background: #e4e5e6!important;
}
section.background-white {
  background: #fff!important;
}
section#faqs-contacts {
  margin: 0;
}
.youtube-iframe{
  max-width: 100%;
  max-height: 100%;
}
.footer-bottom .nav{
  align-items: center;
  justify-content: center;
}
.footer-bottom .nav .menu-item{
  margin:0 1rem;
}
.footer-bottom .nav .menu-item .menu-link{
  text-decoration: underline;
}
.price-format{
  color: #4bbae7;
  font: bolder 1rem/1em "HelveticaNeueCyrRoman", sans-serif;
}
[data-tip] {
  position:relative;
}
[data-tip]:before {
  content:'';
  /* hides the tooltip when not hovered */
  display:none;
  content:'';
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #1a1a1a;
  position:absolute;
  top:30px;
  left:35px;
  z-index:8;
  font-size:0;
  line-height:0;
  width:0;
  height:0;
}
[data-tip]:after {
  display:none;
  content:attr(data-tip);
  position:absolute;
  top:35px;
  left:0px;
  padding:5px 8px;
  background:#1a1a1a;
  color:#fff;
  z-index:9;
  font-size: 0.75em;
  height:18px;
  line-height:18px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  white-space:nowrap;
  word-wrap:normal;
}
[data-tip]:before,[data-tip]:after {
  display:block;
}
input[type="file"].form-control {
  height: 2.7rem;
}
input[type='file'].form-control::-webkit-input-placeholder {
  font-size: 1rem;
  line-height: 1rem;
  text-transform: uppercase;
  vertical-align: middle;
}
.image-gallery-thumbnails .image-gallery-thumbnail {
  border: 0;
  width: 12rem;
  filter: grayscale(100%);
}
.image-gallery-thumbnails .image-gallery-thumbnail.active,.image-gallery-thumbnails .image-gallery-thumbnail:hover,.image-gallery-thumbnails .image-gallery-thumbnail:focus {
  border: 0;
  filter: grayscale(0%);
}
.slick-dots.slick-thumb li {
  height: auto;
  width: 12rem;
}
.slick-dots.slick-thumb li img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.slick-dots.slick-thumb li.slick-active img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.slick-dots.slick-thumb {
  bottom: -45px;
  z-index: 1111;
}
.banner-slider .slick-slider .slick-list{
  margin: 0;
}
.image-gallery-icon:focus {
  outline: none;
}
[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: button;
}
.image-gallery-left-nav {
  left: 0;
}
.image-gallery-left-nav, .image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.image-gallery-icon {
  color: #fff;
  transition: all .2s ease-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: initial;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  -webkit-filter: drop-shadow(0 2px 2px #1a1a1a);
  filter: drop-shadow(0 2px 2px #1a1a1a);
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 120px;
  width: 60px;
}
element.style {
}
[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: button;
}
.image-gallery-left-nav {
  left: 0;
}
.image-gallery-right-nav {
  right: 0;
}
.main-slider-navigation img{
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.main-slider-navigation .slick-current img,.main-slider-navigation img:hover{
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
@media (max-width: 1199px){
  .main-slider-banner.slick-initialized .slick-slide {
    height: 55vh;
    display: flex!important;
    flex-direction: column;
    justify-content: center;
  }
  .with-full-title .slider.banner-slider{
    /*min-height: 100vh;
    */
  }
}
@media (max-width: 425px) {
  .with-full-title .slider.banner-slider {
    margin-top: 1rem;
  }
}
.calculator-element-mobile .row{
  padding-top:0;
}
.calc-title{
  font: bold 1rem/1.5em "HelveticaNeueCyrLight", sans-serif;
  padding-top: 0.5rem;
  line-height: 24px;
  color: #0275D8;
}
.calculator-element-mobile .room{
  margin: 0.4rem 1rem 0.4rem 0;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 10px;
  display: inline-block;
  width: 100%;
}
.calculator-element-mobile .room.category{
  margin: 0.4rem 1rem;
  padding: 0;
  /*height: 8rem;*/
}
.calculator-element-mobile .room.with-button:not(.not-clickable){
  width: 75%;
}
.calculator-element-mobile .room.category.choosed{
  width: 70%;
}

.calculator-element-mobile .room .room-link{
  text-align: left;
}
.calculator-element-mobile .room .room-link img.room-image{
  -webkit-transform: none;
  transform: none;
  opacity: 1;
  -webkit-filter: none;
  filter: none;
  transition: none;
  margin-top: -0.9rem;
}
.calculator-element-mobile .room.cabinets .room-link img.room-image{
  margin: 0.6rem 0 0 0;
}
.calculator-element-mobile .room.room-type .room-link img.room-image{
  margin: 0;
}
.calculator-element-mobile .room .room-link .room-image-wrapper{
  display: inline-block;
  width: 7.5rem;
  margin: 1rem;
  border-radius: 10px;
  -webkit-transform: none;
  transform: none;
  opacity: 1;
  -webkit-filter: none;
  filter: none;
  transition: none;
  background-color: #F5F5F5;
  min-height: 5rem;
}

.calculator-element-mobile .room.with-button .room-link img.room-image {
  width: 7.5rem;
}
.calculator-element-mobile .room .room-link,.calculator-element-mobile .room .room-link:hover{
  -webkit-transform: none;
  transform: none;
  transition: none;
  display: flex;
  align-items: center;
}
.calculator-element-mobile .room .room-link .room-name,
.elements-list-mobile div.element-item a {
  font: bold 1rem/1.5em "HelveticaNeueCyrLight", sans-serif;
  line-height: 16px;
  color: #0275D8;
  text-transform: capitalize;
  letter-spacing: 1px;
}
.calculator-element-mobile .room .room-link .room-name{
  width: 13rem;
}
.calculator-element-mobile .room.category .room-link .room-name{
  width: 48%;
  display: inline-block;
}
.calculator-element-mobile .room.with-button .room-link:not(.not-clickable) .room-name{
  width: 7rem;
}
.calculator-element-mobile .room .room-link.not-clickable .room-image {
  opacity: 0.4;
  filter: grayscale(100);
}
.calculator-element-mobile .room .room-link.not-clickable .coming-soon {
  display: block;
  visibility: visible;
  position: absolute;
  top: 50%;
  left: 60%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 1rem;
  font-weight: bold;
  color: white;
  opacity: 1;
  transition: opacity 0.45s ease-in-out;
  width: 100%;
  height: auto;
}

.next-btn{
  display: none;
}

.touchevents .next-btn{
  background-color: #28A745;
  color: #fff;
  width: 20%;
  font: bold 1rem/1.5em "HelveticaNeueCyrLight", sans-serif;
  line-height: 6.5rem;
  text-align: center;
  border-radius: 5px;
  display: inline-block;
}

.touchevents .next-btn.calc-category.mobile{
  width: 18%;
  margin: 0.4rem 0;
  line-height: 7rem;
}

.room-type-margins{
  margin-top: 1.875rem;
}
.calculator-element-mobile .row.room-type-paddings{
  padding-top: 1.875rem;
}
.elements-list-mobile div.element-item{
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 10px;
  margin: 0.4rem 0.4rem 0.4rem 0;
}
.options-list-mobile {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.option-item-mobile-wrapper{
  flex: 0 0 30%;
  margin-bottom: 0.5rem;
  margin-right: 0.7rem;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
}
.options-list-mobile .option-item-mobile{
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 10px;
  height: 100%;
}
.options-list-mobile .option-item-mobile p.option span.room-name{
  color: #373A3C;
  font: normal 0.85rem/1em "HelveticaNeueCyrLight", sans-serif;
  line-height: 14px;
}
.options-list-mobile .option-item-mobile:nth-child(4){
  margin-top: 1.875rem;
}
.calculator-steps-mobile .steps{
  justify-content: flex-start;
}
.calculator-steps-mobile .steps .step{
  flex: 0 0 14%;
  border: 2px solid #373A3C;
  color: #373A3C;
  border-radius: 5px;
  padding-right: 2px;
  padding-left: 2px;
  margin-left: 2rem;
}
.calculator-steps-mobile .steps .step:first-child{
  margin-left: 0;
}

.calculator-steps-mobile .steps .step:before{
  content: '';
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  width: 60%;
  height: 3px;
  background: black;
  border-width: 0 10px;
  position: absolute;
  top: 47%;
  left: 123%;
}

.calculator-steps-mobile .steps .step:last-child:before{
  display: none;
}

.calculator-steps-mobile .steps .step.active{
  bottom: 0;
  border: 2px solid #0275D8;
  color: #0275D8;
  background-color: white;
}

.calculator-steps-mobile .steps .step.past{
  border-color: #5CB85C;
  color: #5CB85C;
}

.faq-btn{
  margin-left: 5rem;
  line-height: 27px;
  border: 2px solid #0275D8;
  border-radius: 4px;
  width: 45px;
  color: #0275D8;
  text-align: center;
}

.row.dimension-row-mobile{
  border: 1px solid #CCC;
  padding: 0.875rem 0;
  margin: 0;
  border-radius: 10px;
  background: #CCC;
}
.row.dimension-row-mobile span.properties{
  line-height: 25px!important;
  text-transform: capitalize!important;
}

.row.dimension-row-mobile .col-4{
  padding-right: 0;
}

.specifications .all-specifications .specification-list .specification-item .value.in.paddings{
  padding-left: 10px;
  padding-right: 10px;
}
.specifications .all-specifications .specification-list .specification-item .value.in.paddings input,
.specifications .all-specifications .specification-list .specification-item .material-value.paddings,
.specifications .all-specifications .specification-list .specification-item .material-value.paddings select,
.specifications .all-specifications .specification-list .specification-item .material-value.paddings input,
.specifications .all-specifications .specification-list .specification-item .value.paddings select,
.specifications .all-specifications .specification-list .specification-item .value.paddings input,
.specifications .all-specifications .specification-list .specification-item .row.dimension-row-mobile .value.mm input {
  border: 1px solid #979797;
  border-radius: 5px;
  color: #818A91;
}
.specifications .all-specifications .specification-list .specification-item .material-value.paddings select{
  -webkit-appearance:none;
}

.specification-item.mobile span{
  font:normal 0.9rem/1.5em "HelveticaNeueCyrLight", sans-serif;
  line-height: 40px;
}
.specification-item.mobile .switch-container,
.specification-item.mobile .switch-container .btn-group-toggle{
  width: 100%;
}
.specification-item.mobile .switch-container .btn-group-toggle .btn.btn-secondary{
  width: 50%;
}

.calc-description .calc-description-title{
  font: bold 1.3rem/1em "HelveticaNeueCyrLight", sans-serif;
  text-transform: uppercase;
  text-align: center;
}
.calc-description .calc-description-text{
  font: bold 0.9rem/1em "HelveticaNeueCyrLight", sans-serif;
  line-height: 20px;
  margin-bottom: 1rem;
}
.row-margin-bottom{
  margin-bottom: 0.5rem;
}
.calculator-elements .calculator-element .important-info svg, .important-info a{
  display: inline-block;
}
.calculator-elements .calculator-element .important-info svg{
  margin: 0;
}
.important-info a{
  font: normal 0.8rem/1em "HelveticaNeueCyrLight", sans-serif;
  color: #0275D8;
  text-decoration: underline;
  margin-left: 0.3rem;
  line-height: 27px;
}
.btn-clear{
  text-align: right;
}
.btn-clear button.clear-field,.product-options-print, .leave-review-btn{
  border: 1px solid #0275D8;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0275D8;
  padding: 7px 15px;
  font: normal 0.8rem/1em "HelveticaNeueCyrLight", sans-serif;
  cursor: pointer;
}
.product-options-print{
  padding: 1px 6px;
  font: normal 0.8rem/1.5em "HelveticaNeueCyrLight", sans-serif;
}
button.get-price.mobile,
button.cart-mobile,
.navigation-item.add-to-cart .cart-link.active.mobile{
  position: absolute;
  top: 25%;
  left: 5%;
  width: 13rem;
  z-index: 3;
  filter: drop-shadow(-8px 4px 20px rgba(0, 0, 0, 0.25));
  background: #28A745;
  color: #ECEEEF;
  border: 0;
  border-radius: 4px;
  padding: 15px 40px;
  font-weight: bold;
  font-family: HelveticaNeueCyrLight, sans-serif;
}
.navigation-item.add-to-cart .cart-link.active.cart-mobile{
  top: 37%;
}
.navigation.navigation-price.mobile{
  text-align: center;
  margin-top: 1rem;
}
.navigation .navigation-item.total-price.active{
  text-align: left;
}

.elements-list-mobile .element-item.active {
  width: 78%;
  display: inline-block;
}
.touchevents .elements-list-mobile .sidebar-item-wrapper .next-btn{
  line-height: 4.5rem;
}
.option-item.option-item-mobile .choose{
  -webkit-transform: scale(0.7)!important;
  transform: scale(0.7)!important;
  transition: none;
}
.option-item.option-item-mobile.choosed .choose{
  margin-top: -1.3rem;
}
.touchevents .options-list-mobile .option-item-mobile-wrapper .next-btn{
  width: 100%;
  line-height: 2rem;
  margin-top: 0.3rem;
}
.account-nav .account-modal .login-form{
  overflow: auto;
}
.account-nav .account-modal .login-form form.mobile .field,
.login-form.not-modal form.mobile .field{
  padding-left: 0;
}
.login-form.not-modal form.mobile .field label .show-password, .account-content .account-information .account-form .show-password,
.login-form.not-modal form.mobile .field label .show-password, .account-content .account-information .account-form .show-password{
  top: 13px;
}
.account-nav .account-modal .login-form .login-title,
.login-form.not-modal .login-title{
  text-align: center;
  font: bold 1.8rem/1em "HelveticaNeueCyrLight", sans-serif;
  color: #212529;
  margin-bottom: 1rem;
}
.account-nav .account-modal .login-form form.mobile .field label,
.login-form.not-modal form.mobile .field label {
  font-weight: bold;
}
.account-nav .account-modal .login-form form.mobile .field label input,
.login-form.not-modal form.mobile .field label input {
  margin-top: 0.5rem;
  background: #F8F9FA;
  border: 1px solid #CED4DA;
  box-sizing: border-box;
  border-radius: 4px;
}
.login-line{
  overflow: hidden;
  text-align: center;
  font-family: HelveticaNeueCyrLight, sans-serif;
  font-size: 14px;
  margin: 2rem 0 1.5rem;
}
.login-line:before,
.login-line:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  width: 100%;
  height: 1px;
  background: #CED4DA;
  border: solid #FFF;
  border-width: 0 10px;
}
.login-line:before {
  margin-left: -100%;
}
.login-line:after {
  margin-right: -100%;
}
.account-nav .account-modal .login-form form.mobile .field.submit,
.login-form.not-modal form.mobile .field.submit {
  display: block;
}
.account-nav .account-modal .login-form form.mobile .field.submit .create-account,
.account-nav .account-modal .login-form form.mobile .field.submit .login-button,
.login-form.not-modal form.mobile .field.submit .create-account,
.login-form.not-modal form.mobile .field.submit .login-button{
  display: block;
  width: 100%;
  border-radius: 4px;
  max-width: unset;
  background: #28A745;
  border-color: #28A745;
  color: #fff;
}
.account-nav .account-modal .login-form form.mobile .field.submit p,
.login-form.not-modal form.mobile .field.submit p{
  text-align: center;
}
.mini-cart-title{
  margin-bottom: 0.5rem;
}
.mini-cart-title span{
  color: #373A3C;
  font: bold 1.5rem/1.5em "HelveticaNeueCyrLight", sans-serif;
}
.empty-cart-btn{
  float: right;
  border: 1px solid #495057;
  box-sizing: border-box;
  border-radius: 4px;
  font: bold 0.8rem/1.5em "HelveticaNeueCyrLight", sans-serif;
  padding: 0.4rem;
  background: #fff;
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item.mobile,
.modal-dialog-save-cart .product-item.mobile{
  background: #F5F5F5;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.modal-dialog-save-cart .product-item.mobile:last-child{
  margin-bottom: 0;
}
.row.product-item.mobile{
  margin-left: -10px;
  margin-right: -10px;
}
.product-item.mobile .product-title{
  font: bold 1rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  margin-top: 1rem;
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item.mobile .kit-fields span.properties,
.modal-dialog-save-cart .product-item.mobile .kit-fields span.properties{
  color: #818A91;
  font: bold 0.6rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  line-height: 10px;
}
.product-item.mobile .kit-fields .select-item{
  line-height: 16px;
}
.product-item.mobile a.editProduct{
  font: normal 0.75rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  line-height: 16px;
  text-decoration-line: underline;
  color: #0275D8;
}
.icon-delete {
  display: inline-block;
  width: 10px;
  height: 8px;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAACNUlEQVRoge2ZsW4TQRBA36wbK45d8gF8QBo4gwyJwDYRRBAJCYmC30ROFFxQpCCiywe4jihtXxxF8g0FRBjn7Lu9m3Nc7GvXuzPPu6ub3YVAIBAIBDaIHu41Ko/x4cmObx/n8+NJNzqI5/XRpP/8lW+gvIy7USe+ro2m/ajv00/y/nDSjQ5EZAA0gFjFvW8Of3z3TXQd427UcSKnQBOYIXq8O/w5zNM3l8iSxB2mMksSd+SWyRTRw71GPK+PgEcpzZNE5Kg1vDjPn/J9xq+fvnDOnfC/xB2/GrWbx3J2Ga8bI3OPyNllnKh+BMYpzU2nelpmz4y7UWeNRKziPmdJgMce+Tv1J0BrRUDvZbZiORUaM7fIQmATGUsJ8BRZSKCUjLUEFBBZSKSQTBUSUFBkISEvmaokoISIb2JVSkBJEciXoCbz2yolwEAEsmRkJqKqSlohaFYdmIhA5sykYVrimImAl4x50WkqAn9kak6+rVhKgNyoyDvrytnrPJIXVa1i2LWYivxbWrKzWkXroslX68NZpZtd1wcw3ScmM7JqkwuAMhPhOqVbw3JmSotkfhCdO5on+gaYpLSbyYQSpUhiW1c0lkloa8p4i0Qe/GBlmcCDHXWr+Bc3fvlQ5Sa1Gns7Luj6z1461QGVX9DBJ4HpUlOs4o7LSgC0hhfniepb7n80Z4h+sb2g67X3azBQ2GULL7H9AvXa+9Ne+6rqZ4Vpr33l+6zgTZFHGO8YG3hMCgQCgUBgkd+b16QCEhrqiAAAAABJRU5ErkJggg==') 50% 50% no-repeat;
  background-size: 100%;
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item.mobile .remove-product,
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item.mobile .remove-product .remove,
.modal-dialog-save-cart .product-item.mobile .remove-product,
.modal-dialog-save-cart .product-item.mobile .remove-product .remove{
  width: 100%;
}
.modal-dialog-save-cart .product-item.mobile .remove-product .remove{
  border: 0;
  background: transparent;
}
.modal-dialog-save-cart .product-item.mobile .remove-product{
  margin-bottom: 1rem;
}
.modal-dialog-save-cart .product-item.mobile .product-quantity,
.modal-dialog-save-cart .product-item.mobile .product-total-price{
  float: right;
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item.mobile .remove-product .remove,
.modal-dialog-save-cart .product-item.mobile .remove-product .remove{
  text-align: right;
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item.mobile .remove-product .remove-text,
.modal-dialog-save-cart .product-item.mobile .remove-product .remove-text{
  display: inline-block;
  font: normal 0.75rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  color: red;
  text-decoration: underline;
  margin-left: 0.1rem;
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item.mobile .remove-product,
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item.mobile .product-quantity,
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item.mobile .product-total-price,
.modal-dialog-save-cart .product-item.mobile .remove-product,
.modal-dialog-save-cart .product-item.mobile .product-quantity,
.modal-dialog-save-cart .product-item.mobile .product-total-price {
  flex: none;
  max-width: unset;
}
.category.samples.row.mobile .product-quantity{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.product-item.mobile .product-quantity .quantity-buttons .quantity .quantity-minus,
.category.samples.row .product-quantity .quantity-buttons .quantity .quantity-minus,
.category.samples.row .product-quantity .quantity-buttons .quantity .quantity-plus,
.product-information .price-quantity .product-quantity .quantity-buttons .quantity .quantity-minus,
.product-information .price-quantity .product-quantity .quantity-buttons .quantity .quantity-plus,
.product-item.mobile .product-quantity .quantity-buttons .quantity .quantity-plus {
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
}
.product-item.mobile .product-quantity .quantity-buttons .quantity .quantity-minus,
.product-information .price-quantity .product-quantity .quantity-buttons .quantity .quantity-minus,
.category.samples.row .product-quantity .quantity-buttons .quantity .quantity-minus {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.category.samples.row .product-quantity .quantity-buttons .quantity .quantity-plus,
.product-information .price-quantity .product-quantity .quantity-buttons .quantity .quantity-plus,
.product-item.mobile .product-quantity .quantity-buttons .quantity .quantity-plus {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.category.samples.row .product-quantity .quantity-buttons .quantity .quantity-minus,
.category.samples.row .product-quantity .quantity-buttons .quantity .quantity-plus,
.category.samples.row .product-quantity .quantity-buttons .quantity .quantity-number
.product-information .price-quantity .product-quantity .quantity-buttons .quantity .quantity-minus,
.product-information .price-quantity .product-quantity .quantity-buttons .quantity .quantity-plus,
.product-information .price-quantity .product-quantity .quantity-buttons .quantity .quantity-number
.product-item.mobile .product-quantity .quantity-buttons .quantity .quantity-minus,
.product-item.mobile .product-quantity .quantity-buttons .quantity .quantity-plus,
.product-item.mobile .product-quantity .quantity-buttons .quantity .quantity-number {
  padding: 0.4rem;
}
.category.samples.row .product-quantity .quantity-buttons .quantity .quantity-number,
.product-information .price-quantity .product-quantity .quantity-buttons .quantity .quantity-number,
.product-item.mobile .product-quantity .quantity-buttons .quantity .quantity-number{
  padding-left: 1rem;
  padding-right: 1rem;
  width: 3.2rem;
  height: 100%;
}
.category.samples.row .product-quantity .quantity-buttons .quantity,
.product-information .price-quantity .product-quantity .quantity-buttons .quantity,
.product-item.mobile .product-quantity .quantity-buttons .quantity{
  max-width: unset;
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item.mobile .product-total-price .price span,
.modal-dialog-save-cart .product-item.mobile .product-total-price .price span{
  color: #212529;
}
.header .main-navigation .mini-cart .mini-cart-content .product-list .product-item.mobile .product-total-price,
.modal-dialog-save-cart .product-item.mobile .product-total-price{
  margin-top: 1.5rem;
}
.header .main-navigation .mini-cart .mini-cart-content .total-price.mobile,
.header .main-navigation .mini-cart .mini-cart-content .checkout-btn-wrapper{
  background: #E5E5E5;
  margin: 0 auto;
}
.header .main-navigation .mini-cart .mini-cart-content.mobile{
  border: 1px solid #000;
}
.header .main-navigation .mini-cart .mini-cart-content.mobile:before{
  content: '';
  position: absolute;
  top: -0.75rem;
  right: 0;
  z-index: 2;
  width: 1.5rem;
  height: 1.5rem;
  background: #fff;
  transform: rotate(45deg);
  border: 1px solid black;
  border-bottom: none;
  border-right: none;
}
.header .main-navigation .mini-cart .mini-cart-content.mobile{
  right: 0;
  width: 25.85rem;
}
.header .main-navigation .mini-cart .mini-cart-content .total-price.mobile .price,
.navigation .navigation-item.total-price .text-price{
  text-align: left;
  font: bold 1.25rem/1em "HelveticaNeueCyrRoman", sans-serif;
}
.header .main-navigation .mini-cart .mini-cart-content .total-price.mobile .price-value,
.navigation .navigation-item.total-price .currency, .navigation .navigation-item.total-price .price-value{
  padding: 1rem 0;
  text-align: right;
  color: #5CB85C;
  font: normal 1.35rem/1em "HelveticaNeueCyrRoman", sans-serif;
}

.header .main-navigation .mini-cart .mini-cart-content .checkout-btn-wrapper{
  background: #E5E5E5;
  padding: 1.5rem 0;
}
.account-content .orders-history .order-item .order-products .link,
.header .main-navigation .mini-cart .mini-cart-content .checkout-btn-wrapper .to-cart.mobile {
  text-align: right;
}
.account-content .orders-history .order-item .order-products .link,
.header .main-navigation .mini-cart .mini-cart-content .checkout-btn-wrapper .to-cart.mobile .link,
.product-information .price-quantity .product-button button.add-to-cart,
.shopping-cart .checkout-form.mobile form .total-information .pay-button .pay, .shopping-cart .checkout-form .pay-button .pay{
  background: #28A745;
  border: none;
  border-radius: 4px;
  width: 100%;
  display: block;
  text-transform: capitalize;
}
.product-information .price-quantity .product-button{
  /*justify-content: flex-end;*/
  flex-direction: column;
  display: flex;
  height: 100%;
  margin: 0;
}
.product-information .price-quantity .product-button button.add-to-cart{
  padding: 0.65rem 1.5rem;
}

.shopping-cart .checkout-form.mobile form .total-information .pay-button .pay, .shopping-cart .checkout-form .pay-button .pay{
  width: 11.5rem;
}
.account-content .orders-history .order-item .order-products .link,
.header .main-navigation .mini-cart .mini-cart-content .checkout-btn-wrapper .total-price-buttons{
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.save-for-later-btn{
  border: 1px solid #6C757D;
  color: #6C757D;
  box-sizing: border-box;
  border-radius: 4px;
  font: bold 0.8rem/1.5em "HelveticaNeueCyrLight", sans-serif;
  padding: 0.4rem 0;
  background: transparent;
  width: 100%;
  display: block;
  text-align: center;
}
.react-confirm-alert-overlay{
  z-index: 999!important;
}
.header .main-navigation .mini-cart .mini-cart-content.mobile:before {
  right: 1.3rem;
}

/*.modal-dialog-save-cart{*/
/*  max-width: 50%!important;*/
/*}*/
@media (min-width: 576px) {
  .modal-dialog.modal-dialog-save-cart{
    max-width: 80%;
  }
}
@media (min-width: 768px) {
  .modal-dialog.modal-dialog-save-cart{
    max-width: 100%;
  }
}
@media (min-width: 1440px) {
  .modal-dialog.modal-dialog-save-cart{
    max-width: 50%;
  }
}
@media (min-width: 1020px) {
  .modal-dialog.modal-dialog-save-cart{
    max-width: 80%;
  }
}

.modal-dialog-save-cart .modal-body{
  padding-top: 0;
  padding-bottom: 0;
}

.modal-dialog-save-cart .products{
  padding: 1rem;
}
.modal-dialog-save-cart .modal-text{
  padding: 6rem 2rem;
}
.modal-dialog-save-cart .modal-text .modal-title{
  font: normal 3rem/1.5em "HelveticaNeueCyrLight", sans-serif;
  color: #000;
  margin-bottom: 2rem;
}
.modal-dialog-save-cart .modal-text .modal-text-p{
  color: #818A91;
  font: normal 1.5rem/1.5em "HelveticaNeueCyrLight", sans-serif;
}

.modal-dialog-save-cart .products {
  background: #e5e5e5;
  padding: 7rem 1rem;
}
.modal-dialog-save-cart .products .products-list {
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
  overflow: auto;
  height: 39vh;
}
.modal-dialog-save-cart .products-selection-title{
  text-align: center;
  color: #777;
  font: normal 1.5rem/1.5em "HelveticaNeueCyrLight", sans-serif;
}
.modal-dialog-save-cart .modal-buttons{
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  align-items: center;
}
.modal-dialog-save-cart .modal-buttons button,
.modal-dialog-save-cart .modal-buttons a {
  display: inline-block;
  flex: 0 0 32%;
  max-width: 32%;
  text-align: center;
  font: bold 1rem/2em "HelveticaNeueCyrLight", sans-serif!important;
  padding: 6px 0;
}
.modal-dialog-save-cart .modal-buttons .close-modal,
.product-options-reset{
  font: normal 1.5rem/1.5em "HelveticaNeueCyrLight", sans-serif;
  border: 2px solid #D9534F;
  color: #D9534F;
  background: transparent;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
}
.modal-dialog-save-cart .modal-buttons .close-modal:hover,
.product-options-reset:hover{
  color:#fff;
  background: #c24946;
  border-color:#c24946;
}
.product-options-reset{
  font-size: 1.3rem;
  margin-right: .6rem;
  border: 1px solid;
  padding: 0.25rem 1rem;
}
.modal-dialog-save-cart .modal-body .modal-buttons .save-cart-btn,
.samples-modal-body .modal-access-btn {
  background: #28A745;
  color: #fff;
  border-radius: 4px;
  border: 1px solid #28A745;
  display: inline-block;
  float: right;
  font: bold 1.5rem/1.5em "HelveticaNeueCyrLight", sans-serif;
  padding: 6px;
  margin-right: 0 !important;
}
.samples-modal-body {
  background: url(/images/bg-instagram-popup.jpg);
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 100% 71px;
}
.modal-dialog-save-cart .modal-body .modal-buttons .save-cart-btn:hover,
.samples-modal-body .modal-access-btn:hover {
  background: #25993f;
  border-color: #25993f;
}
.samples-header{
  padding: 0 8rem;
}
.samples-header.mobile{
  padding: 0;
}
.samples-title, .samples-text{
  text-align: center;
}
.samples-title {
  color: #000;
  font: normal 3rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  text-align: center;
  margin-bottom: 1.25rem;
}
.samples-header.mobile .samples-title{
  font: normal 2.5rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
}
#moreText{
  display: none;
}
#moreBtn{
  text-decoration: underline;
  cursor: pointer;
  color: #000;
  display: inline-block;
}
.category.samples.row.mobile .category-title{
  margin-bottom: 0;
}
.category.samples.row.mobile .category-content .product-layout .product-thumb{
  width: 100%;
}
.product-name-dots{
  display: none;
}
.product-name-dots.show{
  display: inline;
}
.anchor-item-layout{
  display: block;
  cursor: pointer;
}
.anchor-item{
  height: 13rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  padding: 2rem 1rem;
  background: #fff;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 10px;
}
.anchor-item .anchor-image{
  flex: 0 0 80%;
  max-height: 80%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.anchor-item .anchor-image img{
  object-fit: cover;
  object-position: 0 0;
}

.anchor-item .anchor-title{
  text-align: center;
  color: #0275D8;
  font: bold 1rem/1.2em "HelveticaNeueCyrRoman", sans-serif;
  flex: 0 0 20%;
  max-height: 20%;
  text-transform: capitalize;
  padding: 0 1rem;
}
.anchor-item.mobile{
  flex-direction: row-reverse;
  align-items: center;
  height: 5rem;
  padding: 0.5rem 0.8rem;
}
.anchor-item.mobile .anchor-image {
  flex: 0 0 30%;
  max-width: 30%;
  margin-bottom: 0;
}
.anchor-item.mobile .anchor-title{
  flex: 0 0 70%;
  max-width: 70%;
  text-align: left;
}
#back-to-top{
  position: fixed;
  bottom: 100px;
  right: 30px;
  display: none;
  height: 40px;
  width: 40px;
  box-shadow: 0 0 10px rgba(0,0,0,.05);
  background: url(/images/cd-top-arrow.svg) no-repeat center 50%;
  background-color: #827B71;
  cursor: pointer;
  opacity: 0.5;
  z-index:999;
}
#back-to-top:hover, #back-to-top.mobile{
  opacity: 1;
}
.navigation-item.get-a-price.footer,
.navigation-item.add-to-cart.footer{
  position: fixed;
  bottom: 0;
  z-index: 99;
  background: #E5E5E5;
  border: 1px solid #999999;
  height: 15vh;
  width: 100%;
  box-sizing: border-box;
}
.login-form .tab-navigation.nav.nav-tabs,
.checkout-form .tab-navigation.nav.nav-tabs,
.checkout-form.mobile .tab-navigation.nav.nav-tabs
{
  border: 0;
  margin-bottom: 1rem;
  justify-content: center;
}
.login-form .tab-navigation.nav.nav-tabs .navigation-item a,
.checkout-form .tab-navigation.nav.nav-tabs .navigation-item a,
.checkout-form.mobile .tab-navigation.nav.nav-tabs .navigation-item a{
  padding: 0.5rem 1rem;
  border: 1px solid rgba(89, 89, 89, 0.7);
  color: rgba(89, 89, 89, 0.7);
  cursor: pointer;
  display: block!important;
}
.checkout-form.mobile .tab-navigation.nav.nav-tabs .navigation-item:first-child a,
.checkout-form .tab-navigation.nav.nav-tabs .navigation-item:first-child a,
.login-form .tab-navigation.nav.nav-tabs .navigation-item:first-child a{
  border-right: none;
  border-radius: 2px 0 0 2px;
}
.checkout-form.mobile .tab-navigation.nav.nav-tabs .navigation-item:last-child a,
.checkout-form .tab-navigation.nav.nav-tabs .navigation-item:last-child a,
.login-form .tab-navigation.nav.nav-tabs .navigation-item:last-child a{
  border-left: none;
  border-radius: 0 2px 2px 0;
}
.login-form .tab-navigation.nav.nav-tabs .navigation-item a:hover,
.login-form .tab-navigation.nav.nav-tabs .navigation-item a.active.show,
.checkout-form .tab-navigation.nav.nav-tabs .navigation-item a:hover,
.checkout-form .tab-navigation.nav.nav-tabs .navigation-item a.active.show,
.checkout-form.mobile .tab-navigation.nav.nav-tabs .navigation-item a:hover,
.checkout-form.mobile .tab-navigation.nav.nav-tabs .navigation-item a.active.show{
  background: rgba(2, 117, 216, 0.1);
  border-color: rgba(2, 117, 216, 0.7);
  color: #2D9CDB;
}
.checkout-form.mobile .tab-navigation.nav.nav-tabs .navigation-item a.active.show,
.checkout-form.mobile .tab-navigation.nav.nav-tabs .navigation-item a{
  text-align: center;
  font:normal 1rem/1.5em "HelveticaNeueCyrLight", sans-serif;
}

.shopping-cart .checkout-form form .total-information.no-margin-padding-border{
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
}
.login-form.not-modal{
  width: 23rem;
}
.shopping-cart .checkout-form.mobile form .total-information .total-price{
  background: rgba(196, 196, 196, 0.3);
  margin-left: -20px;
  margin-right: -20px;
  padding: 1.5rem 1rem;
}
.shopping-cart .checkout-form.mobile form .total-information .total-price div.price,
.shopping-cart .checkout-form.mobile form .total-information .total-price p{
  justify-content: space-between;
}
.shopping-cart .checkout-form.mobile form .total-information .total-price div select,
.shopping-cart .checkout-form.mobile form .total-information .total-price p span{
  margin-left: 0;
  margin-right: 0;
  max-width: unset;
  flex: none;
}
.shopping-cart .checkout-form.mobile form .total-information .total-price div.price{
  align-items: center;
}
.shopping-cart .checkout-form.mobile form .total-information .total-price div select{
  max-width: 70%;
}
.shopping-cart .checkout-form.mobile form .total-information .total-price p.price-total{
  margin-top: 2rem;
}
.shopping-cart .checkout-form.mobile form .total-information .pay-button{
  justify-content: center;
}
.checkout-form.mobile .product-tabs .tab-navigation .navigation-item{
  flex: 0 0 50%;
  max-width: 50%;
}
.checkout-form.mobile .card-image{
  text-align: center;
  margin-top: 1.5rem;
}
.checkout-form .card-image{
  text-align: right;
  margin-top: 1.5rem;
}

div.room-flex{
  display: flex;
  align-items: center;
}
.login-form.not-modal{
  margin: auto;
}
.calculator-element-mobile,.calculator-element-mobile .room .room-link .room-name, .elements-list-mobile div.element-item a {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.check-email-title{
  color: #0275D8;
  font: bold 1.5rem/1.5em "HelveticaNeueCyrLight", sans-serif;
}
.email-icon-modal{
  background: url("../src/images/email-icon-blue.svg") 50% 50% no-repeat;
  height: 48px;
  width: 48px;
  margin: auto;
}

.check-email-text{
  color: #212529;
  font: normal 1rem/1.5em "HelveticaNeueCyrLight", sans-serif;
}
div.success.activate{
  text-align: center;
  font-size: 2.5rem;
  color: #28a745;
}
.margin-bottom{
  margin-bottom: 9em;
}
.mini-cart-overlay{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: black;
  opacity: 0.5;
}
.exterior-material-gallery.calculator-material.modal-gallery .option-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem;
  height: 15rem;
}
.exterior-material-gallery.calculator-material.modal-gallery .option-item .option-image{
  flex: 0 0 70%;
  max-height: 70%;
  text-align: center;
}
.exterior-material-gallery.calculator-material.modal-gallery .option-item .option-image .lazyload-wrapper{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.exterior-material-gallery.calculator-material.modal-gallery .option-item .option-image img,
.exterior-material-gallery.calculator-material.modal-gallery .option-item .option-image .lazyload-wrapper img{
  object-fit: cover;
  object-position: 0 0;
  max-height: 100%;
}
.exterior-material-gallery.calculator-material.modal-gallery .option-item span.room-name{
  flex: 0 0 30%;
  max-height: 30%;
  text-align: left;
  font: normal 0.7rem/1.5em "HelveticaNeueCyrLight", sans-serif;
  margin-top: 0.5rem;
}
.saveCart.close-modal{
  color: red;
}
.touchevents .next-btn{
  display: none;
}
.touchevents .next-btn-enter {
  opacity: 0.01;
  display: inline-block;
}
.touchevents .next-btn-enter.next-btn-enter-active {
  display: inline-block;
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.touchevents .next-btn-enter-done{
  display: inline-block;
}
.touchevents .next-btn-leave {
  display: inline-block;
  opacity: 1;
}

.touchevents .next-btn-leave.next-btn-leave-active {
  display: inline-block;
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
.product-image .lens-icon,
.product-gallery .main-images .lens-icon{
  position: absolute;
  left: 0;
  bottom: 0;
  background: url('../src/images/loupe.svg') 35% 55% no-repeat;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: #C4C4C4;
  border-top-right-radius: 35px;
}
.product-gallery .main-images .lens-icon{
  right: 0;
  left: unset;
  border-top-left-radius: 35px;
  border-top-right-radius: 0;
  background: url('../src/images/loupe.svg') 55% 60% no-repeat;
  background-color: #C4C4C4;
}
.product-image .lens-icon:hover,
.product-gallery .main-images .lens-icon:hover{
  transform: scale(1.1);
  transition: transform 0.3s;
}
.sample-full-image{
  text-align: center;
}
.maple-leaf-img{
  position: absolute;
  top: -4px;
  right: 14px;
  transform: rotate(25deg);
}
.touchevents .maple-leaf-img{
  position: absolute;
  top: -8px;
  right: 0;
  transform: rotate(25deg);
}

.touchevents .full-width-title .title{
  font-weight: normal;
  font-size: 1.2rem;
}
.touchevents .our-mission{
  background: none;
  color: #000;
}
.touchevents .our-mission div p{
  line-height: 2rem;
  text-align: justify;
}
.touchevents .our-mission .read-more-wrapper{
  text-align: center;
}
.touchevents .our-mission .read-more{
  text-decoration: underline;
  cursor: pointer;
}
.touchevents #numbers{
  padding: 0 1.4rem;
  flex-direction: column;
}
.touchevents #numbers .item{
  position: relative;
  transition: all 0.45s ease-in-out;
  min-width: 240px;
  padding: 0;
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 10px;
  margin-bottom: 1rem;
  align-items: center;
  background: #F5F5F5;
}
.touchevents #numbers .item .number{
  flex: 0 0 30%;
  max-width: 30%;
  background: #DDDDDD;
  border: 1px solid transparent;
  border-radius: 10px;
  color: #818A91;
  font-size: 2rem;
  font-family: "Open Sans", sans-serif;
  padding: 0.5rem 1rem;
  text-align: right;
  margin-right: 1rem;
}

.touchevents #numbers .item .text{
  flex: 0 0 70%;
  max-width: 70%;
  margin-top: 0;
  text-align: left;
  text-transform: capitalize;
}
.touchevents #faqs, .touchevents #contact-us{
  padding: 5rem 0.5rem 1rem;
}
.touchevents #faqs .faq-container{
  position: relative;
  border: 1px solid #ddd;
}
.touchevents #faqs .faq-container .faq-arrow{
  position: absolute;
  top: 1rem;
  right: 1rem;
}
#contact-us .position-relative.form-group{
  text-align: center;
}
.touchevents #contact-us button.callback-sumbit.btn.btn-secondary,
.btn.mobile-btn{
  background: #fff;
  color: #0275D8;
  border: 1px solid #0275D8;
  margin-bottom: 1rem;
}
.touchevents #portfolio .exterior-material-gallery .gallery-list{
  display: unset;
  max-width: unset;
}
.touchevents #portfolio .exterior-material-gallery .gallery-list .gallery-item{
  flex: 0 0 95%;
  max-width: 95%;
}
.touchevents #portfolio .exterior-material-gallery .gallery-list .gallery-item button{
  background: transparent;
}
.touchevents #portfolio .slick-slider .slick-list{
  margin: 0 0 0 -0.4rem;
}
.touchevents #portfolio .slick-dots{
  bottom: 6px;
  left: -6px;
}
.touchevents #portfolio .slick-dots li {
  width: 5px;
  height: 5px;
  margin: 0 12px;
}
.touchevents #top .main-slider-banner .slick-slide{
  height: 150px;
}
.touchevents #top .main-slider-banner .slick-dots{
  bottom: 0.1rem;
}
.touchevents #top .main-slider-banner .slick-dots li{
  width: 5px;
  display: inline-block;
  height: 5px;
  float: none;
}
.touchevents #top .image-gallery-left-nav .image-gallery-svg,
.touchevents #top .image-gallery-right-nav .image-gallery-svg{
  width: 24px;
  height: 24px;
}
.touchevents #faqs-contacts,
.touchevents #top {
  background: #fff!important;
}
.touchevents .full-width-title h1.title{
  font-size: 1.3rem;
  margin-top: 3rem;
}
.touchevents  .subtitle.text-align-center{
  font-size: 1rem;
  padding: 1rem 1rem 0 1rem;
}
.touchevents .subtitle.text-align-center h3{
  margin: 0;
  font-size: 1rem;
}
.btn.mobile-btn{
  background: transparent;
  margin-bottom: 0;
}
.touchevents .calc-button{
  margin-top: 1.25rem;
}

.touchevents #tabs{
  margin-bottom: 3rem;
}

.touchevents #tabs .tabs-category-content{
  display: flex;
  justify-content: space-around;
  margin-bottom: 2.5rem;
}
.touchevents #tabs .tabs-category-wrapper{
  flex: 0 0 30%;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  padding: 0.3rem;
}
.touchevents #tabs .tabs-category-item{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.touchevents #tabs .tabs-category-item .tabs-category-img{
  flex: 0 0 70%;
  max-height: 70%;
}
.touchevents #tabs .tabs-category-item .tabs-category-item-name{
  flex: 0 0 30%;
  max-height: 30%;
  color: #373a3c;
  font: normal .7rem/1em "HelveticaNeueCyrLight",sans-serif;
  line-height: 14px;
  text-align: center;
  margin-bottom: 1rem;
}

.touchevents #tabs .tabs-category-header{
  display: flex;
  justify-content: space-around;
  padding: 0 1rem 0.5rem;
}

.touchevents #tabs .tabs-category-title{
  flex: 0 0 80%;
  max-width: 80%;
  font-weight: bold;
}
.touchevents #tabs .tabs-category-link{
  flex: 0 0 20%;
  max-width: 20%;
  text-align: right;
  text-decoration: underline;
  text-decoration-color: #0275D8;
}
.touchevents #tabs .tabs-category-title,
.touchevents #tabs .tabs-category-link {
  color: #0275D8;
}

.tooltip-item{
  background: url('../src/images/info.svg') 50% 50% no-repeat;
  display: inline-block;
  width: 15px;
  height: 15px;
  cursor: pointer;
  opacity: 0.7;
  margin-left: 0.5rem;
  position: absolute;
}

.tooltip-item:hover{
  opacity: 1;
  transition: opacity 0.2s;
}
.samples-modal{
  padding: 0;
  max-width: 750px;
  top: calc(50% - 210px);
}
.samples-modal-body{
  display: flex;
  justify-content: space-between;
}
.samples-modal-body .samples-modal-left {
  flex: 0 0 49%;
  max-width: 49%;
  padding: 30px 0 0 30px;
  text-align: center;
}
.samples-modal-body .samples-modal-left img {
  max-width: 230px;
}
.samples-modal-body .samples-modal-right {
  flex: 0 0 49%;
  max-width: 49%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.samples-modal-body .samples-modal-right a img {
  border-radius: 50px;
}
.samples-modal-body .samples-modal-right a {
  outline: none;
  display: inline-block;
  border-radius: 50px;
}
.samples-modal-body .samples-modal-right a {
  animation: pulse 2s infinite;
}
.samples-modal-close-btn{
  top: 0;
  right: 0;
  background: #c4c4c4;
  border-bottom-left-radius: 40px;
  width: 40px;
  height: 40px;
}
.samples-modal-close-icon{
  right: 2px;
  position: absolute;
  fill: #6C757D;
}
.samples-modal-img-wrapper{
  display:flex;
  align-items:center;
  justify-content:center;
  height: 100%;
}
.samples-modal-body h2{
  margin-bottom: 0;
  font-family: "HelveticaNeueCyr", sans-serif;
  font-size: 1.9rem;
}
.samples-modal-body h1{
  font-weight: bold;
  font-family: "HelveticaNeueCyrBold", sans-serif;
  color: #000;
  font-size: 2rem;
}
.touchevents .samples-modal{
  max-width: 300px;
  top: 0;
}
.touchevents .samples-modal-body{
  flex-direction: column;
}
.touchevents .samples-modal-body .samples-modal-right,
.touchevents .samples-modal-body .samples-modal-left{
  max-width: 100%;
}
.touchevents .samples-modal-body .samples-modal-left {
  margin-bottom: -4px;
  padding-top: 20px;
}
.touchevents .samples-modal-body .samples-modal-left img {
  max-width: 180px;
}
.touchevents .samples-modal-body .samples-modal-right {
  padding: 2rem 4rem;
}
.touchevents .samples-modal-body {
  background-size: 100% 95px;
}
.touchevents .samples-modal-body .samples-modal-left {
  padding: 0;
  flex: 0 0 30%;
  max-height: 30%;
}
.touchevents .samples-modal-body h1{
  font-size: 2rem;
}
.subtitle.text-align-center{
  display: block;
}
.touchevents .subtitle.text-align-center{
  display: none;
}
.subtitle.text-align-center.mobile{
  display: none;
}

.touchevents .subtitle.text-align-center.mobile {
  display: block;
}
#tabs .text-align-center.tab-subtitle{
  display: block;
}
.touchevents #tabs .text-align-center.tab-subtitle{
  display: none;
}

#tabs .tabs-category{
  display: none;
}
.touchevents #tabs .tabs-category{
  display: block;
}

#tabs .tabs{
  display: block;
}
.touchevents #tabs .tabs{
  display: none;
}
#about .about-text-desktop{
  display: block;
}
.touchevents #about .about-text-desktop{
  display: none;
}
#about .about-text-mobile{
  display: none;
}
.touchevents #about .about-text-mobile{
  display: block;
}
#about .read-more-wrapper{
  display: none;
}
.touchevents #about .read-more-wrapper{
  display: block;
}
#product-page-gallery .slick-slider{
  flex: 0 0 15%;
  max-width: 15%;
  margin-right: 5%;
}
#product-page-gallery .slick-slider .slick-list{
  margin: 0;
}

#product-page-gallery .slick-slider .image-gallery-icon {
  transform: rotate(-90deg);
  position: absolute;
  padding: 0;
  left: 50%;
  margin-left: -10px;
}
#product-page-gallery .slick-slider .image-gallery-right-nav{
  top: -3rem;
}
#product-page-gallery .slick-slider .image-gallery-left-nav{
  bottom: -17rem;
}
#product-page-gallery .slick-slider .image-gallery-left-nav .image-gallery-svg,
#product-page-gallery .slick-slider .image-gallery-right-nav .image-gallery-svg{
  width: 20px;
  height: 60px;

}
.product-items-wrapper .item .product-image .discount,
.category-products .item .product-image .discount,
.viewed-products .item .product-image .discount{
  position: absolute;
  top: 0;
  right: 0;
}
.product-items-wrapper .item .product-image .discount:after,
.category-products .item .product-image .discount:after,
.viewed-products .item .product-image .discount:after {
  content: '';
  background: transparent;
  color: white;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 3rem solid transparent;
  position: absolute;
  top: -3em;
  right: -3rem;
  border-bottom-color: red;
}

.product-items-wrapper .item .product-image .discount-value,
.category-products .item .product-image .discount-value,
.viewed-products .item .product-image .discount-value {
  color: white;
  position: absolute;
  top: 0.5rem;
  right: 0;
}
.product-image.active,
.gallery .product-gallery .main-images.active {
  animation: animFadeImage .4s linear;
}
.product-list-series .main-item .link-plus {
  width: 46px;
  height: 44px;
  align-items: center;
  justify-content: center;
}
@keyframes animFadeImage {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.product-image.active {

}
.viewed-products .more-btn{
  display: none;
}
.touchevents .viewed-products .more-btn{
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
  display: block;
}
.touchevents .viewed-products .more-btn.hide{
  display: none;
}
.touchevents .viewed-products .more-btn span{
  display: block;
  background: #0275D8;
  color: #fff;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  max-width: 40%;
  margin: 0 auto;
}

.product-information {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-information .product-information-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.product-information .product-information-wrap .price-quantity {
  margin-top: auto;
  padding: 0 40px;
}
.product-information .product-information-wrap .product-button {
  display: flex;
  justify-content: center;
}
.product-information .product-information-wrap .product-button .add-to-cart {
  background: #28A745;
  border: none;
  border-radius: 4px;
  width: 100%;
  display: block;
  text-transform: capitalize;
  padding: 15px;
 }
.product-information .price-quantity{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.product-information .price-quantity .product-price{
  display: block;
  margin-right: 0;
}
.product-information .price-quantity .product-total-price,
.product-information .price-quantity .product-price,
.product-information .price-quantity .stock-status,
.product-information .price-quantity .product-quantity{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.product-information .price-quantity .stock-status .price-text,
.product-information .price-quantity .product-price .price-text,
.product-information .price-quantity .product-total-price .price-text,
.product-information .price-quantity .product-quantity .price-text {
  color: #000;
  font: normal 0.9rem/1.25em "HelveticaNeueCyrRoman", sans-serif;
  margin-bottom: 0.25rem;
  /*margin-right: 0.25rem;*/
  text-align: left;
}
.product-information .price-quantity .product-total-price .price-text {
  text-align: center;
}
.product-information .price-quantity .product-total-price .price-value{
  font-family: 'HelveticaNeueCyrRoman', sans-serif;
  font-weight: bold;
  margin-top: 5px;
}
.product-information .price-quantity .stock-status .stock-indicator {
  margin-top: 3px;
}
.product-information .price-quantity .product-price .price-value{
  font-size: 1rem;
}
.product-options-buttons{
  float: right;
}

.product-options .option .option-list .option-item label{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}
.product-options .option .option-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  row-gap: 10px;
  column-gap: 10px;
  width: 100%;
}

.touchevents .module.module-viewed-products .viewed-products .item {
  margin-bottom: 15px !important;
}
.touchevents div.breadcrumb .breadcrumb-list .breadcrumb-item .breadcrumb-current {
  font-size: 1rem !important;
}
.product-options .option .option-list .option-item{
  border: 1px solid #e4e5e6;
  border-radius: 5px;
  padding: 1rem;
  max-width: 100% !important;
  margin: 0;
  height: 47px;
}
.touchevents .viewed-products .item img {
  margin: 0;
}
.product-options .option .option-list .option-item.thumb {
  overflow: hidden;
  padding: 0 0.7rem;
  height: 47px;
}
.product-list-series .main-item {
  padding: 0 !important;
}
.product-options .option .option-list .option-item label img{
  flex: 0 0 50px;
  max-width: 50px;
  margin-right: 0.5rem;
  margin-left: -0.7rem;
}
.product-options .option .option-list .option-item{
  margin-top: 0;
}
.product-information .product-title-block {
  padding: 0 !important;
}
/*.option .option-list .option-item label .value{*/
/*  flex: 0 0 35%;*/
/*  max-width: 35%;*/
/*}*/
.product-description-wrapper{
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
}
.product-description-wrapper .button-wrap {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
.product-description-wrapper .description ul {
  list-style-type: disc;
}
.product-description-wrapper .description ol {
  list-style-type: decimal;
}
.product-description-wrapper .description ul,.product-description-wrapper .description ol {
  padding-left: 25px;
}
.product-description-left{
  flex: 0 0 48%;
  max-width: 48%;
}
.product-description-right{
  flex: 0 0 35%;
  max-width: 35%;
}
.description-item{
  width: 100%;
}
.reviews-wrapper .title,
.description-item .title{
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.9rem;
}
.reviews-wrapper .title{
  margin-bottom: 1rem;
}
.description-item:not(:first-child) {
  margin-top: 2rem;
}
.img-border {
  border: 1px solid #d8d8d8;
}
.product-items-wrapper,
.category-products{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.product-items-wrapper .item,
.category-products .item,
.viewed-products .item{
  flex: 0 0 45%;
  border: 2px solid #e4e5e6;
  background: #fff;
  max-width: 45%;
  border-radius: 5px;
  position: relative;
  margin: 0 0.5rem 1rem;
}
.category-products .item{
  flex: 0 0 24%;
  max-width: 24%;
  margin: 0 0.25rem 1rem;
}
.viewed-products .item{
  flex: unset;
  max-width: unset;
  margin: 0 1rem;
}
.product-items-wrapper .item .bestseller-label,
.category-products .item .bestseller-label,
.viewed-products .item .bestseller-label{
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  background: #5CB85C;
  border-radius: 4px;
  color: #fff;
  padding: 0.3rem 1rem;
  font-weight: bold;
}

.product-items-wrapper .item img,
.category-products .item img,
.viewed-products .item img {
  max-width: 75%;
  margin: 1rem auto;
  display: block;
}

.product-items-wrapper .item .bottom,
.category-products .item .bottom,
.viewed-products .item .bottom{
  border-top: 2px solid #e4e5e6;
  padding: 1rem;
}
.product-items-wrapper .item .bottom .product-name,
.category-products .item .bottom .product-name,
.viewed-products .item .bottom .product-name {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: left;
}
.viewed-products .slick-slide {
  height: auto;
}
.viewed-products .slick-slide .item {
  height: 100%;
  margin-bottom: 15px;
}
.viewed-products .item .bottom .product-name {
  min-height: 5rem;
}

.text-instagram-wrap {
  margin-top: 20px;
}
.btn-to-instagram-wrap {
  margin: 20px 0;
  text-align: center;
}
.btn-to-instagram {
  padding: 0.5rem;
  border: 2px solid #0275D8;
  border-radius: 5px;
  background: #0275D8;
  color: #fff;
  display: inline-block;
  transition: .2s;
  outline: none;
}
.btn-to-instagram:hover {
  background-color: #fff;
  color: #0275D8;
}
.head-contact-info-wrap {
  display: inline-flex;
  align-items: center;
}
.head-contact-info {
  display: flex;
  column-gap: 10px;
}
.head-contact-info .phone-wrap,
.head-contact-info .work-wrap {
  display: inline-flex;
  position: relative;
  cursor: pointer;
}
.head-contact-info .ico {
  display: inline-block;
  width: 24px;
  margin-right: 5px;
}
.head-contact-info .value {
  display: none;
  position: absolute;
  top: 100%;
  padding: 4px 10px;
  color: #fff;
  z-index: 2;
  font-size: 14px;
  white-space: nowrap;
}
.head-contact-info.phone-wrap,
.head-contact-info.work-wrap {
  position: relative;
}
.head-contact-info.phone-wrap .value,
.head-contact-info.work-wrap .value {
  top: auto;
  bottom: 100%;
  left: auto;
  right: 0;
}
.head-contact-info .phone-wrap:hover .value,
.head-contact-info .work-wrap:hover .value,
.head-contact-info.phone-wrap:hover .value,
.head-contact-info.work-wrap:hover .value{
  display: block;
}
.title-insta-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.title-insta-wrapper .fa svg {
  width: 60px;
  margin-right: 10px;
}
.instagram-gallery-wrap .lazyload-wrapper {
  height: 100%;
}
.instagram-gallery-wrap .lazyload-wrapper img {
  height: 100%;
  object-fit: cover;
}
.product-information .product-information-wrap {
  position: relative;
}
.product-information .product-information-wrap .product-options-buttons {
  position: absolute;
  right: -8px;
  top: -17px;
}
.articles-sidebar {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 15px;
}
.articles-sidebar .sidebar-wrapper-job [class*="col-"] {
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%;
  padding: 0;
}
.articles-sidebar .sidebar-wrapper-job .full-width-title {
  position: static;
  padding: 15px 0 0;
  margin-top: 15px;
  margin-bottom: 15px;
  border-top: 1px solid #ccc;
}
.articles-sidebar .sidebar-wrapper-job h2.title {
  font-size: 18px;
}
.articles-sidebar .sidebar-wrapper-job #contact-us {
  padding: 0;
}
.articles-sidebar .btn-all-articles-wrap {
  text-align: center;
  margin-top: 15px;
}
.articles-sidebar .btn-all-articles {
  border: 1px solid #007bff;
  border-radius: 4px;
  background: #fff;
  color: #007bff;
}
.articles-sidebar .btn-all-articles:hover {
  background-color: #007bff;
  color: #fff;
}
.articles-sidebar .checkbox-group-wrap {
  padding: 5px 0;
  line-height: 1.2;
  font-size: 14px;
}
.account-nav .account-modal .account-information .account-list li {
  flex: 0 0 33% !important;
  max-width: 33% !important;
}
.checkout-page {
  padding-top: 5rem;
}
.product-line-btn {
  display: inline-block;
  padding: 2px 10px;
  margin: 10px 0;
  border: 1px solid #000;
  border-radius: 2px;
  color: #000;
  position: relative;
  background-color: #f3f3f3;
}
.product-line-btn:hover {
  color: #fff;
  border-color: #000;
  background-color: #000;
}
.pro-side-bar .dropdown-submenu {
  position: static !important;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}
.pro-side-bar .child-menu-arrow {
  position: static;
}
.pro-side-bar .dropdown-menu .dropdown-submenu>.dropdown-menu {
  left: 100%;
  top: -72px;
  overflow: auto;
  height: 100vh;
  width: 85vw;
}
.pro-side-bar .dropdown-menu .dropdown-submenu>.dropdown-menu>li {
  width: 340px;
}
.pro-side-bar .dropdown-menu .dropdown-submenu>.dropdown-menu .dropdown-menu {
  width: 370px;
  left: 340px;
  top: 10px;
}
.pro-side-bar .dropdown-menu .dropdown-menu .dropdown-menu {
  top: 0;
}
.active-country-wrapper {
  margin-right: 15px;
  padding-right: 10px;
  border-right: 1px solid #fafafa;
  color: #fff;
  position: relative;
}

.active-country-wrapper ul {
  position: absolute;
  padding: 10px;
  background: #373A3C;
  top: 100%;
  width: 110px;
  left: 0;
}
.active-country-wrapper ul li {
  padding: 3px 0;
}
.active-country-wrapper ul li:hover {
  opacity: .8;
  cursor: pointer;
}
.active-country-wrapper .us:after,.active-country-wrapper .canada:after {
  content: '';
  display: inline-block;
  width: 25px;
  height: 20px;
  margin-left: 7px;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: sub;
}
.active-country-wrapper .us:after {
  background-image: url("/images/united-states-flag.svg");
}
.active-country-wrapper .canada:after {
  background-image: url("/images/canada-flag.svg");
}
.active-country-wrapper .active-country {
  line-height: 1;
}
.active-country-wrapper .flag-ico {
  vertical-align: middle;
  margin-left: 3px;
}
.touchevents .header .main-navigation nav .nav {
  height: 100vh;
  overflow: auto;
}

/*.option-item {*/
/*  position: relative;*/
/*}*/
/*.option-item .tooltip-info {*/
/*  position: absolute;*/
/*  bottom: 100%;*/
/*  border-radius: 4px;*/
/*  padding: 5px;*/
/*  color: #fff;*/
/*  font-size: 12px;*/
/*  background-color: #000;*/
/*  display: none;*/
/*}*/
/*.option-item:hover .tooltip-info {*/
/*  display: block;*/
/*}*/
@media (max-width: 990px) {
  .checkout-page {
    padding-top: 2rem;
  }
  .header .main-navigation .account-nav .account-link {
    padding: 0.4rem !important;
  }
  .tooltip-item {
    right: 0;
  }
  .touchevents .header .main-navigation .menu-link.order-now-link.single {
    margin-right: 0.8rem !important;
  }
  .checkout-page {
    margin-top: 25px;
  }
  .touchevents .account-information-wrapper .account-navigation {
    padding: 1rem 1rem 1.8rem !important;
  }
  .account-content .account-information .account-form.default .field label select, .account-content .account-form.default .account-information .field label select {
    padding: 0 0.625rem !important;
  }
  .head-contact-info {
    position: absolute;
    right: 200px;
    top: 10px;
  }
  .head-contact-info .value {
    background-color: #000;
  }
  .product-list-series .main-item.plus-icon {
    width: 85% !important;
    margin: 0;
  }

  .row-steps-mobile {
    margin-top: 20px;
  }
  .touchevents .product-items-wrapper .item img, .touchevents .category-products .item img, .touchevents .viewed-products .item img {
    max-width: 100% !important;
    width: 100%;
    height: auto;
  }
  .navigation-item.footer .total-price .number {
    color: #5CB85C;
    font-weight: 700;
    font-size: 20px;
  }
  .options.product-options {
    width: 100%;
  }

  div.breadcrumb .breadcrumb-list {
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }
  .breadcrumb-item+.breadcrumb-item {
    padding-left: 0 !important;
  }
  .product-rating {
    margin-bottom: 2rem !important;
  }
  .touchevents .module.module-viewed-products .viewed-products .item {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin: 0 0 15px !important;
  }
  .touchevents .module.module-viewed-products .viewed-products {
    padding: 0 !important;
  }
  .touchevents .viewed-products .slick-slider {
    width: 100%;
  }
  .touchevents .viewed-products .slick-slider .slick-list {
    margin: 0 1rem
  }
  .touchevents .category-list .category-item {
    position: relative;
    flex-direction: row !important;
    justify-content: flex-start !important;
  }
  .touchevents .category-list .category-item .category-image {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    flex: 0 0 30% !important;
    max-width: none !important;
    width: 100px !important;
  }
  .touchevents .category-list .category-item .category-image img {
    max-height: 100%;
  }
  .touchevents .category-list .category-item .category-view-btn-mobile {


  }
  .touchevents .category-list .category-item.selected .category-name {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .touchevents .category-list .category-item.selected .category-image {
    flex: 0 0 30% !important;
    max-width: 30% !important;
  }
  .touchevents .category-list .category-item.selected {
    background-color: transparent;
    border-color: transparent;
  }
  .touchevents .category-list .category-item.selected .cat-info-row {
    background-color: #fff;
  }
  .touchevents .category-list .category-view-btn-mobile.selected {
    padding: 10px 20px !important;
    line-height: 4.5 !important;
  }
  .main-navigation .currency-wrapper,.main-navigation .active-country-wrapper {
    display: none;
  }
  .menu-item .currency-wrapper, .menu-item .active-country-wrapper {
    display: block;
    text-align: right;
  }
  .menu-item .active-country-wrapper {
    border: none;
    padding-right: 0;
    margin-right: 0;
    z-index: 1;
  }
  .menu-item .active-country-wrapper ul {
    right: 0;
    left: auto;
  }

  .title-insta-wrapper .fa svg {
    margin-right: 0;
    width: 40px;
  }
  .title-insta-wrapper .title {
    margin-left: 0 !important;
  }

  .list-section.category-list {
    display: block;
  }
  .viewed-products .item .bottom .product-name {
    min-height: 0;
  }
  .faq-blog-section #faqs {
    padding: 6rem 0 3rem !important;
  }
}

@media (min-width: 1300px) and (max-width: 1920px) {
  .head-contact-info-wrap {
    position: absolute;
    left: 21%;
    top: 7px;
  }
  .header .main-navigation nav .nav .menu-link {
    font-size: 0.75rem !important;
  }
  .head-contact-info svg {
    max-width: 100%;
  }
  .head-contact-info {
    flex-direction: column;
    background-color: transparent;
  }
  .head-contact-info .ico {
    width: 17px;
  }
  .head-contact-info .phone-wrap,
  .head-contact-info .work-wrap {
    position: relative;
  }
  .head-contact-info .phone-wrap .value,
  .head-contact-info .work-wrap .value {
    position: static;
    display: block;
    padding: 1px 10px 1px 5px;
    background-color: transparent;
  }
}
@media (min-width: 2000px) {
  .header .main-navigation {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .head-contact-info {
    padding-left: 10px;
  }
  .head-contact-info .phone-wrap,
  .head-contact-info .work-wrap {
    position: relative;
  }
  .head-contact-info .phone-wrap .value,
  .head-contact-info .work-wrap .value {
    position: static;
    display: block;
  }
}

.product-items-wrapper .item:hover .bottom .product-name,
.category-products .item:hover .bottom .product-name,
.viewed-products .item:hover .bottom .product-name {
  color: #0275D8;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}

.viewed-products .item .bottom .product-price,
.category-products .item .bottom .product-price,
.product-items-wrapper .item .bottom .product-price{
  margin-right: 0;
  display: block;
  position: relative;
}
.viewed-product .product-title .product-price {
  display: flex;
}
.viewed-product .product-title .product-price .options-count{
  text-align: left;
  display: inline-block;
}
.viewed-products .item .bottom .product-price .price,
.category-products .item .bottom .product-price .price,
.product-items-wrapper .item .bottom .product-price .price{
  display: inline-block;
  color: #4A7287;
  font-weight: bold;
}
.viewed-product .product-title .product-price .price{
  color: #000;
  font-weight: normal;
}

.product-information .price-quantity .product-price .price-value .price.color-green,
.viewed-products .item .bottom .product-price .price.color-green,
.category-products .item .bottom .product-price .price.color-green,
.product-items-wrapper .item .bottom .product-price .price.color-green{
  color: #28A745;
}

.product-information .price-quantity .product-price .price-value .price.color-green{
  font-size: 1rem;
}

.product-information .price-quantity .product-price .price-value .price-old,
.viewed-products .item .bottom .product-price .price-old,
.category-products .item .bottom .product-price .price-old,
.product-items-wrapper .item .bottom .product-price .price-old{
  text-decoration: line-through;
  color: #818A91;
  font-size: 0.8rem;
  position: absolute;
  bottom: -1rem;
}

.viewed-product .product-title .product-price .price .currency,
.viewed-product .product-title .product-price .price .number{
  color: #69B9E2;
}

.viewed-product .product-title .product-price .price,
.viewed-product .product-title .product-price .options-count{
  flex: 0 0 50%;
}

.product-items-wrapper .item .view-item-btn,
.category-products .item .view-item-btn,
.viewed-products .item .view-item-btn{
  max-width: 50%;
  display: none;
  padding: 0.3rem 0.7rem;
  background: #fff;
  color: #0275D8;
  border: 1px solid #096DD9;
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.8rem;
  margin: auto;
  position: absolute;
  min-width: 100px;
  height: 40px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.product-items-wrapper .item:hover .view-item-btn,
.category-products .item:hover .view-item-btn,
.viewed-products .item:hover .view-item-btn {
  display: block;

  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}

.product-items-wrapper .item.selected,
.product-items-wrapper .item:hover,
.category-products .item.selected,
.category-products .item:hover,
.viewed-products .item.selected,
.viewed-products .item:hover{
  border: 2px solid #0275D8;
  -webkit-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  cursor: pointer;
}

.product-items-wrapper .item.selected .bottom .view-item-btn,
.category-products .item.selected .bottom .view-item-btn,
.viewed-products .item.selected .bottom .view-item-btn{
  display: block;
}

.product-description-wrapper .product-description-left .document-name{
  overflow-wrap: break-word;
}
.product-description-right .block-title{
  color: #0275D8;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.module-viewed-products .slick-track{
  margin-left: 0;
}
.review-item{
  display: flex;
  margin-bottom: 1rem;
}
.review-item-left{
  flex: 0 0 15%;
  max-width: 15%;
}
.review-item-right{
  flex: 0 0 80%;
  max-width: 80%;
}
.review-item-left .client-name{
  font-size: 0.8rem;
  font-weight: bold;
  overflow-wrap: anywhere;
}
.review-item-left .review-date{
  font-size: 0.7rem;
}
.product-page-title{
  margin-bottom: 0!important;
}
.product-rating{
  margin-bottom: 1rem;
}
.reviews-count{
  display: inline-block;
  text-decoration: underline;
  color: #4F4F4F;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
.reviews-wrapper .bottom {
  text-align: right;
}
.reviews-wrapper .bottom .text{
  display: inline-block;
}
.reviews-wrapper .bottom > span {
  margin: 0 0.5rem;
}
.reviews-wrapper .bottom .leave-review-btn{
  background: none;
}
.kit-notes-item {
  position: relative;
  width: fit-content;
}
.kit-notes-description{
  width: fit-content;
}
.kit-notes-item .tooltip-item{
  top: 0;
  right: -23px;
}
.kit-notes-tooltip .tooltip-inner{
  max-width: 300px;
}
.kit-notes-tooltip .tooltip-inner .kit-notes-img{
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.touchevents .kit-notes-item .tooltip-item {
  right: 0;
  margin-left: 0;
}

.tooltip.show{
  opacity: 1!important;
}
.planner .select-cabinet{
  padding: 3rem 5rem;
  background-color: transparent;
  border: 2px solid #4bbae7;
  border-radius: 10px;
  font-weight: bold;
  font-size: 1.6rem;
}

.planner .select-cabinet.active,
.planner .select-cabinet:hover
{
  background-color: #4bbae7;
  color:#fff;
}
.planner .confirm-select-cabinet{
  padding: 1rem 2rem;
  background: #28A745;
  border-radius: 10px;
  font-weight: bold;
  font-size: 1rem;
  color:#fff;
  border-color: #28A745;

}
.planner .confirm-select-cabinet:hover
{
  background-color: #fff;
  color:#000;
}
.sorting-text .items-count{
  color: #69B9E2;
}
.touchevents .category-title h1{
  font-size: 1.3rem;
}
.touchevents .category-list .category-item{
  flex-direction: row-reverse;
  justify-content: flex-end;
  flex: 0 0 100%;
  max-width: 100%;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  margin: 0.5rem 0;
}

.touchevents .category-list .category-item .category-image,
.touchevents .category-list .category-item .category-name {
  max-height: 100%;
}
.touchevents .category-list .category-item .category-image {
  flex: 0 0 20%;
  max-width: 20%;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.touchevents .category-list .category-item .category-name {
  flex: 0 0 75%;
  max-width: 75%;
  margin: auto;
  text-align: left;
}
.touchevents .category-list .category-item .category-name h4{
  margin: 0;
}
.touchevents .category-list .category-item .category-view-btn{
  display: none;
}
.touchevents .category-list .category-item.selected{
  max-width: 70%;
  flex: 0 0 70%;
}
.touchevents .category-list .category-item.selected .category-image{
  flex: 0 0 28.5%;
  max-width: 28.5%;
}
.touchevents .category-list .category-item.selected .category-name{
  flex: 0 0 60%;
  max-width: 60%;
}
.touchevents .category-list .category-view-btn-mobile{
  display: none;
}
.touchevents .category-list .category-view-btn-mobile.selected{
  display:block;
  flex: 0 0 26%;
  margin: auto;
  padding: 0.5rem;
  border: 2px solid #28A745;
  border-radius: 5px;
  background: #28A745;
  color: #fff;
  text-align: center;
  line-height: 3.4rem;
}

.touchevents .module.module-viewed-products .product-items-wrapper,
.touchevents .category-products,
.touchevents .module.module-viewed-products .viewed-products{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.touchevents .module.module-viewed-products .product-items-wrapper .item,
.touchevents .category-products .item,
.touchevents .module.module-viewed-products .viewed-products .item{
  flex: 0 0 45%;
  max-width: 45%;
  margin: 0 0.5rem 1.5rem;
}
.touchevents .module.module-viewed-products .description{
  display: none;
}
.touchevents .product-items-wrapper .item img,
.touchevents .category-products .item img,
.touchevents .viewed-products .item img{
  max-width: 88%;
}
.touchevents .row.categories{
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
.touchevents .no-padding-bottom.catalog{
  padding-top:3.5rem;
}
.touchevents .gallery .product-gallery{
  flex-direction: column-reverse;
  align-items: center;
}
.touchevents #product-page-gallery {
  flex-wrap: nowrap;
}
.touchevents #product-page-gallery .slick-slider{
  flex: 0 0 100%;
  max-width: 100%;
  margin: 1rem 0;
}
.touchevents #product-page-gallery .main-images {
  flex: 0 0 100%;
  max-width: 100%;
}
.touchevents #product-page-gallery .slick-slider .image-gallery-left-nav,
.touchevents #product-page-gallery .slick-slider .image-gallery-right-nav{
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.touchevents #product-page-gallery .slick-slider .image-gallery-left-nav{
  left: -0.5rem;
}
.touchevents #product-page-gallery .slick-slider .image-gallery-right-nav{
  right: -1rem;
  left: unset;
}
.touchevents .product-information .price-quantity .product-total-price,
.touchevents .product-information .price-quantity .product-quantity,
.touchevents .product-information .price-quantity .product-price{
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.touchevents .product-information .price-quantity .product-total-price,
.touchevents .product-information .price-quantity .product-quantity,
.touchevents .product-information .price-quantity .stock-status,
.touchevents .product-information .price-quantity .product-price{
  margin-bottom: 1rem;
}
.touchevents .product-information .price-quantity .product-quantity,
.touchevents .product-information .price-quantity .product-total-price{
  height: unset;
}
.touchevents .product-information .price-quantity .product-quantity .price-text,
.touchevents .product-information .price-quantity .product-quantity .quantity-buttons,
.touchevents .product-information .price-quantity .product-total-price .price-text,
.touchevents .product-information .price-quantity .product-total-price .price-value,
.touchevents .product-information .price-quantity .product-price .price-text,
.touchevents .product-information .price-quantity .product-price .price-value{
  flex: 0 0 50%;
  max-width: 50%;
  margin: 0;
}
.touchevents .product-information .price-quantity .product-total-price .price-text {
  text-align: left;
}
.touchevents .product-page .product-information .stock-status{
  flex: 0 0 100%;
  max-width: 100%;
}
.touchevents .product-information .price-quantity .product-total-price .price-value{
  text-align: center;
}
.touchevents .product-information .price-quantity .product-total-price .price-value,
.touchevents .product-information .price-quantity .product-price .price-value{
  text-align: right;
}
.touchevents .product-information .price-quantity .product-quantity .quantity-buttons{
  justify-content: flex-end;
}
.touchevents .product-information .price-quantity .product-price .price-value{
  display: flex;
  flex-direction: column-reverse;
}
.touchevents .product-information .price-quantity .product-price .price-value b.old-price{
  font-size: 0.9rem;
  color: #777;
}
.touchevents .product-price .price-value b.old-price:after{
  background: #777;
  left: unset;
  right: 0;
  width: 50%;
}
.touchevents .product-information .price-quantity .product-total-price .price-value{
  color: #28A745;
  font-size: 1.3rem;
  font-weight: bold;
}
.touchevents .product-information .price-quantity .product-button{
  height: unset;
  margin: 0 auto;
  width: 70%;
}
.touchevents .product-description-wrapper{
  flex-direction: column;
}
.touchevents .product-description-wrapper .product-description-left,
.touchevents .product-description-wrapper .product-description-right{
  flex: 0 0 100%;
  max-width: 100%;
}
.touchevents .product-description-wrapper .description-item .title{
  text-align: center;
  margin-bottom: 1rem;
}
.touchevents .related-documents .documents-list .documents-item{
  flex: 0 0 50%;
  max-width: 50%;
}
.product-description-left.reviews-mobile .reviews-wrapper,
.touchevents .product-description-left .reviews-wrapper,
.product-description-left.reviews-mobile{
  display: none;
}
.touchevents .product-description-left.reviews-mobile .reviews-wrapper,
.touchevents .product-description-left.reviews-mobile{
  display: block;
}
.touchevents .product-description-left.reviews-mobile .reviews-wrapper .title{
  text-align:center;
}
.touchevents .product-description-left.reviews-mobile .reviews-wrapper .review-item{
  flex-direction: column;
}
.touchevents .product-description-left.reviews-mobile .reviews-wrapper .review-item .review-item-left{
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
}
.touchevents .product-description-left.reviews-mobile .reviews-wrapper .review-item .review-item-left .client-name{
  flex: 0 0 15%;
  max-width: 15%;
}
.touchevents .product-description-left.reviews-mobile .reviews-wrapper .review-item .review-item-left .review-info .review-rating span span{
  width: 10px;
}
.touchevents .product-description-left.reviews-mobile .reviews-wrapper .recaptcha-block div div{
  margin: 0 auto 1rem;
}
.touchevents .product-description-right .block-title{
  text-align: center;
}
.touchevents .product-title-block{
  margin-top: 1rem;
  text-align: center;
}
.touchevents div.breadcrumb .breadcrumb-list{
  padding-top: 1rem;
}
.touchevents div.breadcrumb .breadcrumb-list .breadcrumb-item .breadcrumb-link,
.touchevents div.breadcrumb .breadcrumb-list .breadcrumb-item .breadcrumb-current{
  font-size: 0.7rem;
}
.touchevents div.breadcrumb .breadcrumb-list .breadcrumb-item .breadcrumb-link svg{
  width: 1rem;
}
.touchevents .product-page{
  padding-top: 3.5rem;
}
.touchevents .category-sorting{
  justify-content: space-between;
}
.touchevents .filter-result{
  display: none;
}
.filter-btn{
  display: none;
}
.touchevents .category-sorting .sorting{
  flex: 0 0 60%;
  max-width: 60%;
}
.touchevents .filter-btn{
  flex: 0 0 35%;
  max-width: 35%;
  display: block;
  text-align: center;
  border: 1px solid #0275D8;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0275D8;
  padding: 7px 15px;
  font: normal 0.8rem/1em "HelveticaNeueCyrLight", sans-serif;
  cursor: pointer;
  position: relative;
}
.touchevents .filter-btn span{
  position: absolute;
  top: -10px;
  right: -10px;
  background: #0275D8;
  color: #fff;
  padding: 5px 7px;
  border-radius: 10px;
}
.touchevents .filters{
  display: none;
}
.filters .title{
  display: none;
}

.touchevents .filters.open {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 99;
  padding: 6rem 3rem 3rem;
}
.touchevents .filters.open .title{
  display: block;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
}
.filters .close-btn{
  display: none;
}
.touchevents .filters .close-btn {
  display: block;
  position: absolute;
  top: 3.5rem;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: #e5e5e5;
  background-image: url("/images/close-icon.svg");
  background-repeat: no-repeat;
  background-position: 100% 0%;
  border-bottom-left-radius: 3rem;
}
.touchevents .filters .filter .filter-title.active{
  margin-bottom: 1rem;
}
.filters .filters-btn-block{
  display: none;
}
.touchevents .filters-btn-block{
  display: block;
  text-align: center;
}
.touchevents .filters .reset-filters-btn,
.touchevents .filters .apply-filters-btn{
  display: inline-block;
  max-width: 40%;
  margin: 1rem 1rem;
  border: 1px solid;
  border-radius: 5px;
  padding: 0.5rem 1rem;
}
.touchevents .filters .reset-filters-btn{
  border-color: #EB5757;
  color: #EB5757;
}
.touchevents .filters .apply-filters-btn{
  background-color: #0275D8;
  border-color: #0275D8;
  color: #fff;
}
.product-breadcrumbs, .shopping-cart-breadcrumbs{
  padding-top: 4.5rem!important;
}
.touchevents .product-breadcrumbs, .touchevents .shopping-cart-breadcrumbs {
  padding-top: 0!important;
}
section.no-padding-bottom.catalog {
  padding-bottom: 1rem;
}
.touchevents section.no-padding-bottom.catalog{
  padding-bottom: 0;
}
.load-more {
  text-align: center;
  margin: 1rem auto;
}
.load-more button{
  padding: 0.3rem 1rem;
  color: #096DD9;
  border: 1px solid #096DD9;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
}
.touchevents .product-pagination{
  display: none;
}
#product-page-gallery{
  align-items: unset;
}
#product-page-gallery .slick-slider .slick-list{
  min-height: 100%;
}
#product-page-gallery .slick-vertical .slick-slide{
  border-color: #d8d8d8;
  margin-top: 0.5rem;
}
#product-page-gallery .slick-vertical .slick-slide div {
  display: block !important;
}
.reviews-wrapper .rating-wrapper {
  margin-bottom: 30px;
  margin-top: -40px;
}
.reviews-wrapper .rating-wrapper .text {
  vertical-align: middle;
  margin-right: 5px;
}

.product-list-series .slick-slider .slick-list{
  margin: 0;
}
.room-image-wrapper .lazy-load-image-background.blur.lazy-load-image-loaded > img:not(#specificationImage) {
  opacity: 0.4;
}
.lazy-load-image-background.blur.lazy-load-image-loaded {
  display: block!important;
}
.child-menu-arrow{
  position: absolute;
  top:0;
  right:1rem;
  display: block;
}
.nav > .nav-item.dropdown.menu-item.menu-item > .child-menu-arrow{
  display: none;
}
.child-menu-arrow.rotate{
  transform: rotate(-90deg);
}
.touchevents .nav > .nav-item.dropdown.menu-item.menu-item > .child-menu-arrow{
  display: block;
}
.touchevents .child-menu-arrow.rotate{
  transform: rotate(180deg);
}


.slider-kit-item {
  padding: 0.5rem;
}
.slider-kit-item p.name{
  margin: 0.5rem 0;
  text-align: center;
}
.slider-kit-item button{
  display: block;
  width: 110px;
  margin: 0 auto;
}
.slider-kit-item.option-item.option-item-mobile .choose{
  transform: unset!important;
}
.pro-side-bar {
  display: none;
}
.pro-side-bar.show{
  display: block;
}
.pro-sidebar-inner {
  background: #444;
  color: #fff;
  height: 100vh;
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0;
  min-width: 20%;
  padding: 2rem 0.5rem;
}
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: none;
}
.pro-side-bar.show .overlay {
  display: block;
}
.pro-side-bar .pro-sidebar-layout {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.pro-sidebar-inner .dropdown-item, .pro-sidebar-inner .dropdown-item:hover{
  color: #fff;
}
.pro-side-bar .dropdown-menu{
  top: 5rem;
  width: 100%;
  margin-top: 0;
  cursor: pointer;
  background: #444;
}
.pro-side-bar .dropdown-submenu .dropdown-item:hover, .pro-side-bar .dropdown-submenu.clicked {
  background-color: rgba(255, 255, 255, 0.3);
}
.pro-side-bar .dropdown-submenu>.dropdown-menu{
  min-width: 300px;
  height: 100vh;
  margin-top: -0.5rem;
}
.sidebar-title {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}
.touchevents .dropdown-menu{
  float: none;
}
.product-sery-tooltip{
  left: -1.2rem!important;
}
.save-btns{
  margin-top: 0.5rem;
}
.to-cart.cart-buttons .link {
  cursor: pointer;
}
.to-cart.cart-buttons.new.col-6 {
  padding-right: 0;
}
.to-cart.cart-buttons.existing.col-6 {
  padding-left: 0;
}
.specifications .all-specifications .specification-list .specification-item .value input::placeholder{
  opacity: 0.3;
}

.orders-modal.partner-modal .orders-history {
  padding: 3rem;
}
.orders-modal.partner-modal .account-content .orders-history .product-item .kit-fields{
  text-align: left;
}
.orders-modal.partner-modal .price-col {
  display: flex;
  align-items: center;
}
.paybright-logo {
  margin-right: 0.5rem;
}
.pay-button.paybright-btn .pay{
  flex-direction: column;
  height: 3.4rem!important;
}
.pay-button.paybright-btn img{
  margin-top: 0.5rem;
  width: 50%;
}
.pay-button.learn-more-link{
  margin-bottom: 0!important;}
.pay-button.learn-more-link a{
  text-decoration: underline;
}
.option-item-wrapper {
  text-align: center;
}
.cursor-pointer{
  cursor: pointer;
}
.read-more-button{
  color: #096DD9;
}
.link{
  color: #007bff;
   text-decoration: underline;
  background-color: transparent;
}


.tooltip-custom {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}
 .tooltip-custom .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip-custom:hover .tooltiptext {
  visibility: visible;
}

.sidebar-job-head .title-head-sidebar{
  font-weight: bold;
  color: #000;
  font-size: 19px;
  margin-bottom: 1rem;
}

.filters-wrapper {
    display: none;
}
.filters-wrapper.open {
    display: block;
}
.filters-wrapper .filters {
    min-width: 200px;
    margin-top: 10px;
}
.filters-wrapper .category-sorting {
    min-width: 260px;
}
.category-products .item img {
    margin: 0;
    max-width: 100%;
  height: auto;
  width: 100%;
}

.filters-toggle-btn {
    font-weight: 700;
    font-size: 16px;
    margin: 20px 0;
    display: inline-block;
    cursor: pointer;
    padding: 10px 25px;
    border: 1px solid #333;
}
.filters-toggle-btn:hover {
    color: #69B9E2;
    border-color: #69B9E2;
}
.filters-toggle-btn:hover path, .filters-toggle-btn:hover ellipse {
    stroke: #69B9E2;
}
.filters-toggle-btn .ico {
    vertical-align: bottom;
    margin-right: 12px;
}

.category-products .item {
    flex: 0 0 25%;
    max-width: 24.2%;
}

.product-description-wrapper .description ul,
.product-description-wrapper .description ol {
  padding-left: 40px;
  margin-bottom: 10px;
}
.product-description-wrapper .description p:last-of-type {
  margin-bottom: 1rem;
}
.banner-slider h2 {
  color: #444;
  font: normal 1rem/1.5em "HelveticaNeueCyrLight",sans-serif;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.75rem;
}
.faq-blog-section #faqs {
  padding: 7rem 5rem 3rem;
}
.faq-blog-section .full-width-title {
  padding: 2.5rem 0;
}
.faq-blog-section .margin-bottom {
  margin-bottom: 0;
}
.page-section .date-published {
  font-style: italic;
  margin-top: 20px;
}
.page-section .date-published span {
  font-weight: bold;
}
.widget-contacts-wrap {
  position: fixed;
  bottom: 6vh;
  right: 6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  z-index: 9999;
}
.widget-contacts-wrap.active .widget-contacts {
  visibility: visible;
  opacity: 1;
}
.widget-contacts-wrap.active .btn-open-widget-contacts {
  animation: none;
  background-image: url('/images/close-icon.svg');
}
.widget-contacts-wrap .widget-contacts {
  flex-direction: column;
  row-gap: 10px;
  column-gap: 10px;
  padding: 10px 5px;
  border-radius: 40px;
  background-color: #fff;
  box-shadow: 0 0 3px #ccc;
  width: 50px;
  display: flex;
  visibility: hidden;
  transition: .2s;
  opacity: 0;
}
.widget-contacts-wrap .widget-contacts a {
  transition: .1s;
}
.widget-contacts-wrap .widget-contacts a:hover {
  transform: scale(1.1);
}
.btn-products-widget {
  top: 50%;
  left: -65px;
  position: fixed;
  background: #373A3C;
  padding: 10px 23px;
  color: #fff;
  border: none;
  transform: rotate(-90deg);
}
.btn-products-widget:hover {
  opacity: .8;
}
.btn-products-widget svg {
  margin-left: 10px;
  animation: translateArrow 1.5s infinite;
}
.btn-products-widget.active svg {
  animation: translateArrowReverse 1.5s infinite;
}
.products-widget-wrapper {
  position: fixed;
  left: 42px;
  top: 0;
  width: 400px;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 999;

}
.products-widget-wrap {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 15px;
  max-height: 100%;
  padding: 15px;
  width: 100%;
  background: #fafafa;
  box-shadow: 0 0 3px #ccc;
  overflow-y: auto;
}
.products-widget-wrap .item-product {
  border: 2px solid #e4e5e6;
  border-radius: 5px;
  overflow: hidden;
  padding-bottom: 10px;
  flex: 0 0 47%;
  background: #fff;
  transition: .1s;
}
.products-widget-wrap .item-product:hover {
  border-color: #096DD9;
}
.products-widget-wrap .item-product .title {
  font-size: 14px;
  padding: 10px;
  font-weight: 600;
  line-height: 1.2;
  display: block;
}
.products-widget-wrap .item-product .link-wrap {
  text-align: center;
  padding: 0 10px;
}
.products-widget-wrap .item-product .link-wrap a {
  background-color: #096DD9;
  color: #fff;
  padding: 5px 20px;
  border-radius: 4px;
  display: inline-block;
}
.products-widget-wrap .item-product .link-wrap a:hover {
  opacity: .8;
}
@keyframes pulseChat {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
}
@keyframes translateArrow {
  0% {
    transform: translateY(-5px);
    opacity: 1;
  }
  80% {
    transform: translateY(2px);
    opacity: 1;
  }
  100% {
    transform: translateY(2px);
    opacity: 0;
  }
}
@keyframes translateArrowReverse {
  0% {
    transform: translateY(5px) rotate(180deg);
    opacity: 1;
  }
  80% {
    transform: translateY(-2px) rotate(180deg);
    opacity: 1;
  }
  100% {
    transform: translateY(-2px) rotate(180deg);
    opacity: 0;
  }
}
.btn-open-widget-contacts {
  width: 60px;
  height: 60px;
  background: #fafafa url(/images/chats.png) center center no-repeat;
  background-size: 38px;
  border-radius: 50%;
  filter: invert(1);
  border: none;
  box-shadow: 0 0 6px #000;
  animation: pulseChat 1.5s infinite;
}

/* for tablet */
@media (min-width: 768px) and (max-width: 1920px) {
  .touchevents header {
    height: 56px;
  }
  .touchevents .header .main-navigation {
    column-gap: 15px;
  }
  .touchevents .head-contact-info {
    top: 15px;
    right: 310px;
    position: absolute;
  }
  .touchevents .head-contact-info .value {
    display: block;
    position: static;
    background-color: transparent;
  }
  .touchevents .viewed-products .slick-slider {
    width: 100%;
  }
  .touchevents .module.module-viewed-products .viewed-products .item {
    flex: 0 0 31% !important;
    max-width: 31% !important;
  }
  .touchevents .module.module-viewed-products .product-items-wrapper .slick-slide .item, .touchevents .category-products .slick-slide  .item,
  .touchevents .module.module-viewed-products .viewed-products .slick-slide .item {
    max-width: 98% !important;
    flex: 0 0 98% !important;
  }
  .touchevents #top .main-slider-banner .slick-slide {
    height: 350px;
  }
  .touchevents #top .main-slider-banner .slick-slide img {
    height: 100%;
    object-fit: cover;
    max-width: none;
  }
  .touchevents #top .main-slider-banner .slick-slide > div {
    height: 100%;
  }
  .touchevents #top .main-slider-banner .slick-slide .lazyload-wrapper {
    height: 100%;
  }
  .touchevents .main-slider-banner .slide-layer-info .layer-title {
    font-size: 28px;
  }
  .touchevents #how-cut2size-works {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .touchevents #how-cut2size-works .item {
    width: 50%;
    flex: 0 0 50%;
  }
  .touchevents .list-section.category-list {
    padding: 0 15px;
  }
  .touchevents .full-width-title .title {
    font-size: 1.8rem;
  }
  .category-list .category-item .category-name h4 {
    font-size: 1.2rem;
  }
  .touchevents #numbers .item .number {
    flex: 0 0 125px;
    padding: 1rem;
  }
  .touchevents .header .logo {
    max-width: 100%;
    flex: 0 0 100px;
    border: none;
  }
  .touchevents .category-products .item {
    flex: 0 0 31%;
  }
  .touchevents .category-title h1 {
    font-size: 1.8rem;
  }
  .touchevents .product-page .product-information .stock-status {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .touchevents .product-information .price-quantity .product-total-price,
  .touchevents .product-information .price-quantity .product-quantity,
  .touchevents .product-information .price-quantity .product-price {
    flex: 0 0 auto;
    max-width: none;
    flex-direction: column;
  }
  .touchevents .product-information .price-quantity .product-quantity .price-text,
  .touchevents .product-information .price-quantity .product-quantity .quantity-buttons,
  .touchevents .product-information .price-quantity .product-total-price .price-text,
  .touchevents .product-information .price-quantity .product-total-price .price-value,
  .touchevents .product-information .price-quantity .product-price .price-text,
  .touchevents .product-information .price-quantity .product-price .price-value {
    flex: 0 0 auto;
    max-width: none;
  }
  .touchevents .product-information .product-information-wrap .price-quantity {
    padding: 0;
  }
  .touchevents .product-information .price-quantity .price-text {
    margin-bottom: 10px !important;
  }
  .touchevents .reviews-wrapper .title, .touchevents .description-item .title {
    font-size: 1.3rem;
    text-align: left !important;
  }
  .touchevents .reviews-wrapper .bottom .leave-review-btn {
    padding: 10px 15px;
    font-size: 1.2rem;
  }
  /*.touchevents .full-width-title {*/
  /*  padding: 10px;*/
  /*}*/
  .filters-wrapper .category-sorting {
    min-width: 371px;
    margin-left: -30px;
  }
  .touchevents .category-sorting .filter-btn {
    padding: 9px 15px;
  }
  .touchevents div.breadcrumb .breadcrumb-list {
    margin-top: 2rem;
  }

  .touchevents .calc-description .calc-description-title {
    font-size: 1.6rem;
  }
  .touchevents .calc-description .calc-description-text {
    text-align: center;
    font-size: 1rem;
  }
  .touchevents .calculator-steps-mobile .steps .step:before {
    left: 100%;
    width: 2.1rem;
  }
  .touchevents .row-steps-mobile .faq-btn {
    margin-left: auto;
    align-self: center;
    width: 80px;
  }
  .touchevents .options-list-mobile .option-item-mobile p.option span.room-name {
    font-size: 1.1rem;
  }
  .touchevents .calc-title {
    font-size: 1.1rem;
    padding-top: 1rem;
    padding-bottom: .5rem;
  }
  .touchevents .calculator-element-mobile .room.category.choosed {
    width: 77%;
  }
  .touchevents .elements-list-mobile .element-item.active {
    width: 75%;
  }
  .touchevents .calculator .option-item-mobile-wrapper {
    flex: 0 0 32%;
  }
  .touchevents #top .main-slider-banner .slick-dots {
    bottom: 1.1rem;
  }

  .touchevents .shopping-cart .cart-content .product-list .product-item .remove-product .remove {
    width: 4rem;
    position: absolute;
    top: 0;
    right: 0;
  }
  .touchevents .shopping-cart .cart-content .product-list .product-item .product-total-price .price {
    text-align: center;
    margin-top: 10px;
  }
  .touchevents .shopping-cart-breadcrumbs {
    padding-top: 4.5rem !important;
  }
  .touchevents .shopping-cart .cart-content .to-checkout {
    column-gap: 15px;
  }
  .touchevents .shopping-cart .cart-content .to-checkout .save-for-later-btn {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
  .touchevents .shopping-cart .cart-content .to-checkout .link {
    height: auto;
    padding: 3px;
    text-decoration: none;
  }
  .touchevents .account-content .account-information .account-form .field label input,
  .touchevents .account-content .account-information .account-form .field label select,
  .touchevents .account-content .account-information .account-form .field label>div,
  .touchevents .account-content .account-form .account-information .field label>div{
    width: 100%;
    max-width: 100%;
  }

  .touchevents .account-sidebar {
    width: 100% !important;
  }
  .touchevents .account-information-wrapper .account-navigation {
    justify-content: center !important;
    margin-top: -77px;
    width: 82%;
    margin-left: auto;
    margin-right: auto;
  }
  .touchevents .account-information-wrapper .account-navigation li.navigation-item {
    flex: 0 0 19%
  }
  .touchevents .account-information-wrapper .account-content {
    margin-top: 9rem !important;
  }
}

@media (min-width: 992px) and (max-width: 1920px) {
  .touchevents .list-section.category-list {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0px;
    column-gap: 15px;
  }
  .touchevents .category-list .category-view-btn-mobile.selected {
    line-height: 2.4rem;
    flex: 0 0 20%;
  }
  .touchevents .category-list .category-item .category-image {
    padding: 10px;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .touchevents .category-list .category-item.selected .category-image {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 1200px) {
  .slider-home-wrapper {
    height: 29vw;
  }
}