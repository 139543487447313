.shrink {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px
}

.wrapper {
    padding: 50px 5%;
}

.wrapper .rating-row .item-star,
.wrapper .rating-row>span
{
    margin-right: 3px
}

.wrapper .title-reviews-con {
    text-align: center;
    font-size: 36px;
    margin-bottom: 30px
}

.wrapper .info-rating {
    font-size: 18px;
    margin-top: 5px
}

.wrapper .link-all-reviews {
    margin-top: 5px
}

.wrapper .link-all-reviews a {
    color: #0275d8;
    font-size: 18px
}

.wrapper .link-all-reviews a:hover {
    text-decoration: none;
    color: #000
}

.wrapper .item-slide-wrap {
    background-color: #f8f9fa;
    border-radius: 27px;
    padding: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    position: relative;
    margin-right: 2rem;
    margin-left: 2rem;
}

.wrapper .item-slide-wrap .user-avatar {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.wrapper .item-slide-wrap .user-avatar img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.wrapper .item-slide-wrap .user-name {
    font-size: 12px;
    color: #8c8c8c
}

.wrapper .item-slide-wrap .date-published {
    font-size: 12px;
    color: #bfbfbf;
    margin-left: 10px
}

.wrapper .item-slide-wrap .text-review {
    color: #595959;
    margin-top: 5px;
    line-height: 1.4
}

.wrapper .item-slide-wrap .content-review {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-left: 10px
}

.wrapper .item-slide-wrap .read-more-button {
    font-weight: 500;
    color: #096dd9;
    cursor: pointer
}

.wrapper .item-slide-wrap .read-more-button:hover {
    color: #000
}

.wrapper .google-info-wrap {
    margin-bottom: 20px
}

.wrapper .google-info-wrap .logo {
    max-width: 70px
}
.wrapper .google-info-wrap .rating-total {
    font-size: 34px;
    font-weight: 700;
    color: #FFC107;
}

@media only screen and (max-width: 768px) {
    .wrapper .title-reviews-con {
        font-size: 24px;
        margin-bottom: 0;
        text-align: left;
        width: calc(100% + 30px);
        margin-left: -15px;
        font-weight: 700;
    }

    .wrapper .item-slide-wrap {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 20px 15px
    }

    .wrapper .item-slide-wrap .user-avatar {
        width: 35px;
        height: 35px
    }

    .wrapper .item-slide-wrap .content-review {
        padding-left: 0
    }

    .wrapper .item-slide-wrap .head-review {
        position: absolute;
        top: 30px;
        left: 60px
    }
    .wrapper .google-info-wrap .logo {
        max-width: 60px;
    }
    .wrapper .google-info-wrap {
        margin-bottom: 0;
        width: calc(100% + 30px);
        margin-left: -15px;
        padding: 10px;
        background-color: #fff8e2;
        border: 1px solid #FFC107;
        border-radius: 8px;
    }
    .wrapper .google-info-wrap .row {
        padding: 0;
    }
    .wrapper .info-rating {
        font-size: 16px;
        font-weight: 700;
        color: #343A40;
    }
    .wrapper .link-all-reviews a {
        font-size: 14px;
    }
    .wrapper .row {
        padding-top: 0;
    }
    
}
